import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/recommendation";

function* getRecommendations(action) {
  try {
    const response = yield call(Api.getRecommendations, action.payload);
    const responseHistory = yield call(
      Api.getRecommendersHistory,
      action.payload
    );
    yield put({
      type: Types.GET_RECOMMENDATIONS_SUCCESS,
      payload: { list: response, history: responseHistory },
    });
  } catch (error) {
    yield put({
      type: Types.GET_RECOMMENDATIONS_FAILURE,
      errorMessage: error.message,
    });
  }
}
export default function* () {
  yield all([
    takeLatest(Types.GET_RECOMMENDATIONS, getRecommendations),
    // takeLatest(Types.SET_FILTERS, getRecommendations),
  ]);
}
