import React from "react";

import {
  Avatar,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Utils from "../../Utils";

import MenuIcon from "@mui/icons-material/MoreVert";

import "./style.sass";

const UserCard = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  // const [showLoading, setShowLoading] = React.useState(false)

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const changeToManager = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null);
    props.changeToManagerAction();
  };
  const changeToTutor = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null);
    props.changeToTutorAction();
  };
  const enable = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null);
    props.enableAction();
  };
  const disable = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null);
    props.disableAction();
  };
  const del = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null);
    props.deleteAction();
  };
  const sendAction = () => {
    // setShowLoading(true)
    setMenuAnchorEl(null);
    props.sendAccessAction();
  };

  return (
    <Card
      className={`user-card block 
        ${props.data.type === "tutor" ? "tutor" : "manager"} 
        ${props.data.Enabled === true ? "" : "disabled"}`}
    >
      {/* {showLoading && (<Loading/>)} */}
      <div className="top-bar">
        {!props.itsMe && (
          <IconButton className="menu-button" onClick={openMenu} size="large">
            <MenuIcon />
          </IconButton>
        )}
      </div>
      <div className="header">
        <Avatar className="user-picture" src={props.data.picture} />
        <Typography variant="subtitle2">
          {props.data.name || `${props.data.firstName} ${props.data.lastName}`}
        </Typography>
        <Divider />
      </div>
      <div className="body">
        <Typography variant="subtitle2">Email: {props.data.email}</Typography>
        <Typography variant="subtitle2">
          Whatsapp: {Utils.phoneMask(props.data.phone_number) || "sem whatsapp"}
        </Typography>
      </div>

      {!props.itsMe && (
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={closeMenu}
        >
          {props.data.type === "manager" &&
            props.tutorModule === true &&
            props.data.attributes?.tutorId > -1 && (
              <MenuItem onClick={changeToTutor}>Alterar para tutor</MenuItem>
            )}
          {props.data.type === "tutor" && props.tutorModule === true && (
            <MenuItem onClick={changeToManager}>Alterar para gestor</MenuItem>
          )}
          {props.data.firstAccess === false &&
            (props.tutorModule === true || props.data.type === "manager") && (
              <MenuItem onClick={sendAction}>Enviar acesso</MenuItem>
            )}
          {props.data.firstAccess === true &&
            (props.tutorModule === true || props.data.type === "manager") && (
              <MenuItem onClick={sendAction}>Enviar novo acesso</MenuItem>
            )}
          {props.data.Enabled === true && (
            <MenuItem onClick={disable}>Desativar acesso</MenuItem>
          )}
          {props.data.Enabled === false && (
            <MenuItem onClick={enable}>Ativar acesso</MenuItem>
          )}
        </Menu>
      )}
    </Card>
  );
};

UserCard.defaultProps = {
  data: {},
  itsMe: false,
  changeToManagerAction: () => {},
  changeToTutorAction: () => {},
  enableAction: () => {},
  disableAction: () => {},
  deleteAction: () => {},
  sendAccessAction: () => {},
};

export default UserCard;
