import {
  Event,
  FiberManualRecord,
  Groups,
  MenuBook,
  OpenInNew,
  Percent,
  Person,
  RadioButtonChecked,
  Recommend,
  School,
  SpeakerNotes,
  Summarize,
  Timeline,
  Today,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { Recommendation } from "./types";

type Props = {
  filterBatch: () => void;
  recommendation: Recommendation;
};

const AverageGradeClassVSHistorical = ({
  filterBatch,
  recommendation,
}: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>{`
            Notamos que a turma ${recommendation.class?.name || ""} do curso ${
              recommendation.course?.name || ""
            } está com média (${
              recommendation.list?.[0].classAverageGrade
            }) abaixo da média histórica para o curso (${
              recommendation.list?.[0].historicalAverageGrade || ""
            }).`}</Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <SpeakerNotes />
            <Typography>
              A informação pode indicar situações críticas a serem analisadas.
              Sugerimos a realização das seguintes ações:
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Verificar a participação do(a) tutor(a). Se ausente, entrar em
              contato;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Verificar se houveram modificações no conteúdo;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Solicitar coleta de feedback dos alunos para verificar fatores
              externos (problemas no AVA, avaliação do conteúdo do curso, etc).
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <MenuBook />
                <Typography>
                  <strong>Curso:</strong>
                </Typography>
                <Typography>{recommendation?.course?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Groups />
                <Typography>
                  <strong>Turma:</strong>
                </Typography>
                <Typography>{recommendation?.class?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Percent />
                <Typography>
                  <strong>Progresso da turma:</strong>
                </Typography>
                <Typography>
                  {(recommendation?.conclusion * 100).toFixed(2)}% de conclusão
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Today />
                <Typography>
                  <strong>Data de início da turma:</strong>
                </Typography>
                <Typography>
                  {recommendation?.startDate &&
                    moment(recommendation?.startDate).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Event />
                <Typography>
                  <strong>Data de término da turma:</strong>
                </Typography>
                <Typography>
                  {recommendation?.endDate &&
                    moment(recommendation?.endDate).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <School />
                <Typography>
                  <strong>Tutor:</strong>
                </Typography>
                <Typography>{recommendation?.tutor?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Summarize />
                <Typography>
                  <strong>Análise que disparou a recomendação:</strong>
                </Typography>
                <Link onClick={filterBatch} sx={{ cursor: "pointer" }}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {moment(recommendation?.date)
                        .add(3, "hours")
                        .format("DD/MM/YYYY")}
                    </Typography>
                    <OpenInNew />
                  </Stack>
                  <Typography></Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={recommendation.list?.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "classAverageGrade",
              headerName: "Média da turma",
              flex: 1,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.classAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.classAverageGrade}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "historicalAverageGrade",
              headerName: "Média histórica",
              flex: 1,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.historicalAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.historicalAverageGrade}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média histórica</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "IAEvasionGroup", // @TODO Classificar no backend o AVG IAEvasion para um Grupo
              headerName: "Potêncial de evasão",
              flex: 1,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.IAEvasionGroup || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.IAEvasionGroup || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Potêncial de evasão</strong>
                  </Typography>
                </Stack>
              ),
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5]}
        />
      </Grid>
    </>
  );
};

export default AverageGradeClassVSHistorical;
