/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Proptype from "prop-types";
import Draggable from "react-draggable";
import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as ChatActions } from "../../store/ducks/chat";
import { Resgate, Apresentacao } from "./chatMessages";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Fab,
  Paper,
  Popover,
} from "@mui/material";

import "./style.sass";
import { Close, LiveHelp } from "@mui/icons-material";

import MessageComponent from "../../components/Message";
import LoadingSVG from "./bolinhas.svg";

const Chat = (props) => {
  const [message, setMessage] = useState("");
  const [finishedTab, setFinishedTab] = useState(false);
  const [defaultStyle, setDefaultStyle] = useState(false);
  const lastMsgRef = useRef(null);
  const [inactiveInfoEl, setInactiveInfoEl] = useState(null);

  const dispatch = useDispatch();
  let chatList = useSelector((state) => state.chat.list);
  let chatOrder = useSelector((state) => state.chat.order);
  let chatFilter = useSelector((state) => state.chat.filter);
  let chatLoading = useSelector((state) => state.chat.sendMessageLoading);
  let chatFailCount = useSelector((state) => state.chat.failCount);
  let user = useSelector((state) => state.auth.authenticatedUser);

  const getIndicatorsLoading = useSelector(
    (state) => state.indicators.getIndicatorsLoading
  );
  const getMetricsLoading = useSelector(
    (state) => state.metrics.getMetricsLoading
  );
  const getBatchsLoading = useSelector((state) => state.batch.getBatchsLoading);

  let getTutorsLoading = useSelector((state) => state.tutor.getTutorsLoading);
  let getStudentsLoading = useSelector(
    (state) => state.student.getStudentsLoading
  );
  let msg = useSelector((state) => state.chat.messageSuccess);
  let statusChat = useSelector((state) => state.chat.status);

  useEffect(() => {
    if (chatList.length === 0 && user.name)
      dispatch(ChatActions.autoMessage(Apresentacao({ userName: user.name })));
  }, [user]);

  useEffect(() => {
    if (chatFilter.tutor)
      dispatch(AppActions.setSelectedTutors(chatFilter.tutor));
    if (chatFilter.student)
      dispatch(AppActions.setSelectedStudents(chatFilter.student));
    if (chatFilter.course)
      dispatch(AppActions.setSelectedCourses(chatFilter.course));
    if (chatFilter.classe)
      dispatch(AppActions.setSelectedClasses(chatFilter.classe));
    if (chatFilter.action)
      dispatch(AppActions.setSelectedEnrollments(chatFilter.action));

    if (chatFilter.isFinished && chatFilter.isFinished !== finishedTab) {
      setFinishedTab(chatFilter.isFinished);
      dispatch(AppActions.changeFinished(chatFilter.isFinished));
    }
    setTimeout(() => {
      if (lastMsgRef.current) {
        lastMsgRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 200);
    // Interpreta chatList
    // Link do encerramento irá disparar Encerramento em chatMessages, não envia para o back
  }, [chatOrder, chatFilter]);

  useEffect(() => {
    setTimeout(() => {
      if (lastMsgRef.current) {
        lastMsgRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 200);
  }, [chatList, props.show]);

  const onSubmit = () => {
    let count = chatFailCount;
    if (count > 2) count = 2;
    dispatch(
      ChatActions.sendMessage({
        message: message,
        origin: "me",
        failCount: count,
      })
    );
    setMessage("");
  };

  const onChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    let valid =
      getTutorsLoading ||
      getBatchsLoading ||
      getMetricsLoading ||
      getIndicatorsLoading ||
      getStudentsLoading;
    if (!valid && msg.message) {
      dispatch(ChatActions.sendMessageSuccess({}));
    }
  }, [
    getTutorsLoading,
    getBatchsLoading,
    getMetricsLoading,
    getIndicatorsLoading,
    getStudentsLoading,
  ]);
  const onClose = () => {
    /**
     * Usar em caso de Encerramento, não é aqui
     * dispatch(ChatActions.autoMessage(Encerramento))
     * setTimeout(
         () => props.setShow(false)
       , 2000);
     */
    dispatch(
      ChatActions.autoMessage({
        origin: "assis",
        message: Resgate(),
        intent: "rescue",
      })
    );
    props.setShow(false);
  };

  useEffect(() => {
    setDefaultStyle(false);
    if (statusChat === "success" || statusChat === "failure") {
      setTimeout(() => {
        setDefaultStyle(true);
      }, 3000);
    }
  }, [statusChat]);

  return (
    <Dialog
      open={props.show}
      className="chat-wrapper"
      fullScreen
      // disablePortal
      hideBackdrop
      PaperComponent={PaperComponent}
      PaperProps={{ elevation: 0 }}
      style={{
        left: 50,
        top: 200,
      }}
    >
      <div className="chat-wrapper-fabs">
        <Fab onClick={onClose} className="chat-fab close">
          {" "}
          <Close />{" "}
        </Fab>

        <Fab
          className="chat-fab help"
          onClick={(event) => setInactiveInfoEl(event.currentTarget)}
        >
          <LiveHelp />
        </Fab>
        <Popover
          style={{ top: "30px", left: "20px" }}
          open={Boolean(inactiveInfoEl)}
          anchorEl={inactiveInfoEl}
          onClose={() => setInactiveInfoEl(null)}
        >
          <div style={{ width: "323px", margin: "10px" }}>
            <strong>
              Você pode interagir com o Assis clicando nas perguntas ou
              digitando com o teclado.
            </strong>
            . Caso tenha mais dúvidas ou problemas, entre em contato com{" "}
            <a style={{ color: "#3B8FE4" }} href="mailto:contato@assis.ai">
              contato@assis.ai
            </a>
            .
          </div>
        </Popover>
      </div>
      <DialogTitle
        id="draggable-dialog-title"
        style={{ cursor: "move" }}
      ></DialogTitle>
      <DialogContent
        className={`chat-content ${defaultStyle ? "" : statusChat} `}
      >
        <div className="chat-view">
          {chatList.map((m, i) => (
            <React.Fragment key={i}>
              <MessageComponent key={i} ref={lastMsgRef} {...m} />
            </React.Fragment>
          ))}
          {chatLoading && (
            <object
              ref={lastMsgRef}
              type="image/svg+xml"
              data={LoadingSVG}
              className="chat-loading"
            >
              svg-animated
            </object>
          )}
        </div>
        <div className="input-text">
          <TextField
            placeholder="Digite o que gostaria de saber..."
            className="chat-tf"
            id="chat-tf"
            InputProps={{ disableUnderline: true }}
            variant="standard"
            onChange={onChange}
            value={message}
            onKeyPress={(e) => {
              if (e.key === "Enter") onSubmit();
            }}
            autoComplete="off"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const PaperComponent = (props) => {
  const [position, setPosition] = useState({
    x: window.innerWidth - 580,
    y: window.innerHeight - 580,
  });
  const stop = (event, data) => {
    const documentElement = document.documentElement;
    const wrapperHeight = window.innerHeight || documentElement.clientHeight;
    const wrapperWidth = window.innerWidth || documentElement.clientWidth;
    const center = {
      x: data.x + data.node.clientWidth / 2,
      y: data.y + data.node.clientHeight / 2,
    };
    const margin = {
      top: center.y - 0,
      left: center.x - 0,
      bottom: wrapperHeight - center.y,
      right: wrapperWidth - center.x,
    };
    const p = {
      top: { y: 0 },
      left: { x: 0 },
      bottom: { y: wrapperHeight - data.node.clientHeight - 80 },
      right: { x: wrapperWidth - data.node.clientWidth - 20 },
    };
    const sorted = Object.keys(margin).sort((a, b) => margin[a] - margin[b]);

    setPosition({
      ...p[sorted[3]],
      ...p[sorted[2]],
      ...p[sorted[1]],
      ...p[sorted[0]],
    });
  };

  return (
    <Draggable
      position={position}
      onStop={(event, data) => stop(event, data)}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

Chat.defaultProps = {
  anchorX: 1300,
  anchorY: 625,
  show: true,
};

PaperComponent.defaultProps = {
  anchorX: 1300,
  anchorY: 625,
};
Chat.propTypes = {
  show: Proptype.bool,
  anchorX: Proptype.number,
  anchorY: Proptype.number,
};

export default Chat;
