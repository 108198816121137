import React, { useState, useEffect } from "react";

import {
  Avatar,
  ButtonBase,
  Card,
  Typography,
  Chip,
  Tooltip,
} from "@mui/material";

import Utils from "../../Utils";

import "./style.sass";

const TutorCard = (props) => {
  const [openedInfo] = useState(false);
  const [evasorCount, setEvasorCount] = useState(0);

  useEffect(() => {
    if (props.data.classifications && props.data.evarsorConcludents) {
      let count = 0;
      if (props.data.evarsorConcludents.length)
        count =
          props.data.evarsorConcludents.find(
            (item) => item.title === "Evasores"
          )?.count || 0;
      else
        count =
          props.data.classifications.find((item) => item.title === "Evasores")
            ?.count || 0;

      setEvasorCount(count);
    }
  }, [props.data]);

  return (
    <Card
      className={`tutor-card ${openedInfo ? " open-info" : ""} ${
        props.color
      }-color`}
    >
      <div className="picture-numbers-wrapper">
        <Avatar
          className="picture"
          size=""
          src={
            props.data.picture || process.env.PUBLIC_URL + "/default_perfil.svg"
          }
        />
        <div className="numbers">
          {props.data.classifications.map((classificationItem, key) => (
            <Tooltip key={key} title={classificationItem.title} placement="top">
              <Chip
                size="small"
                className="number"
                label={`${classificationItem.count} (${(
                  (classificationItem.count / props.data.studentCount) *
                  100
                ).toFixed(2)}%)`}
                style={{ backgroundColor: classificationItem.color }}
              />
            </Tooltip>
          ))}
          <Typography variant="button">
            {`${evasorCount === 1 ? "1 Evasor" : evasorCount + " Evasores"}`}
          </Typography>
        </div>
      </div>
      <Typography className="name" gutterBottom variant="subtitle2">
        {Utils.capitalize(props.data.name)}
      </Typography>
      <ButtonBase className="see-more" onClick={props.seeMoreAction}>
        <Typography variant="button">Ver mais</Typography>
      </ButtonBase>
    </Card>
  );
};

TutorCard.defaultProps = {
  data: {},
  seeMoreAction: () => {},
};

export default TutorCard;
