import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/metrics";

function* getMetrics(action) {
  try {
    const response = yield call(Api.getMetrics, action.payload);
    yield put({ type: Types.GET_METRICS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_METRICS_FAILURE, errorMessage: error.message });
  }
}

export default function* () {
  yield all([takeLatest(Types.GET_METRICS, getMetrics)]);
}
