import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
  Grid,
  Typography,
  Avatar,
  Switch,
  Dialog,
  DialogTitle,
  List,
  ListItem,
} from "@mui/material";
import Alert from '@mui/material/Alert';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Create";
import DuplicateIcon from "@mui/icons-material/FileCopyOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

import { Creators as AlertActions } from "../../store/ducks/alert";

import "./style.sass";

const AlertAccordionItem = (props) => {
  const dispatch = useDispatch();

  const ruleConditions = [
    { symbol: ">", text: "Maior que" },
    { symbol: "<", text: "Menor que" },
    { symbol: ">=", text: "Maior ou igual a" },
    { symbol: "<=", text: "Menor ou igual a" },
    { symbol: "=", text: "Igual a" },
  ];

  const [monitoringExpanded, setMonitoringExpanded] = useState(null);
  const [showLog, setShowLog] = useState(false);

  const monitoringAccordionHandle = (id) => {
    setMonitoringExpanded(monitoringExpanded === id ? null : id);
  };

  const enableDisableAlert = (alert) => {
    if (props.data.enable) dispatch(AlertActions.disableAlert(props.data._id));
    else dispatch(AlertActions.enableAlert(props.data._id));
  };

  const remonitor = (alert) => {
    dispatch(AlertActions.remonitorAlert(props.data._id));
  };

  const deleteAlert = (alert) => {
    dispatch(AlertActions.deleteAlert(props.data._id));
  };

  const duplicateAlert = (alert) => {
    dispatch(AlertActions.duplicateAlert(props.data._id));
  };

  useEffect(() => { }, []);

  return (
    <Accordion
      className="alert-accordion-item"
      expanded={monitoringExpanded === props.data._id}
      onChange={() => monitoringAccordionHandle(props.data._id)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          {props.data.expired !== true && (
            <Grid item xs="1">
              <Switch
                checked={props.data.enable}
                onClick={(event) => event.stopPropagation()}
                onChange={(event) => enableDisableAlert(alert)}
              />
            </Grid>
          )}
          <Grid item xs="5" container>
            <Typography variant="subtitle2" className="accordion-title">
              {props.data.expired === true && (
                <Grid item xs="2">
                  <Button
                    className="remonitor"
                    variant="contained"
                    color="primary"
                    onClick={() => remonitor(alert)}
                    startIcon={<RefreshIcon />}
                  >
                    {/* <RefreshIcon /> */}
                    <span className="text">Remonitorar alerta</span>
                  </Button>
                </Grid>
              )}

              <Grid item xs="7">
                {props.data.title}
              </Grid>
              {props.data.log.length > 0 && (
                <Grid item xs="3">
                  <Button
                    className="log-button"
                    variant="outlined"
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowLog(true);
                    }}
                  >
                    {props.data.log.length + " Atividade"}
                    {props.data.log.length > 1 ? "s" : ""}
                  </Button>
                </Grid>
              )}
              {/* {props.data.expired === true && (<Chip size="small" className="status expired" label="Expirado" color="secondary"/>)} */}
            </Typography>
          </Grid>
          <Grid item xs="6">
            {monitoringExpanded === props.data._id && (
              <div className="action-buttons">
                <Button
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteAlert(alert);
                  }}
                >
                  <DeleteIcon /> Deletar
                </Button>
                <Button
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    duplicateAlert(alert);
                  }}
                >
                  <DuplicateIcon /> Duplicar
                </Button>
                <Button
                  size="small"
                  onClick={() =>
                    dispatch(AlertActions.openAlertForm(props.data))
                  }
                >
                  <EditIcon /> Editar
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className="alert-details">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <div>
                <Typography variant="subtitle2">Tutores</Typography>
              </div>
              {props.data.tutors.length === 0 && (
                <Alert severity="info">Nenhum tutor</Alert>
              )}
              {props.data.tutors.map((tutor) => (
                <Chip
                  avatar={<Avatar alt={tutor.name} src={tutor.picture} />}
                  label={tutor.name}
                />
              ))}
            </div>

            <div>
              <div>
                <Typography variant="subtitle2">Cursos</Typography>
              </div>
              {props.data.courses.length === 0 && (
                <Alert severity="info">Nenhum curso</Alert>
              )}
              {props.data.courses.map((course) => (
                <Chip label={course.name} />
              ))}
            </div>

            <div>
              <div>
                <Typography variant="subtitle2">Turmas</Typography>
              </div>
              {props.data.classes.length === 0 && (
                <Alert severity="info">Nenhuma turma</Alert>
              )}
              {props.data.classes.map((clss) => (
                <Chip label={clss.name} />
              ))}
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography variant="subtitle2">Indicadores</Typography>
                {props.data.rules.map((rule) => (
                  <Grid container>
                    <Grid item xs>
                      <Typography variant="caption">Indicador</Typography>
                      <Typography variant="caption">{rule.name}</Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption">Parâmetro</Typography>
                      <Typography variant="caption">
                        {
                          ruleConditions.find(
                            (ruleItem) => ruleItem.symbol === rule.condition
                          ).text
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption">Porcentagem</Typography>
                      <Typography variant="caption">{rule.value}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">Data limite</Typography>
                <Typography variant="caption">
                  {moment(props.data.limitDate).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2">Enviar também para</Typography>
                <Typography variant="caption">
                  {props.data.sendTo || "---"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Lembretes</Typography>
                <ul>
                  {props.data.reminder?.map((action) => (
                    <li>
                      <Typography variant="caption">{action}</Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Dialog
        className="alert-log-dialog"
        onClose={() => setShowLog(false)}
        maxWidth="md"
        open={showLog}
      >
        <DialogTitle>Atividades</DialogTitle>
        <List>
          {props.data.log.map((log) => (
            <ListItem className="log-item">
              <Typography variant="caption">
                {moment(log.date).format("DD/MM/YYYY HH:mm")}
              </Typography>
              <Typography variant="body2">{log.msg}</Typography>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </Accordion>
  );
};

AlertAccordionItem.defaultProps = {
  data: {},
};

export default AlertAccordionItem;
