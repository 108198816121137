import { put, call, takeLatest, all } from "redux-saga/effects";
import { select } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/user";
import { Types as AuthTypes } from "../ducks/auth";
import { Types as AppTypes } from "../ducks/app";

function* reloadUsers() {
  const getUserState = (state) => state.user;
  const userState = yield select(getUserState);

  let getManagerUserPayload = {
    search: userState.search,
    page: userState.managerPage,
    limit: userState.managerLimit,
    filter: userState.managerFilter,
  };
  let getTutorUserPayload = {
    search: userState.search,
    page: userState.tutorPage,
    limit: userState.tutorLimit,
    filter: userState.tutorFilter,
  };

  yield put({ type: Types.GET_MANAGER_USERS, payload: getManagerUserPayload });
  yield put({ type: Types.GET_TUTOR_USERS, payload: getTutorUserPayload });
}

function* getManagerUsers(action) {
  try {
    // action.payload.role = "manager";
    const response = yield call(Api.getUsers, { role: "manager" });
    yield put({ type: Types.GET_MANAGER_USERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_MANAGER_USERS_FAILURE,
      payload: error.message,
    });
  }
}

function* getTutorUsers(action) {
  try {
    // action.payload.role = "tutor";
    const response = yield call(Api.getUsers, { role: "tutor" });
    yield put({ type: Types.GET_TUTOR_USERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_TUTOR_USERS_FAILURE, payload: error.message });
  }
}

function* getUsersNoAccess(action) {
  try {
    action.payload = { filter: "no-access", limit: 10000 };
    const response = yield call(Api.getUsers, action.payload);
    yield put({ type: Types.GET_USERS_NO_ACCESS_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_USERS_NO_ACCESS_FAILURE,
      payload: error.message,
    });
  }
}

function* enableUser(action) {
  try {
    let payload = { ...action.payload, enabled: true };
    const response = yield call(Api.updateUser, payload);
    yield put({ type: Types.ENABLE_USER_SUCCESS, payload: response });
    yield reloadUsers();
    yield put({
      type: AppTypes.SHOW_ALERT,
      payload: {
        title: "Acesso do usuário",
        text: "Acesso do usuário habilitado com sucesso",
      },
    });
  } catch (error) {
    yield put({ type: Types.ENABLE_USER_FAILURE, payload: error.message });
  }
}

function* disableUser(action) {
  try {
    let payload = { ...action.payload, enabled: false };
    const response = yield call(Api.updateUser, payload);
    yield put({ type: Types.DISABLE_USER_SUCCESS, payload: response });
    yield reloadUsers();
    yield put({
      type: AppTypes.SHOW_ALERT,
      payload: {
        title: "Acesso do usuário",
        text: "Acesso do usuário desabilitado com sucesso",
      },
    });
  } catch (error) {
    yield put({ type: Types.DISABLE_USER_FAILURE, payload: error.message });
  }
}

function* changeUserRoleToManager(action) {
  try {
    let payload = { userId: action.payload, role: "manager" };
    const response = yield call(Api.changeRole, payload);
    yield put({
      type: Types.CHANGE_USER_ROLE_TO_MANAGER_SUCCESS,
      payload: response,
    });
    yield reloadUsers();
  } catch (error) {
    yield put({
      type: Types.CHANGE_USER_ROLE_TO_MANAGER_FAILURE,
      payload: error.message,
    });
  }
}

function* changeUserRoleToTutor(action) {
  try {
    let payload = { userId: action.payload, role: "tutor" };
    const response = yield call(Api.changeRole, payload);
    yield put({
      type: Types.CHANGE_USER_ROLE_TO_TUTOR_SUCCESS,
      payload: response,
    });
    yield reloadUsers();
  } catch (error) {
    yield put({
      type: Types.CHANGE_USER_ROLE_TO_TUTOR_FAILURE,
      payload: error.message,
    });
  }
}

function* deleteUser(action) {
  try {
    const response = yield call(Api.deleteUser, action.payload);
    yield put({ type: Types.DELETE_USER_SUCCESS, payload: response });
    yield put({
      type: AppTypes.SHOW_ALERT,
      payload: {
        title: "Exclusão do usuário",
        text: "Usuário excluído com sucesso",
      },
    });
    yield reloadUsers();
  } catch (error) {
    yield put({ type: Types.DELETE_USER_FAILURE, payload: error.message });
  }
}

function* newUser(action) {
  try {
    action.payload.type = "manager";
    const response = yield call(Api.newUser, action.payload);
    yield put({ type: Types.NEW_USER_SUCCESS, payload: response });
    yield reloadUsers();
    yield put({
      type: AppTypes.SHOW_ALERT,
      payload: {
        title: "Cadastrar gestor",
        text: response,
      },
    });
  } catch (error) {
    yield put({ type: Types.NEW_USER_FAILURE, payload: error.message });
  }
}

function* sendAccess(action) {
  try {
    const response = yield call(Api.sendAccess, action.payload);
    yield put({ type: Types.SEND_ACCESS_SUCCESS, payload: response });
    yield put({
      type: AppTypes.SHOW_ALERT,
      payload: {
        title: "Acesso do usuário",
        text: "Novos acessos enviados com sucesso",
      },
    });
    yield reloadUsers();
  } catch (error) {
    yield put({ type: Types.SEND_ACCESS_FAILURE, payload: error.message });
  }
}

function* saveMyData(action) {
  try {
    const response = yield call(Api.updateUser, action.payload);
    yield put({ type: Types.SAVE_MY_DATA_SUCCESS, payload: response });
    // yield put({ type: AuthTypes.GET_AUTHENTICATED_USER });
    yield reloadUsers();
  } catch (error) {
    yield put({ type: Types.SAVE_MY_DATA_FAILURE, payload: error.message });
  }
}

function* changeProfilePicture(action) {
  try {
    const response = yield call(Api.updateUser, action.payload);
    yield put({
      type: Types.CHANGE_PROFILE_PICTURE_SUCCESS,
      payload: response,
    });
    // yield put({ type: AuthTypes.GET_AUTHENTICATED_USER });
    yield reloadUsers();
  } catch (error) {
    yield put({
      type: Types.CHANGE_PROFILE_PICTURE_FAILURE,
      payload: error.message,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_MANAGER_USERS, getManagerUsers),
    takeLatest(Types.GET_TUTOR_USERS, getTutorUsers),
    takeLatest(Types.GET_USERS_NO_ACCESS, getUsersNoAccess),
    takeLatest(Types.ENABLE_USER, enableUser),
    takeLatest(Types.DISABLE_USER, disableUser),
    takeLatest(Types.CHANGE_USER_ROLE_TO_MANAGER, changeUserRoleToManager),
    takeLatest(Types.CHANGE_USER_ROLE_TO_TUTOR, changeUserRoleToTutor),
    takeLatest(Types.DELETE_USER, deleteUser),
    takeLatest(Types.NEW_USER, newUser),
    takeLatest(Types.SEND_ACCESS, sendAccess),
    takeLatest(Types.SAVE_MY_DATA, saveMyData),
    takeLatest(Types.CHANGE_PROFILE_PICTURE, changeProfilePicture),
  ]);
}
