import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/batchlog";

function* getMonitoingAlerts(action) {
  try {
    const response = yield call(Api.getBatchlogs);
    yield put({ type: Types.GET_BATCH_LOGS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_BATCH_LOGS_FAILURE, payload: error.message });
  }
}

export default function* () {
  yield all([takeLatest(Types.GET_BATCH_LOGS, getMonitoingAlerts)]);
}
