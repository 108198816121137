import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  login: ["payload"],
  loginSuccess: null,
  loginFailure: null,
  logout: null,
  logoutSuccess: null,
  logoutFailure: null,
  getAuthenticatedUser: null,
  getAuthenticatedUserSuccess: ["payload"],
  getAuthenticatedUserFailure: ["payload"],
});

const INITIAL_STATE = {
  isAuthenticated: false,
  showLoginLoading: false,
  loginFailureMsg: null,
  showLogoutLoading: false,
  logoutFailureMsg: null,
  authenticatedUser: {},
  showAuthenticatedUserLoading: false,
};

const login = (state = INITIAL_STATE, action) => ({
  ...state,
  showLoginLoading: true,
});
const loginSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  showLoginLoading: false,
  isAuthenticated: true,
  loginFailureMsg: INITIAL_STATE.loginFailureMsg,
});
const loginFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  showLoginLoading: false,
  isAuthenticated: false,
  loginFailureMsg: action.payload,
});

const logout = (state = INITIAL_STATE, action) => ({
  ...state,
  showLogoutLoading: true,
});
const logoutSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  showLogoutLoading: false,
  isAuthenticated: false,
  logoutFailureMsg: INITIAL_STATE.logoutFailureMsg,
});
const logoutFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  showLogoutLoading: false,
  logoutFailureMsg: action.payload,
});

const getAuthenticatedUser = (state = INITIAL_STATE, action) => ({
  ...state,
  showAuthenticatedUserLoading: true,
});
const getAuthenticatedUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  showAuthenticatedUserLoading: false,
  isAuthenticated: true,
  authenticatedUser: action.payload,
});
const getAuthenticatedUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  showAuthenticatedUserLoading: action.payload,
});

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT]: logout,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,
  [Types.GET_AUTHENTICATED_USER]: getAuthenticatedUser,
  [Types.GET_AUTHENTICATED_USER_SUCCESS]: getAuthenticatedUserSuccess,
  [Types.GET_AUTHENTICATED_USER_FAILURE]: getAuthenticatedUserFailure,
});
