import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import "./style.sass";
import { CheckCircle } from "@mui/icons-material";

const ToolScreen = (props) => {
  return (
    <Grid container spacing={2} id="tool-screen" mb={3}>
      <Grid item xs={12} className="header">
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Grid container spacing={1} id="header-text">
              <Grid item>
                <Typography variant={"h4"}>
                  Faça a integração do Assis de maneira fácil e segura através
                  do módulo de integração.
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Por meio do plug-in a integração e configuração do Assis com
                  múltiplas fontes de dados ficou muito mais flexível,
                  permitindo que você conecte sistemas sem a necessidade de
                  recursos de desenvolvimento. Simplifique o processo e ganhe
                  mais agilidade na geração e automatização de dados com muito
                  mais segurança.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <img
              id={"tool-graphs"}
              src={`${process.env.PUBLIC_URL}/tool-graphs.svg`}
              alt=""
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={2} id="download-info">
          <Grid item xs={12}>
            <Stack spacing={1} direction={"row"} className="blue">
              <img
                id={"windows-icon"}
                src={`${process.env.PUBLIC_URL}/windows-icon.svg`}
                alt=""
              />
              <Typography variant={"h5"}>Download para Windows</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Requisitos do sistema:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p">
              Instalador compatível com a distribuição do Windows 10 64 bits ou
              posterior.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>(4.54 MB) - Português (pt-BR) - 12/03/2021</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant={"contained"}
              color="primary"
              size="small"
              href="https://devassis.s3.us-east-2.amazonaws.com/apps/windows.exe"
              target="_blank"
            >
              Baixar agora
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1} direction={"row"} className="blue">
              <img
                id={"linux-icon"}
                src={`${process.env.PUBLIC_URL}/linux-icon.svg`}
                alt=""
              />
              <Typography variant={"h5"}>Download para Linux</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Requisitos do sistema:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p">
              Instalador compatível com a distribuição do Linux.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>(4.54 MB) - Português (pt-BR) - 12/03/2021</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant={"contained"}
              color="primary"
              size="small"
              href="https://devassis.s3.us-east-2.amazonaws.com/apps/linux.exe"
              target="_blank"
            >
              Baixar agora
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Grid container spacing={1} id="benefits-info">
          <Grid item xs={12} className="blue">
            <Typography variant={"h5"}>Benefícios</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={1} textAlign={"center"}>
            <CheckCircle color="primary" />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              <strong>Facilidade na integração e geração dos dados:</strong>Sem
              a necessidade de ter equipe de desenvolvimento para integração.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={1} textAlign={"center"}>
            <CheckCircle color="primary" />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              <strong>Maior segurança:</strong> Procedimentos que asseguram a
              privacidade e a confidencialidade das informações, criptografia
              avançada e autenticação rigorosa.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={1} textAlign={"center"}>
            <CheckCircle color="primary" />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              <strong>Controle automatizado do processo de geração:</strong>{" "}
              Resultados precisos e confiáveis, reduzindo erros humanos e
              melhorando a eficiência operacional.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={1} textAlign={"center"}>
            <CheckCircle color="primary" />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              <strong>
                Maior flexibilidade na configuração de multiplas fontes de
                dados:
              </strong>{" "}
              bancos de dados, APIs e sistemas externos, podem ser configurados
              facilmente.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToolScreen;
