import {
  Event,
  FiberManualRecord,
  Groups,
  MenuBook,
  OpenInNew,
  Percent,
  Person,
  RadioButtonChecked,
  Recommend,
  School,
  SpeakerNotes,
  Summarize,
  Timeline,
  Today,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { Recommendation } from "./types";

type Props = {
  filterBatch: () => void;
  recommendation: Recommendation;
};

const StudentsGradeClass = ({ filterBatch, recommendation }: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>{`${recommendation.list?.length} alunos (${recommendation.percent}% da turma) tiveram uma piora no desempenho, com base nas análises dos 2 últimos envios.`}</Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <SpeakerNotes />
            <Typography>
              Recomendamos que você acione os alunos listados para entender o
              que pode ter acontecido. Para isso, seguem algumas sugestões de
              análise:
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Observar a frequência do aluno e a participação do aluno na turma:
              interações e respostas;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Identificar possíveis erros/dificuldades no conteúdo e/ou nos OAs;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Coletar feedback dos alunos sobre o conteúdo do curso e da
              plataforma de ensino.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <MenuBook />
                <Typography>
                  <strong>Curso:</strong>
                </Typography>
                <Typography>{recommendation?.course?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Groups />
                <Typography>
                  <strong>Turma:</strong>
                </Typography>
                <Typography>{recommendation?.class?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Percent />
                <Typography>
                  <strong>Progresso da turma:</strong>
                </Typography>
                <Typography>
                  {(recommendation?.conclusion * 100).toFixed(2)}% de conclusão
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Today />
                <Typography>
                  <strong>Data de início da turma:</strong>
                </Typography>
                <Typography>
                  {recommendation?.startDate &&
                    moment(recommendation?.startDate).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Event />
                <Typography>
                  <strong>Data de término da turma:</strong>
                </Typography>
                <Typography>
                  {recommendation?.endDate &&
                    moment(recommendation?.endDate).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <School />
                <Typography>
                  <strong>Tutor:</strong>
                </Typography>
                <Typography>{recommendation?.tutor?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Summarize />
                <Typography>
                  <strong>Análise que disparou a recomendação:</strong>
                </Typography>
                <Link onClick={filterBatch} sx={{ cursor: "pointer" }}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {moment(recommendation?.date)
                        .add(3, "hours")
                        .format("DD/MM/YYYY")}
                    </Typography>
                    <OpenInNew />
                  </Stack>
                  <Typography></Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={recommendation.list?.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "name",
              headerName: "Aluno",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Aluno</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "previousAverageGrade",
              headerName: "Média de avaliação anterior",
              flex: 1,
              minWidth: 120,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.previousAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.previousAverageGrade}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média de avaliação</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "actualAverageGrade",
              headerName: "Média de avaliação atual",
              flex: 1,
              minWidth: 120,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.actualAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.actualAverageGrade}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média de avaliação atual</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "variation",
              headerName: "Variação de pontuação",
              flex: 1,
              minWidth: 120,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={`${Number(params.row?.variation).toFixed(2) || ""}`}
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${Number(params.row?.variation).toFixed(2)}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Variação de pontuação</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "studentLastLogin",
              headerName: "Último Acesso do Aluno",
              flex: 1,
              minWidth: 120,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    (params.row?.studentLastLogin &&
                      `${moment(params.row?.studentLastLogin).format(
                        "DD/MM/YYYY"
                      )}`) ||
                    "Não informado"
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.studentLastLogin &&
                      `${moment(params.row?.studentLastLogin).format(
                        "DD/MM/YYYY"
                      )}`) ||
                      "Não informado"}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Último Acesso do Aluno</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "accessTime",
              headerName: "Assiduidade do aluno",
              flex: 1,
              minWidth: 120,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.accessTime || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.accessTime}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Assiduidade do aluno</strong>
                  </Typography>
                </Stack>
              ),
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5]}
        />
      </Grid>
    </>
  );
};

export default StudentsGradeClass;
