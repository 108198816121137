import axios from "axios";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
const backendRequest = axios.create({
  baseURL: process.env.REACT_APP_API_NEW_HOST,
  headers: { Authorization: "Bearer " + window.localStorage.getItem("token") },
});

backendRequest.interceptors.request.use(
  async (config) => {
    const token = (await fetchAuthSession()).tokens.idToken.toString();
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

backendRequest.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) signOut();
    return Promise.reject(error);
  }
);

// Auth
const login = async (payload) =>
  await backendRequest.post("/auth/login", payload);
const getAuthenticatedUser = async (payload) =>
  await backendRequest.get("/auth/authenticated");

// Clients
const getClients = async () => await backendRequest.get("/companies");
const createCompany = async (payload) =>
  await backendRequest.post("/companies", payload);
const getCompanyById = async (payload) =>
  await backendRequest.get(`/companies/${payload}`);
const updateCompany = async (payload) =>
  await backendRequest.patch(`/companies/${payload.id}`, payload);
const getFeatureFlags = async () => await backendRequest.get("/feature-flags");

// Tutor
const getTutors = async () => await backendRequest.get("/tutors");
const getTutorsEvasion = async (payload) =>
  await backendRequest.post("/tutors/evasion", payload);

// Course
const getCourses = async () => await backendRequest.get("/courses");
const getCoursesEvasion = async (payload) =>
  await backendRequest.post("/courses/evasion", payload);
// const getCoursesEvasion = (payload) =>
//     _apiRequest({method: 'post', path: `/course/evasion`, data: payload})

// Class
const getClasses = async () => await backendRequest.get("/classes");
const getClassesEvasion = async (payload) =>
  await backendRequest.post("/classes/evasion", payload);

// Subjects
const getSubjects = async () => await backendRequest.get("/subjects");
const getSubjectsEvasion = async (payload) =>
  await backendRequest.post("/subjects/evasion", payload);

// Student
const getStudents = async (payload) =>
  await backendRequest.post("/students/evasion", payload);
// _apiRequest({ method: "post", path: `/student/evasion`, data: payload });
const getDisability = async (payload) =>
  await backendRequest.get(`/student/disability`, payload);
const getStudentsReport = async (payload) =>
  await backendRequest.get(`/student/report`, payload);
// const getStudentsEvasion = (payload) =>
//     _apiRequest({method: 'post', path: `/student/evasion`, data: payload})

// User
const getUsers = async (payload) =>
  await backendRequest.get("/users", { params: payload });
const newUser = async (payload) => await backendRequest.post("/users", payload);
const deleteUser = async (payload) =>
  await backendRequest.delete(`/users/${payload}`);
const updateUser = async (payload) =>
  await backendRequest.patch(`/users`, payload);
const sendAccess = async (payload) =>
  await backendRequest.post("/users/send-access", payload);
const changeRole = async (payload) =>
  await backendRequest.post("/users/change-role", payload);

// _apiRequest({method: 'delete', path: `/user/${payload}`})

// const enableUser = (payload) =>
//     _apiRequest({method: 'patch', path: `/user/${payload}/enable`})
// const disableUser = (payload) =>
//     _apiRequest({method: 'patch', path: `/user/${payload}/disable`})
// const changeUserRoleToManager = (payload) =>
//     _apiRequest({method: 'patch', path: `/user/${payload}/change-to-admin`})
// const changeUserRoleToTutor = (payload) =>
//     _apiRequest({method: 'patch', path: `/user/${payload}/change-to-tutor`})
// const sendAccess = (payload) =>
//     _apiRequest({method: 'post', path: `/user/send-access`, data: payload})
// const saveMyData = (payload) =>
//     _apiRequest({method: 'post', path: `/user/me`, data: payload})
// const changePassword = (payload) =>
//     _apiRequest({method: 'post', path: `/user/me/password`, data: payload})
// const changeProfilePicture = (payload) =>
//     _apiRequest({method: 'post', path: `/user/me/picture`, data: payload})

// Metrics
const getMetrics = async (payload) =>
  await backendRequest.post("/metrics", payload);

// Indicators
const getIndicators = async (payload) =>
  await backendRequest.post("/indicators", payload);

// Period Chart & Batchs
// const getBatchs = (payload) => await backendRequest.get("/batch" });
const getBatchs = async () => await backendRequest.get("/batchs");
const getBatchInfo = async (payload) =>
  await backendRequest.post("/batchs/evasion", payload);
// _apiRequest({
//   method: "post",
//   path: `/batch/${payload.batchId}`,
//   data: payload,
// });

// Chatbot
const chatbot = async (payload) =>
  await backendRequest.post("/chatbot", payload);

// Notification
const getNotifications = async (payload) =>
  await backendRequest.get("/notification");
const readNotification = async (payload) =>
  await backendRequest.get(`/notification/${payload}/read`);
const deleteNotification = async (payload) =>
  await backendRequest.delete(`/notification/${payload}`);

//Alert
const getAlertActions = async (payload) =>
  await backendRequest.get("/alert-action", payload);
const changeAlertActions = async (payload) =>
  await backendRequest.put(`/alert-action/${payload.id}`, payload);
const getAlerts = async (payload) =>
  await backendRequest.get("/alert", { params: payload });
const saveAlert = async (payload) =>
  await backendRequest.post("/alert", payload);
const updateAlert = async (payload) =>
  await backendRequest.patch(`/alert/${payload._id}`, payload);
const deleteAlert = async (payload) =>
  await backendRequest.delete(`/alert/${payload}`);
const getRuleFields = async (payload) =>
  await backendRequest.get(`/alert/rule-fields`);
const enableAlert = async (payload) =>
  await backendRequest.patch(`/alert/${payload}/enable`);
const disableAlert = async (payload) =>
  await backendRequest.patch(`/alert/${payload}/disable`);
const remonitorAlert = async (payload) =>
  await backendRequest.patch(`/alert/${payload}/remonitor`);
const duplicateAlert = async (payload) =>
  await backendRequest.patch(`/alert/${payload}/duplicate`);

//Chat
const sendMessage = async (payload) =>
  await backendRequest.post("/chatbot", payload);

//BatchLogs
const getBatchlogs = async (payload) =>
  await backendRequest.get("batchs/batchlogs");

//Consultant
const getQuantitative = async (payload) =>
  await backendRequest.post("batchs/quantitative", payload);

//Classification
const getClassification = async (payload) =>
  await backendRequest.get("/classifications", payload);

//Feedback e Tip
const getFeedbackTipOrQuestion = async (payload) =>
  await backendRequest.get("/feedback/tip-or-question");
const confirmFeedbackTipDelivered = async (payload) =>
  await backendRequest.get(`/tip/${payload}/confirm-delivered`);
const saveFeedbackAnswer = async (payload) =>
  await backendRequest.post(`/feedback/${payload._id}`, payload);

// Recommendation
const getRecommendations = async (payload) =>
  await backendRequest.post("/recommendations", payload);
const getRecommendersHistory = async (payload) =>
  await backendRequest.post("/recommendations/history", payload);
const sendRecommenderByEmail = async (payload) =>
  await backendRequest.post("/recommendations/notify-email", payload);

export default {
  // Auth
  login,
  getAuthenticatedUser,
  // Clients
  getClients,
  createCompany,
  getCompanyById,
  updateCompany,
  getFeatureFlags,

  // Tutor
  getTutors,
  getTutorsEvasion,
  // getTutorsEvasion,
  // Course
  getCourses,
  getCoursesEvasion,
  // getCoursesEvasion,
  // Class
  getClasses,
  getClassesEvasion,
  // Subject
  getSubjects,
  getSubjectsEvasion,
  // Student
  getStudents,
  getDisability,
  getStudentsReport,
  // getStudentsEvasion,
  // User
  getUsers,
  newUser,
  updateUser,
  deleteUser,
  sendAccess,
  changeRole,

  // enableUser,
  // disableUser,
  // changeUserRoleToManager,
  // changeUserRoleToTutor,
  // saveMyData,
  // changePassword,
  // changeProfilePicture,

  // Indicators
  getIndicators,
  // Metrics
  getMetrics,
  // Batchs
  getBatchs,
  getBatchInfo,
  // Chatbot
  chatbot,
  // Notification
  getNotifications,
  readNotification,
  deleteNotification,
  //Alerts
  getAlertActions,
  changeAlertActions,
  getAlerts,
  saveAlert,
  updateAlert,
  deleteAlert,
  getRuleFields,
  enableAlert,
  disableAlert,
  remonitorAlert,
  duplicateAlert,
  //Chat
  sendMessage,
  //BatchLogs
  getBatchlogs,
  //Quantitative
  getQuantitative,
  //Classification
  getClassification,
  //Feedback
  getFeedbackTipOrQuestion,
  confirmFeedbackTipDelivered,
  saveFeedbackAnswer,
  //Recommendation
  getRecommendations,
  getRecommendersHistory,
  sendRecommenderByEmail,
};
