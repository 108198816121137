import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getBatchLogs: ["payload"],
  getBatchLogsSuccess: ["payload"],
  getBatchLogsFailure: ["payload"],
});

const INITIAL_STATE = {
  rows: [],
  getBatchLogLoader: false,
};

const getBatchLogs = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchLogLoader: true,
});

const getBatchLogsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchLogLoader: false,
  rows: action.payload,
});

const getBatchLogsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchLogLoader: false,
  rows: [],
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_BATCH_LOGS]: getBatchLogs,
  [Types.GET_BATCH_LOGS_SUCCESS]: getBatchLogsSuccess,
  [Types.GET_BATCH_LOGS_FAILURE]: getBatchLogsFailure,
});
