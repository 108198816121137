import moment from "moment";

const moneyFormat = (value) => {
  return `R$ ${parseFloat(value).toFixed(2).replace(".", ",")}`;
};

const moneyMask = (value, showZero = false) => {
  value = value.replace(/\D/g, "");
  value = parseInt(value, 10).toString();

  if (value === "NaN" || (value === "0" && !showZero)) {
    value = "";
  } else if (value.length === 1) {
    value = moneyFormat(`0.0${value}`);
  } else if (value.length === 2) {
    value = moneyFormat(`0.${value}`);
  } else if (value.length >= 3) {
    value =
      value.substr(0, value.length - 2) + "." + value.substr(value.length - 2);
    value = moneyFormat(value);
  }
  return value;
};

const moneyUnmask = (value) => {
  if (value !== "" && value !== "R$ 0,00") {
    return parseFloat(moneyMask(value).replace("R$ ", "").replace(",", "."));
  } else {
    return 0;
  }
};

const cpfMask = (value) => {
  value = value.replace(/\D/g, "");
  let length = value.length;

  if (length <= 3) return value;
  else if (length > 3 && length < 7)
    return `${value.substr(0, 3)}.${value.substr(3, 3)}`;
  else if (length >= 7 && length < 10)
    return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}`;
  else if (length >= 10)
    return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(
      6,
      3
    )}-${value.substr(9, 2)}`;
};

const cnpjMask = (value) => {
  value = value.replace(/\D/g, "");
  let length = value.length;

  if (length <= 3) return value;
  if (length > 3 && length < 7)
    return `${value.substr(0, 3)}.${value.substr(3, 3)}`;
  else if (length >= 7 && length < 10)
    return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}`;
  else if (length >= 10 && length < 14)
    return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(
      6,
      3
    )}/${value.substr(9, 4)}`;
  else if (length >= 14)
    return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(
      6,
      3
    )}/${value.substr(9, 4)}-${value.substr(13, 2)}`;
};

const digitMask = (value) => {
  value = value.replace(/\D/g, "");
  value = `+55${value}`;
  return value;
};

const dateMask = (value) => {
  value = value.replace(/\D/g, "");
  let length = value.length;

  if (length > 2 && length < 5)
    return `${value.substr(0, 2)}/${value.substr(2, 2)}`;
  else if (length >= 5)
    return `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4, 4)}`;
};

const phoneMask = (value) => {
  if (!value) return "";

  value = value.replace(/\D/g, "");
  if (!value.length) return "";

  let ddd = value.slice(0, 2);
  let firstBlock = value.slice(2, 6);
  let secondBlock = value.slice(6, 10);
  if (value.length > 10) {
    firstBlock = value.slice(2, 7);
    secondBlock = value.slice(7, 11);
  }

  let formatedPhone = `(${ddd}`;
  if (firstBlock.length) formatedPhone += ") " + firstBlock;

  if (secondBlock.length) formatedPhone += `-${secondBlock}`;

  return formatedPhone;
};

const validateForm = (form) => {
  if (!form.checkValidity()) {
    let stateToSet = {};
    for (let i = 0; i < form.length; i++) {
      const element = form[i];
      if (
        element.type === "text" ||
        element.type === "email" ||
        element.type === "password" ||
        element.type === "select"
      ) {
        let elementStateToSet = validateFormElement(element);
        stateToSet = { ...stateToSet, ...elementStateToSet };
      }
    }
    return stateToSet;
  }
  return true;
};

const validateFormElement = (element) => {
  if (element.checkValidity()) {
    return {
      [`${element.name}HasError`]: false,
      [`${element.name}ErrorText`]: null,
    };
  } else {
    return {
      [`${element.name}HasError`]: true,
      [`${element.name}ErrorText`]: element.validationMessage,
    };
  }
};

const cashflowType = (statusEnum) => {
  const STATUS = {
    open: "Abertura",
    close: "Fechamento",
    deposit: "Depósito",
    loot: "Saque",
    admin_loot: "Sangria",
    payment: "Pagamento",
  };
  return STATUS[statusEnum];
};

const userType = (statusEnum) => {
  const STATUS = {
    super: "Delta Park",
    admin: "Administrador",
    cashier: "Operador de caixa",
  };
  return STATUS[statusEnum];
};

const automaticReadStatus = (statusEnum) => {
  const STATUS = {
    waiting: "Aguardando",
    accept: "Aceito",
    reject: "Rejeitado",
  };
  return STATUS[statusEnum];
};

const plateMask = (value) => {
  let chars = value.toUpperCase().split("");
  let letterRegex = /[^a-zA-Z]/;
  let digitRegex = /^\D/;
  let letterOrDigitRegex = /[^a-zA-Z0-9]/;
  let newValue = "";

  if (!letterRegex.test(chars[0]) && value.length >= 1) newValue += chars[0];
  if (!letterRegex.test(chars[1]) && value.length >= 2) newValue += chars[1];
  if (!letterRegex.test(chars[2]) && value.length >= 3) newValue += chars[2];

  if (!digitRegex.test(chars[3]) && value.length >= 4) newValue += chars[3];

  if (!letterOrDigitRegex.test(chars[4]) && value.length >= 5)
    newValue += chars[4];

  if (!digitRegex.test(chars[5]) && value.length >= 6) newValue += chars[5];
  if (!digitRegex.test(chars[6]) && value.length >= 7) newValue += chars[6];

  return newValue;
  // return value.toUpperCase().slice(0, 7)
};

const calculateParkingCost = ({ flow, whiteList = [], format = true }) => {
  let minutesInPark = moment().diff(flow.inDate, "minutes");
  let value = 0;

  if (flow.paymentDate) return "Pago";

  if (
    whiteList.find(
      (item) => item.plate === flow.plate && item.disabled === false
    )
  )
    return "Liberado";

  if (flow.outDate)
    minutesInPark = moment(flow.outDate).diff(flow.inDate, "minutes");

  if (minutesInPark <= flow.type.tolerance) {
    value = 0;
  } else if (minutesInPark > 60) {
    let fractions = Math.ceil(minutesInPark / flow.type.fraction);
    let fractionValue = flow.type.hourValue / (60 / flow.type.fraction);
    value = fractions * fractionValue;
  } else {
    value = flow.type.hourValue;
  }

  if (format === true) return moneyFormat(value);
  return value;
};

const calculateParkingTime = (flow) => {
  let hoursInPark = moment().diff(flow.inDate, "hours");
  let minutesInPark = moment().diff(flow.inDate, "minutes");

  let hoursString = hoursInPark.toString().padStart(2, "0");
  let minutesString = (minutesInPark - hoursInPark * 60)
    .toString()
    .padStart(2, "0");
  return `${hoursString}:${minutesString}`;
};

const capitalize = (str) =>
  str
    .toLowerCase()
    .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

const dateFormat = (date, format) => {
  return moment(date).format(format);
};
export default {
  automaticReadStatus,
  calculateParkingCost,
  calculateParkingTime,
  cashflowType,
  cnpjMask,
  cpfMask,
  validateFormElement,
  moneyFormat,
  moneyMask,
  moneyUnmask,
  digitMask,
  dateMask,
  phoneMask,
  plateMask,
  validateForm,
  userType,
  capitalize,
  dateFormat,
};
