/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  TextField,
  Typography,
  Grid,
  Select,
  FormControl,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  InputLabel,
  MobileStepper,
} from "@mui/material";
import Alert from "@mui/material/Alert";

import AddAlertIcon from "@mui/icons-material/Add";
import CoursesIcon from "@mui/icons-material/MenuBook";
import TutorIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Group";
// import DescriptionIcon from "@mui/icons-material/Subject";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import RemoveAlertIcon from "@mui/icons-material/RemoveCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

import Loading from "../Loading";
import Utils from "../../Utils";
import FilterPopup from "../../components/FilterPopup";

import { Creators as AlertActions } from "../../store/ducks/alert";
import { Creators as TutorActions } from "../../store/ducks/tutor";
import { Creators as CourseActions } from "../../store/ducks/course";
import { Creators as ClassActions } from "../../store/ducks/class";

import "./style.sass";

const AlertForm = (props) => {
  const dispatch = useDispatch();

  const ruleConditions = [
    { symbol: ">", text: "Maior que" },
    { symbol: "<", text: "Menor que" },
    { symbol: ">=", text: "Maior ou igual a" },
    { symbol: "<=", text: "Menor ou igual a" },
    { symbol: "=", text: "Igual a" },
  ];

  const formDataInitial = {
    _id: null,
    title: "",
    description: "",
    tutorIds: [],
    courseIds: [],
    classIds: [],
    subjectIds: [],
    studentIds: [],
    rules: [],
    whatsapp: false,
    email: false,
    sendTo: "",
    limitDate: "",
    reminder: [""],
  };

  const [activeStep, setActiveStep] = useState(1);

  const selectedAlert = useSelector((state) => state.alert.selectedAlert);
  const tutors = useSelector((state) => state.tutor.list);
  const [selectedTutors, setSelectedTutors] = useState([]);
  const courses = useSelector((state) => state.course.list);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const classes = useSelector((state) => state.class.list);
  const [selectedClasses, setSelectedClasses] = useState([]);

  const ruleFields = useSelector((state) => state.alert.ruleFieldList);
  const showLoading = useSelector((state) => state.alert.saveAlertLoading);
  const successMsg = useSelector((state) => state.alert.saveAlertSuccessMsg);
  const errorMsg = useSelector((state) => state.alert.saveAlertErrorMsg);

  const [formData, setFormData] = useState(formDataInitial);
  // const [rulesData, setRulesData] = useState([initialRuleItem]);

  const inputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "whatsapp") value = event.target.checked;
    if (name === "email") value = event.target.checked;
    if (name === "ruleField") {
      let index = event.target.getAttribute("data-index");
      let rules = formData.rules;
      rules[index].field = value;
      setFormData({ ...formData, rules });
      return;
    }
    if (name === "ruleCondition") {
      let index = event.target.getAttribute("data-index");
      let rules = formData.rules;
      rules[index].condition = value;
      setFormData({ ...formData, rules });
      return;
    }
    if (name === "ruleValue") {
      let index = event.target.getAttribute("data-index");
      let rules = formData.rules;
      rules[index].value = value;
      setFormData({ ...formData, rules });
      return;
    }
    if (name === "reminder") {
      let index = event.target.getAttribute("data-index");
      let reminder = formData.reminder;
      reminder[index] = value;
      setFormData({ ...formData, reminder });
      return;
    }
    setFormData({ ...formData, [name]: value });
    return;
  };

  const onSelectTutors = (tutorList) => setSelectedTutors(tutorList);
  const onSelectCourses = (courseList) => setSelectedCourses(courseList);
  const onSelectClasses = (classList) => setSelectedClasses(classList);

  const addRule = () => {
    let rules = formData.rules;
    rules.push({
      field: ruleFields[0].field,
      condition: ruleConditions[0].symbol,
      value: 0,
    });
    setFormData({ ...formData, rules });
  };

  const removeRule = (index) => {
    let rules = formData.rules;
    rules.splice(index, 1);
    setFormData({ ...formData, rules });
  };

  const addAction = () => {
    let reminder = formData.reminder;
    reminder.push("");
    setFormData({ ...formData, reminder });
  };

  const removeAction = (index) => {
    let reminder = formData.reminder;
    reminder.splice(index, 1);
    setFormData({ ...formData, reminder });
  };

  const submitAction = (event) => {
    event.preventDefault();
    let result = Utils.validateForm(event.target);
    if (result !== true) {
      setFormData({ ...formData, ...result });
    } else {
      const dataToSend = {
        _id: formData._id,
        title: formData.title,
        description: formData.description,
        tutorIds: selectedTutors.map((tutor) => tutor.tutorId),
        courseIds: selectedCourses.map((tutor) => tutor.courseId),
        classIds: selectedClasses.map((tutor) => tutor.classId),
        subjectIds: formData.subjectIds,
        studentIds: formData.studentIds,
        rules: formData.rules,
        whatsapp: formData.whatsapp ? Utils.digitMask(formData.whatsapp) : null,
        email: formData.email,
        sendTo: formData.sendTo,
        limitDate: formData.limitDate,
        reminder: formData.reminder.filter((reminder) => reminder !== ""),
      };
      // console.log('dataToSend', dataToSend)
      if (selectedAlert) dispatch(AlertActions.updateAlert(dataToSend));
      else dispatch(AlertActions.saveAlert(dataToSend));
    }
  };

  const onClose = () => {
    props.onClose();
    dispatch(AlertActions.closeAlertForm());
  };

  useEffect(() => {
    dispatch(TutorActions.getTutors());
    dispatch(CourseActions.getCourses());
    dispatch(ClassActions.getClasses());
    dispatch(AlertActions.getRuleFields());
  }, []);

  // useEffect(() => {
  //   if(!selectedAlert && ruleFields.length > 0){
  //     console.log('xxxxx', ruleFields)
  //     addRule()
  //   }
  // }, [ruleFields])

  useEffect(() => {
    if (selectedAlert) {
      const seletedAlertData = {
        _id: selectedAlert._id,
        title: selectedAlert.title,
        description: selectedAlert.description,
        tutorIds: selectedAlert.tutorIds,
        courseIds: selectedAlert.courseIds,
        classIds: selectedAlert.classIds,
        subjectIds: selectedAlert.subjectIds,
        studentIds: selectedAlert.studentIds,
        rules: selectedAlert.rules,
        whatsapp: Utils.phoneMask(selectedAlert.whatsapp),
        email: selectedAlert.email,
        sendTo: selectedAlert.sendTo,
        limitDate: selectedAlert.limitDate
          ? moment(selectedAlert.limitDate).format("yyyy-MM-DD")
          : "",
        reminder: selectedAlert.reminder.length ? selectedAlert.reminder : [""],
      };
      setSelectedTutors(selectedAlert.tutors);
      setSelectedCourses(selectedAlert.courses);
      setSelectedClasses(selectedAlert.classes);
      // setSelectedSubjects(selectedAlert.subjects)
      // setSelectedStudents(selectedAlert.Students)
      setFormData(seletedAlertData);
    }
    // console.log('seletedAlertData', selectedAlert)
  }, [selectedAlert]);

  const stepOne = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">O que/quem deseja monitorar?</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography className="label">
          <TutorIcon />
          Tutores
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <FilterPopup
          label="Tutores"
          options={tutors}
          selectedOptions={selectedTutors}
          onConfirm={onSelectTutors}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography className="label">
          <CoursesIcon />
          Cursos
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <FilterPopup
          label="Cursos"
          options={courses}
          selectedOptions={selectedCourses}
          onConfirm={onSelectCourses}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography className="label">
          <ClassIcon />
          Turmas
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <FilterPopup
          label="Turmas"
          options={classes}
          selectedOptions={selectedClasses}
          onConfirm={onSelectClasses}
        />
      </Grid>
    </Grid>
  );

  const stepTwo = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Configure os indicadores</Typography>
      </Grid>
      {/* <Grid item xs={12}>
        {getRuleFieldsLoading && <Loading />}
      </Grid> */}
      {formData.rules.map((ruleData, index) => (
        <>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Escolha o indicador</InputLabel>
              <Select
                native
                name="ruleField"
                fullWidth
                data-index={index}
                onChange={inputChange}
                // value={formData.rules[index].field}
                value={ruleData.field}
                inputProps={{
                  "data-index": index,
                }}
              >
                {ruleFields.map((ruleField, index) => (
                  // <MenuItem key={index} value={ruleField.field}>{ruleField.name}</MenuItem>
                  <option key={index} value={ruleField.field}>
                    {ruleField.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Escolha o parâmetro</InputLabel>
              <Select
                native
                name="ruleCondition"
                data-index={index}
                onChange={inputChange}
                // value={formData.rules[index].condition}
                value={ruleData.condition}
                inputProps={{
                  "data-index": index,
                }}
              >
                {ruleConditions.map((ruleCondition, index) => (
                  <option key={index} value={ruleCondition.symbol}>
                    {ruleCondition.text}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Insira a porcentagem</InputLabel>
              <Select
                native
                name="ruleValue"
                onChange={inputChange}
                value={ruleData.value}
                inputProps={{
                  "data-index": index,
                }}
              >
                {[...Array(101)].map((item, index) => (
                  <option key={index} value={index}>
                    {index}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} className="rules-buttons-wrapper">
            {(index !== 0 || (formData.rules.length > 1 && index === 0)) && (
              <IconButton onClick={() => removeRule(index)} size="large">
                <RemoveAlertIcon color="primary" />
              </IconButton>
            )}
          </Grid>
        </>
      ))}
      <Grid item xs={12}>
        <Button onClick={() => addRule()}>
          <AddAlertIcon color="primary" />
          Adicionar indicador
        </Button>
        {/* {(index !== 0 || (formData.rules.length > 1 && index === 0)) && (
          <IconButton
            onClick={() => removeRule(index)}>
            <RemoveAlertIcon color="primary" />
          </IconButton>
        )}
        
        {(index === formData.rules.length-1) && (
          <IconButton
            onClick={() => addRule()}
          >
            <AddAlertIcon color="primary" />
          </IconButton>
        )} */}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">
          Exemplo: Acompanhar o Risco de Evasão (indicador) maior ou igual
          (parâmetro) a 50% (porcentagem) dos tutores (monitorados).
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">
          Para indicar potencial de evasão considere os percentuais abaixo:
        </Typography>
        <Typography variant="caption">1. baixo risco: até 25%</Typography>
        <Typography variant="caption">2. médio risco: até 50%</Typography>
        <Typography variant="caption">3. alto risco: até 75%</Typography>
        <Typography variant="caption">
          4. altíssimo risco: a partir de 75,01%
        </Typography>
      </Grid>
    </Grid>
  );

  const stepThree = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Até quando deseja receber esse alerta?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="limitDate"
          label="Monitorando Até"
          type="date"
          onChange={inputChange}
          InputLabelProps={{
            shrink: true,
          }}
          value={formData.limitDate}
          error={formData.limitDateHasError}
          helperText={formData.limitDateErrorText}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          Fora você, quem mais receberá esse alerta?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="sendTo"
          label="Enviar também para"
          fullWidth
          gutterButton
          onChange={inputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
          value={formData.sendTo}
          error={formData.sendToHasError}
          helperText={formData.sendToErrorText}
        />
      </Grid>
    </Grid>
  );

  const stepFour = () => (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">Defina lembretes para esse alerta</Typography>
      </Grid>
      {formData.reminder.map((action, index) => (
        <>
          <Grid item xs={10}>
            <TextField
              name="reminder"
              fullWidth
              onChange={inputChange}
              margin="dense"
              variant="outlined"
              multiline
              rows={2}
              value={formData.reminder[index]}
              inputProps={{
                "data-index": index,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            {(index !== 0 || (formData.reminder.length > 1 && index === 0)) && (
              <IconButton onClick={() => removeAction(index)} size="large">
                <RemoveAlertIcon color="primary" />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            {index === formData.reminder.length - 1 && (
              <Button onClick={() => addAction()}>
                <AddAlertIcon color="primary" />
                Adicionar lembrete
              </Button>
            )}
          </Grid>
        </>
      ))}
      <Grid item xs={12}>
        <Typography variant="caption">
          Exemplo: Enviar email solicitando uma reunião com todos os tutores.
        </Typography>
      </Grid>
    </Grid>
  );

  const stepValid = () => {
    if (activeStep === 1) {
      if (
        formData.title &&
        (selectedTutors.length ||
          selectedCourses.length ||
          selectedClasses.length)
      )
        return true;
    } else if (activeStep === 2) {
      if (
        formData.rules.some(
          (rule) => rule.field && rule.condition && rule.value > 0
        )
      )
        return true;
    } else if (activeStep === 3) {
      if (formData.limitDate !== "") return true;
    } else if (activeStep === 4) {
      return false;
    }
    return false;
  };

  return (
    <Dialog
      className="alert-form-dialog"
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      open={true}
    >
      <MobileStepper
        className="steps"
        variant="progress"
        steps={5}
        position="static"
        activeStep={activeStep}
      />
      <TextField
        className="alert-title"
        name="title"
        label="Título"
        required
        fullWidth
        onChange={inputChange}
        value={formData.title}
        error={formData.titleHasError}
        helperText={formData.titleErrorText}
      />
      <DialogTitle className="dialog-title">
        <IconButton
          className="title-close-button"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {showLoading && <Loading />}
        {successMsg && <Alert severity="success">{successMsg}</Alert>}
        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        <form
          id="alert-form"
          className="alert-form"
          noValidate
          autoComplete="off"
          onSubmit={submitAction}
        >
          {activeStep === 1 && stepOne()}
          {activeStep === 2 && stepTwo()}
          {activeStep === 3 && stepThree()}
          {activeStep === 4 && stepFour()}
        </form>
      </DialogContent>
      <DialogActions>
        {activeStep > 1 && (
          <Button
            className="back-button"
            variant="contained"
            color="primary"
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Anterior
          </Button>
        )}
        <Button
          type="cancel"
          className="cancel-button"
          variant="outlined"
          onClick={onClose}
        >
          Cancelar
        </Button>
        {activeStep < 4 && (
          <Button
            variant="outlined"
            disabled={!stepValid()}
            onClick={() => setActiveStep(activeStep + 1)}
          >
            Próximo
          </Button>
        )}
        {activeStep === 4 && (
          <Button variant="outlined" form="alert-form" type="submit">
            Salvar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

AlertForm.defaultProps = {
  onClose: () => {},
};

export default AlertForm;
