import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/tutor";

function* getTutors(action) {
  try {
    const response = yield call(Api.getTutors, action.payload);
    yield put({ type: Types.GET_TUTORS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_TUTORS_FAILURE, errorMessage: error.message });
  }
}

function* getTutorsEvasion(action) {
  try {
    const response = yield call(Api.getTutorsEvasion, action.payload);
    yield put({ type: Types.GET_TUTORS_EVASION_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_TUTORS_EVASION_FAILURE,
      payload: error.message,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_TUTORS, getTutors),
    takeLatest(Types.GET_TUTORS_EVASION, getTutorsEvasion),
  ]);
}
