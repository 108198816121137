import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import { AppTheme } from "./Themes";
import "./App.sass";

import Data from "./screens/Data";
import Users from "./screens/Users";
import Profile from "./screens/Profile";
import Header from "./containers/Header";
import Alert from "./containers/Alert";
import SnackbarGlobal from "./containers/SnackbarGlobal";
// import Confirm from './containers/Confirm'
import AlertsScreen from "./screens/Alerts";
import BatchLogScreen from "./screens/BatchLog";
// import ConsultantScreen from "./screens/Consultant";
// import ReportScreen from "./screens/Report";
import ToolScreen from "./screens/Tool";
import { Creators as AppActions } from "./store/ducks/app";
import { Creators as AuthActions } from "./store/ducks/auth";

import Recommendations from "./screens/Recommendations";
import { SnackbarProvider } from "notistack";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from "aws-amplify/auth";

function App() {
  const dispatch = useDispatch();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { user } = useAuthenticator((context) => [context.user]);
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  const authAction = useCallback(async () => {
    const tokens = (await fetchAuthSession({ forceRefresh: true })).tokens;
    dispatch(
      AuthActions.getAuthenticatedUserSuccess({
        ...tokens.idToken.payload,
        username: tokens.accessToken.payload.username,
      })
    );
    dispatch(AppActions.getClassification());
  }, [user, authStatus]);

  useEffect(() => {
    if (authStatus === "authenticated") {
      authAction();
    } else if (authStatus === "unauthenticated") {
      console.log("unauthenticated");
    }
  }, [authStatus]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AppTheme}>
        <SnackbarProvider
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          Components={{
            error: SnackbarGlobal,
            info: SnackbarGlobal,
            success: SnackbarGlobal,
            warning: SnackbarGlobal,
          }}
        >
          <div className="app-wrapper">
            {authStatus === "authenticated" && (
              // <SnackbarProvider maxSnack={5}>
              <Router>
                <Header />
                <div className="content-wrapper">
                  <div className="content">
                    <Switch>
                      <Route exact={true} path="/" component={Data} />
                      <Route exact={true} path="/data" component={Data} />
                      <Route exact={true} path="/profile" component={Profile} />
                      <Route
                        exact={true}
                        path="/alerts"
                        component={AlertsScreen}
                      />
                      <Route
                        exact={true}
                        path="/recommendations"
                        component={Recommendations}
                      />
                      {authenticatedUser?.["cognito:groups"]?.includes(
                        "manager"
                      ) && (
                        <>
                          <Route exact={true} path="/users" component={Users} />
                          <Route
                            exact={true}
                            path="/tool"
                            component={ToolScreen}
                          />
                        </>
                      )}
                      <Route
                        exact={true}
                        path="/batchlog"
                        component={BatchLogScreen}
                      />
                      {/* {keycloak.hasResourceRole("consultant") && (
                        <Route
                          exact={true}
                          path="/consultant"
                          component={ConsultantScreen}
                        />
                      )}
                      {keycloak.hasResourceRole("consultant") && (
                        <Route
                          exact={true}
                          path="/reports"
                          component={ReportScreen}
                        />
                      )} */}
                      <Redirect from="*" to="/" />
                    </Switch>
                  </div>
                </div>
              </Router>
              // </SnackbarProvider>
            )}
          </div>
          <Alert />
          {/* <SnackbarGlobal /> */}
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
