import { createActions, createReducer } from "reduxsauce";
import moment from "moment";

export const { Types, Creators } = createActions({
  setSelectedCourses: ["payload"],
  setSelectedClasses: ["payload"],
  setSelectedSubjects: ["payload"],
  setSelectedTutors: ["payload"],
  setSelectedStudents: ["payload"],
  setSelectedEnrollments: ["payload"],
  openDateRangePicker: ["payload"],
  closeDateRangePicker: ["payload"],
  setDateRange: ["payload"],
  setSelectedBatch: ["payload"],
  changeFinished: ["payload"],
  showAlert: ["payload"],
  closeAlert: ["payload"],
  setAppSpeech: ["payload"],
  setFieldSpeech: ["payload"],
  showSnackbar: ["payload"],
  closeSnackbar: ["payload"],
  setCustomFilters: ["payload"],
  getClassification: ["payload"],
  getClassificationSuccess: ["payload"],
  getClassificationFailure: ["payload"],
  setBackRecommendation: ["payload"],
});

const INITIAL_STATE = {
  selectedCourses: [],
  selectedClasses: [],
  selectedSubjects: [],
  selectedStudents: [],
  selectedEnrollments: [],
  selectedTutors: [],
  selectedBatch: null,
  openDateRangePicker: false,
  startDate: moment().subtract(1, "month").format(),
  endDate: moment().format(),
  finished: false,
  showAlert: false,
  alertTitle: null,
  alertText: null,
  alertButtonText: "Ok",
  appSpeechActive: false,
  fieldSpeechName: null,
  showSnackbar: false,
  snackbarTitle: null,
  snackbarText: null,
  snackbarType: null,
  customFilters: {},
  getClassificationLoading: false,
  classification: [],
  backRecommendation: false,
};

const setSelectedCourses = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedCourses: action.payload,
});

const setSelectedClasses = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedClasses: action.payload,
});

const setSelectedSubjects = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedSubjects: action.payload,
});

const setSelectedTutors = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedTutors: action.payload,
});

const setSelectedStudents = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedStudents: action.payload,
});
const setSelectedEnrollments = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedEnrollments: action.payload,
});

const setSelectedBatch = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedBatch: action.payload,
});

const setCustomFilters = (state = INITIAL_STATE, action) => ({
  ...state,
  customFilters: action.payload,
});

const openDateRangePicker = (state = INITIAL_STATE, action) => ({
  ...state,
  openDateRangePicker: true,
});

const closeDateRangePicker = (state = INITIAL_STATE, action) => ({
  ...state,
  openDateRangePicker: false,
});

const setDateRange = (state = INITIAL_STATE, action) => ({
  ...state,
  startDate: action.payload.startDate,
  endDate: action.payload.endDate,
});

const changeFinished = (state = INITIAL_STATE, action) => ({
  ...state,
  finished: action.payload,
  customFilters: {},
});

const showAlert = (state = INITIAL_STATE, action) => ({
  ...state,
  showAlert: true,
  alertTitle: action.payload.title || INITIAL_STATE.alertTitle,
  alertText: action.payload.text || INITIAL_STATE.alertText,
  alertButtonText: action.payload.buttonText || INITIAL_STATE.alertButtonText,
});

const closeAlert = (state = INITIAL_STATE, action) => ({
  ...state,
  showAlert: INITIAL_STATE.showAlert,
  alertTitle: INITIAL_STATE.alertTitle,
  alertText: INITIAL_STATE.showAlert,
  alertButtonText: INITIAL_STATE.alertButtonText,
});

const setAppSpeech = (state = INITIAL_STATE, action) => ({
  ...state,
  appSpeechActive: action.payload,
});

const setFieldSpeech = (state = INITIAL_STATE, action) => ({
  ...state,
  fieldSpeechName: action.payload,
});

const showSnackbar = (state = INITIAL_STATE, action) => ({
  ...state,
  showSnackbar: true,
  snackbarTitle: action.payload.title,
  snackbarText: action.payload.text,
  snackbarType: action.payload.type,
});

const closeSnackbar = (state = INITIAL_STATE, action) => ({
  ...state,
  showSnackbar: false,
  snackbarTitle: INITIAL_STATE.snackbarTitle,
  snackbarText: INITIAL_STATE.snackbarText,
  snackbarType: INITIAL_STATE.snackbarType,
});

const getClassification = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassificationLoading: true,
});

const getClassificationSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassificationLoading: false,
  classification: action.payload,
});

const getClassificationFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassificationLoading: false,
  classification: INITIAL_STATE.classification,
});

const setBackRecommendation = (state = INITIAL_STATE, action) => ({
  ...state,
  backRecommendation: action.payload,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_SELECTED_BATCH]: setSelectedBatch,
  [Types.SET_SELECTED_COURSES]: setSelectedCourses,
  [Types.SET_SELECTED_CLASSES]: setSelectedClasses,
  [Types.SET_SELECTED_SUBJECTS]: setSelectedSubjects,
  [Types.SET_SELECTED_TUTORS]: setSelectedTutors,
  [Types.SET_SELECTED_STUDENTS]: setSelectedStudents,
  [Types.SET_SELECTED_ENROLLMENTS]: setSelectedEnrollments,
  [Types.OPEN_DATE_RANGE_PICKER]: openDateRangePicker,
  [Types.CLOSE_DATE_RANGE_PICKER]: closeDateRangePicker,
  [Types.SET_DATE_RANGE]: setDateRange,
  [Types.CHANGE_FINISHED]: changeFinished,
  [Types.SHOW_ALERT]: showAlert,
  [Types.CLOSE_ALERT]: closeAlert,
  [Types.SET_APP_SPEECH]: setAppSpeech,
  [Types.SET_FIELD_SPEECH]: setFieldSpeech,
  [Types.SHOW_SNACKBAR]: showSnackbar,
  [Types.CLOSE_SNACKBAR]: closeSnackbar,
  [Types.SET_CUSTOM_FILTERS]: setCustomFilters,
  [Types.GET_CLASSIFICATION]: getClassification,
  [Types.GET_CLASSIFICATION_SUCCESS]: getClassificationSuccess,
  [Types.GET_CLASSIFICATION_FAILURE]: getClassificationFailure,
  [Types.SET_BACK_RECOMMENDATION]: setBackRecommendation,
});
