import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Snackbar,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import DangerIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";

import { Creators as AppActions } from "../../store/ducks/app";

import "./style.sass";

interface SnackProps extends CustomContentProps {
  title?: string;
}

const SnackbarGlobal = React.forwardRef<HTMLDivElement, SnackProps>(
  ({ id, message, variant, title, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = React.useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} className="snackbar">
        <Paper className={`snackbar-content ${variant}`}>
          <Grid container spacing={2}>
            <Grid item xs={1} className="icon-wrapper">
              {variant === "info" && <InfoIcon />}
              {variant === "success" && <SuccessIcon />}
              {variant === "warning" && <WarningIcon />}
              {variant === "error" && <DangerIcon />}
            </Grid>
            <Grid item xs>
              <Typography variant="body2" className="title">
                {title}
              </Typography>
              <Typography variant="body2">{message}</Typography>
            </Grid>
            <Grid>
              <IconButton size="small" onClick={handleDismiss}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </SnackbarContent>
    );
  }
);

export default SnackbarGlobal;
