import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  InputLabel,
  InputAdornment,
  Button,
  FormControl,
  Grid,
  Typography,
  OutlinedInput,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Pagination from "@mui/material/Pagination";

import AddAlertIcon from "@mui/icons-material/AddAlert";
import SearchIcon from "@mui/icons-material/Search";
import SortDateIcon from "@mui/icons-material/Schedule";

import AlertForm from "../../components/AlertForm";
import Loading from "../../components/Loading";
import AlertAccordionItem from "../../containers/AlertAccordionItem";

import { Creators as AlertActions } from "../../store/ducks/alert";

import "./style.sass";

const Alerts = (props) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({ search: "" });
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState({ by: "date", order: "desc" });
  const [alertActionsLocalPage] = useState(1);
  const [monitoringAlertsLocalPage, setMonitoringAlertsLocalPage] = useState(1);
  const [expiredAlertsLocalPage, setExpiredAlertsLocalPage] = useState(1);

  const getMonitoringAlertsLoading = useSelector(
    (state) => state.alert.getMonitoringAlertsLoading
  );
  const monitoringAlerts = useSelector((state) => state.alert.monitoringList);
  const monitoringAlertsPage = useSelector(
    (state) => state.alert.monitoringPage
  );
  const monitoringAlertsLimit = useSelector(
    (state) => state.alert.monitoringLimit
  );
  const monitoringAlertsTotal = useSelector(
    (state) => state.alert.monitoringTotal
  );

  const getExpiredAlertsLoading = useSelector(
    (state) => state.alert.getExpiredAlertsLoading
  );
  const expiredAlerts = useSelector((state) => state.alert.expiredList);
  const expiredAlertsPage = useSelector((state) => state.alert.expiredPage);
  const expiredAlertsLimit = useSelector((state) => state.alert.expiredLimit);
  const expiredAlertsTotal = useSelector((state) => state.alert.expiredTotal);

  const openAlertForm = useSelector((state) => state.alert.openAlertForm);

  const inputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSearch = () => {
    setSearch(formData.search);
  };

  const reorder = (by) => {
    if (by === order.by)
      setOrder({ by, order: order.order === "asc" ? "desc" : "asc" });
    else setOrder({ by, order: "asc" });
  };

  useEffect(() => {
    dispatch(
      AlertActions.getAlertActions({
        search,
        order,
        page: alertActionsLocalPage,
      })
    );
  }, [dispatch, order, search, alertActionsLocalPage]);

  useEffect(() => {
    dispatch(
      AlertActions.getMonitoringAlerts({
        search,
        order,
        page: monitoringAlertsLocalPage,
      })
    );
  }, [dispatch, order, search, monitoringAlertsLocalPage]);

  useEffect(() => {
    dispatch(
      AlertActions.getExpiredAlerts({
        search,
        order,
        page: expiredAlertsLocalPage,
      })
    );
  }, [dispatch, order, search, expiredAlertsLocalPage]);

  return (
    <div className="alerts">
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item xs={12} className="filter-bar">
          <FormControl
            className="search"
            size="small"
            variant="outlined"
            onChange={inputChange}
          >
            <InputLabel>Buscar</InputLabel>
            <OutlinedInput
              name="search"
              onKeyUp={(event) => (event.keyCode === 13 ? onSearch() : false)}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    onClick={() => onSearch()}
                    edge="end"
                    // color="primary"
                    size="small"
                    variant="contained"
                  >
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            className="button"
            variant={order.by === "title" ? "contained" : "outlined"}
            // sx={{ color: order.by === "title" ? "#fff" : "primary" }}
            onClick={() => reorder("title")}
          >
            {/* <SortAlphaIcon/> */}
            {(order.by !== "title" ||
              (order.by === "title" && order.order === "asc")) &&
              "de A a Z"}
            {order.by === "title" && order.order === "desc" && "de Z a A"}
          </Button>
          <Button
            className="button"
            variant={order.by === "date" ? "contained" : "outlined"}
            // sx={{ color: order.by === "date" ? "#fff" : "primary" }}
            onClick={() => reorder("date")}
          >
            <SortDateIcon />
            {(order.by !== "date" ||
              (order.by === "date" && order.order === "asc")) &&
              "Mais antigos primeiro"}
            {order.by === "date" &&
              order.order === "desc" &&
              "Mais recentes primeiro"}
          </Button>
        </Grid>
        <Grid item xs={12} className="accordion-block">
          <div className="title">
            <Typography variant="h6">Monitorando</Typography>
            <Button
              className="button"
              size="small"
              variant="outlined"
              onClick={() => dispatch(AlertActions.openAlertForm())}
            >
              <AddAlertIcon fontSize="small" />
              Novo alerta
            </Button>
          </div>
          {getMonitoringAlertsLoading && <Loading />}
          {monitoringAlerts.length === 0 && (
            <Alert icon={false} severity="warning" className="empty">
              Nenhum alerta monitorado
            </Alert>
          )}
          {monitoringAlerts.map((alert) => (
            <AlertAccordionItem data={alert} />
          ))}
          <div className="pagination-wrapper">
            <Pagination
              color={"primary"}
              size="small"
              count={Math.ceil(monitoringAlertsTotal / monitoringAlertsLimit)}
              siblingCount={3}
              showFirstButton={true}
              showLastButton={true}
              page={monitoringAlertsPage}
              onChange={(event, page) => {
                setMonitoringAlertsLocalPage(page);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} className="accordion-block">
          <div className="title">
            <Typography variant="h6">Expirados</Typography>
          </div>
          {getExpiredAlertsLoading && <Loading />}
          {expiredAlerts.length === 0 && (
            <Alert icon={false} severity="warning" className="empty">
              Nenhum alerta expirado
            </Alert>
          )}
          {expiredAlerts.map((alert) => (
            <AlertAccordionItem data={alert} />
          ))}
          <div className="pagination-wrapper">
            <Pagination
              color={"primary"}
              size="small"
              count={Math.ceil(expiredAlertsTotal / expiredAlertsLimit)}
              siblingCount={3}
              showFirstButton={true}
              showLastButton={true}
              page={expiredAlertsPage}
              onChange={(event, page) => {
                setExpiredAlertsLocalPage(page);
              }}
            />
          </div>
        </Grid>
      </Grid>
      {openAlertForm && <AlertForm />}
    </div>
  );
};
export default Alerts;
