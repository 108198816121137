import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { ptBR as xptBR } from "@mui/x-data-grid";
import { ptBR } from "@mui/material/locale";

export const Colors = {
  primary: "#3B8FE4",
  secondary: "#9CF1C7",
  warning: "#E4913B",
  alert: "#E23318",
  text: "#444",
  bgLigth: "#f3f3f3",
  green: "#3BE490",
  orange: "#E4913B",
  dark: "#444444",
};

export const AppTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: Colors.primary,
      },
      secondary: {
        main: Colors.secondary,
      },
      green: {
        main: Colors.green,
      },
      orange: {
        main: Colors.orange,
      },
    },

    overrides: {
      MuiTypography: {
        root: {
          color: Colors.text,
        },
      },
      MuiLinearProgress: {
        root: {
          borderRadius: 100,
        },
        bar: {
          borderRadius: 100,
        },
        colorPrimary: {
          backgroundColor: Colors.secondary,
        },
        barColorPrimary: {
          backgroundColor: Colors.warning,
        },
        colorSecondary: {
          backgroundColor: Colors.secondary,
        },
        barColorSecondary: {
          backgroundColor: Colors.alert,
        },
      },

      MuiButton: {
        root: {
          textTransform: "none",
          // color: Colors.primary,
          borderRadius: 100,
        },
        // button: {
        //   textTransform: 'none'
        // },
        outlined: {
          paddingTop: 7,
          paddingBottom: 7,
          borderColor: Colors.primary,
        },
      },

      MuiFab: {
        root: {
          // color: Colors.primary,
          backgroundColor: Colors.bgLigth,
        },
      },

      MuiFormLabel: {
        root: {
          backgroundColor: "#fff",
        },
      },

      MuiBadge: {
        // badge: {
        //   fontSize: '0.5rem',
        //   height: 16,
        //   width: 16,
        //   minWidth: 16,
        // },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: Colors.primary,
        },
      },
      MuiSelect: {
        root: {
          // paddingTop: 10,
          // paddingBottom: 10,
        },
      },

      MuiTab: {
        root: {
          textTransform: "none",
        },
      },

      MuiTooltip: {
        tooltip: {
          backgroundColor: Colors.dark,
        },
      },

      // PrivateTabIndicator: {
      //   colorPrimary: {
      //     backgroundColor: Colors.royal + ' !important',
      //   },
      // }
    },
    ptBR,
    xptBR,
  })
);

export const DialogTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiFormHelperText: {
        root: {
          color: "#f44336",
        },
      },
    },

    typography: {
      useNextVariants: true,
    },
  })
);
