import { useDispatch, useSelector } from "react-redux";
import { Recommendation } from "./types";
import { RootState } from "../../store";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Link,
  Chip,
} from "@mui/material";
import {
  Close,
  EventAvailable,
  Groups,
  MenuBook,
  OpenInNew,
  PendingActions,
  Percent,
  Person,
  RadioButtonChecked,
  Recommend,
  School,
  Send,
  Summarize,
  Timelapse,
  Timeline,
  Warning,
} from "@mui/icons-material";
import moment from "moment";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { Creators as RecommendationActions } from "../../store/ducks/recommendation";
import { Creators as AppActions } from "../../store/ducks/app";
import api from "../../store/Api";
import StudentsGradeClass from "./StudentsGradeClass";
import StudentsAccessTimeClass from "./StudentsAccessTimeClass";
import TutorAccessTimeClassVSHistorical from "./TutorAccessTimeClassVSHistorical";
import AverageGradeClassVSHistorical from "./AverageGradeClassVSHistorical";
import WorkloadCourse from "./WorkloadCourse";
import OAAttemptsInCourse from "./OAAttemptsInCourse";

import "./styles.sass";
import ClassAbandon from "./ClassAbandon";
import ClassProcrastination from "./ClassProcrastination";
import ClassBetterPerformance from "./ClassBetterPerformance";
import OALeastAccessed from "./OALeastAccessed";
import OALeastGraded from "./OALeastGraded";

export default function RecommendationDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openSend, setOpenSend] = useState(false);
  const selectedRecommendation = useSelector<any, Recommendation>(
    (state) => state.recommendation.selectedRecommendation
  );

  useEffect(() => {
    dispatch(AppActions.setBackRecommendation(false));
  }, []);

  // const [recommendersHistory, recommendersHistoryResult] =
  //   useGetRecommendersHistoryMutation();

  const handleClose = () => {
    dispatch(RecommendationActions.selectRecommendation({} as Recommendation));
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Informe um email válido")
      .required("Informe seu email para a confirmação"),
  });

  const form = useFormik({
    initialValues: {
      email: "",
      message: "",
      whatsapp: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        enqueueSnackbar("Enviando email!", {
          variant: "info",
          key: "sending",
        });
        const sendRecommendationResult = await api.sendRecommenderByEmail({
          recommenderId: selectedRecommendation?._id,
          email: values.email,
          message: values.message || "",
        });
        if (sendRecommendationResult.status >= 400)
          throw sendRecommendationResult.data;
        closeSnackbar("sending");
        enqueueSnackbar("Enviado com sucesso!", { variant: "success" });
        handleCloseSend();
      } catch (error) {
        enqueueSnackbar("Erro ao enviar!", { variant: "error" });
        console.log(error);
      }
    },
  });

  const handleCloseSend = () => {
    form.resetForm();
    setOpenSend(false);
  };

  const handleOpenSend = () => {
    setOpenSend(true);
  };

  const renderByType = () => {
    if (selectedRecommendation)
      switch (selectedRecommendation.type) {
        case "Desempenho dos alunos no curso":
          return (
            <StudentsGradeClass
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Assiduidade dos alunos":
          return (
            <StudentsAccessTimeClass
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Assiduidade do tutor":
          return (
            <TutorAccessTimeClassVSHistorical
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );

        case "Análise histórica da média da turma":
          return (
            <AverageGradeClassVSHistorical
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Reavaliação da carga horária do curso":
          return (
            <WorkloadCourse
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Média de tentativas em atividades avaliativas no curso":
          return (
            <OAAttemptsInCourse
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Índice de abandono":
          return (
            <ClassAbandon
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Índice de procrastinação":
          return (
            <ClassProcrastination
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Tutores com melhores resultados":
          return (
            <ClassBetterPerformance
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Objetos de aprendizagem menos acessados do curso":
          return (
            <OALeastAccessed
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
        case "Atividades avaliativas com menores notas do curso":
          return (
            <OALeastGraded
              recommendation={selectedRecommendation}
              filterBatch={filterBatch}
            />
          );
      }
  };

  const filterBatch = () => {
    dispatch(AppActions.setSelectedBatch(selectedRecommendation.batch));
    history.push("/data");
  };

  return <>
    <Dialog fullScreen open={!!selectedRecommendation?.type}>
      <AppBar sx={{ position: "sticky", borderRadius: 0 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large">
            <Close />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1, color: "#FFF" }}
            variant="h5"
            component="div"
          >
            {selectedRecommendation?.type || "Detalhe da Recomendação"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} sx={{ p: 3 }} id={"details-content"}>
        <Grid item xs={12}>
          {/* <Chip
            color="primary"
            icon={<Send />}
            onClick={handleOpenSend}
            label={"Encaminhar"}
            sx={{ p: 1 }}
          /> */}
        </Grid>
        {renderByType()}
      </Grid>
    </Dialog>
    <Dialog open={openSend} maxWidth={"md"} fullWidth>
      <Grid
        container
        spacing={2}
        p={2}
        component={"form"}
        onSubmit={form.handleSubmit}
      >
        <Grid item xs={12}>
          <Stack sx={{ alignItems: "center" }} direction={"row"} spacing={1}>
            <Send />
            <Typography sx={{ flexGrow: 1 }}>Encaminhar</Typography>
            <IconButton onClick={handleCloseSend} size="large">
              <Close />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h6"}>
            Tutor: {selectedRecommendation?.tutor?.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid
                item
                xs={6}
                sx={{ borderRight: 0.5, borderColor: "#333333", pr: 1 }}
              >
                <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                  <Recommend />
                  <Typography>Recomendação</Typography>
                </Stack>
                <Typography>{selectedRecommendation?.type}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ borderRight: 0.5, borderColor: "#333333", pr: 1 }}
              >
                <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                  <MenuBook />
                  <Typography>Curso</Typography>
                </Stack>
                <Typography>
                  {selectedRecommendation?.course?.name}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                  <Groups />
                  <Typography>Turma</Typography>
                </Stack>
                <Typography>{selectedRecommendation?.class?.name}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            // hiddenLabel
            label="Email"
            placeholder="Insira o email que deseja encaminhar"
            size="small"
            fullWidth
            value={form.values.email}
            onChange={form.handleChange}
            error={form.touched.email && Boolean(form.errors.email)}
            // helperText={
            //   "email usado por ultimo, ao enviar notificação salvar esse email para sugestão"
            // }
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            name="whatsapp"
            hiddenLabel
            placeholder="Insira o numero de whatsapp que deseja encaminhar"
            size="small"
            fullWidth
            value={form.values.whatsapp}
            onChange={form.handleChange}
            error={form.touched.whatsapp && Boolean(form.errors.whatsapp)}
            // helperText={
            //   "email usado por ultimo, ao enviar notificação salvar esse email para sugestão"
            // }
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            name="message"
            // hiddenLabel
            label="Mensagem"
            placeholder="Pode enviar uma mensagem junto aos dados"
            size="small"
            fullWidth
            multiline
            value={form.values.message}
            onChange={form.handleChange}
            error={form.touched.message && Boolean(form.errors.message)}
            // helperText={"email usado por ultimo, ao enviar notificação salvar esse email para sugestão"}
          />
        </Grid>
        <Grid item xs={7}></Grid>
        <Grid item xs={5}>
          <Button variant="contained" type="submit" fullWidth>
            Enviar mensagem
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  </>;
}
