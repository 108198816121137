import React from "react";
import { CircularProgress } from "@mui/material";

import "./style.sass";

const Loading = (props) => {
  return (
    <div className={`loading ${props.className}`}>
      <CircularProgress size={props.small ? 25 : 40} />
    </div>
  );
};

Loading.defaultProps = {
  className: "",
  small: false,
};

export default Loading;
