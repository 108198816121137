import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/batch";

function* getBatchs(action) {
  try {
    const response = yield call(Api.getBatchs);
    yield put({ type: Types.GET_BATCHS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_BATCHS_FAILURE, payload: error.message });
  }
}

function* getBatchInfo(action) {
  try {
    const response = yield call(Api.getBatchInfo, action.payload);
    yield put({ type: Types.GET_BATCH_INFO_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_BATCH_INFO_FAILURE, payload: error.message });
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_BATCHS, getBatchs),
    takeLatest(Types.GET_BATCH_INFO, getBatchInfo),
  ]);
}
