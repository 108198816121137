import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/indicators";

function* getIndicators(action) {
  try {
    const response = yield call(Api.getIndicators, action.payload);
    yield put({ type: Types.GET_INDICATORS_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_INDICATORS_FAILURE,
      errorMessage: error.message,
    });
  }
}

export default function* () {
  yield all([takeLatest(Types.GET_INDICATORS, getIndicators)]);
}
