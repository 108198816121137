import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import Store from "./store";

import "@aws-amplify/ui-react/styles.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  Authenticator,
  Button,
  Heading,
  Image,
  Text,
  View,
  translations,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
// import awsExports from "./aws-exports";

I18n.putVocabularies(translations);
I18n.setLanguage("pt");

I18n.putVocabularies({
  pt: {
    "Reset Password": "Redefinir Senha",
    "Enter your Email": "Digite seu Email",
    "Enter your Password": "Digite sua Senha",
  },
});
Amplify.configure({
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_AUTH_REGION,
      userPoolId:
        process.env.REACT_APP_AUTH_USER_POOL_ID,
      userPoolClientId:

        process.env.REACT_APP_AUTH_USER_POOL_APP_CLIENT_ID,
    },
  },
});

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Assis logo" src={`./logo.svg`} />
        <Text
          style={{
            color: "#FFF",
            fontSize: "20px",
            fontWeight: 500,
            marginTop: "10px",
          }}
        >
          Faça login em sua conta
        </Text>
      </View>
    );
  },

  // ConfirmResetPassword: {
  //   Header() {
  //     const { tokens } = useTheme();
  //     return (
  //       <Heading
  //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
  //         level={3}
  //       >
  //         Enter Information:
  //       </Heading>
  //     );
  //   },
  //   Footer() {
  //     return <Text>Footer Information</Text>;
  //   },
  // },
};

const formFields = {
  signIn: {
    username: {
      label: "Endereço de Email",
      placeholder: "Digite seu email",
    },
    password: {
      label: "Senha",
      placeholder: "Digite sua senha",
    },
  },
  forgotPassword: {
    username: {
      label: "Email",
      placeholder: "Digite seu email",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Código de confirmação",
      label: "Código",
    },
    password: {
      placeholder: "Nova Senha",
      label: "Digite sua senha",
    },
  },
};

ReactDOM.render(
  <Authenticator
    loginMechanisms={["email"]}
    hideSignUp
    components={components}
    formFields={formFields}
  >
    <Provider store={Store}>
      <App />
    </Provider>
  </Authenticator>,
  document.getElementById("App")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
