import {
  FiberManualRecord,
  InfoOutlined,
  OpenInNew,
  SpeakerNotes,
  Summarize,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { Recommendation } from "./types";

type Props = {
  filterBatch: () => void;
  recommendation: Recommendation;
};

const ClassProcrastination = ({ filterBatch, recommendation }: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>
              {`${((recommendation.problemPercent || 0) * 100).toFixed(
                2
              )}% das turmas vigentes possuem alto índice de procrastinação.`}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <SpeakerNotes />
            <Typography>Sugerimos que realize as seguintes ações</Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Coletar os feedbacks individuais dos alunos sobre o andamento das
              turmas;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Coletar opiniões a respeito do cronograma das turmas;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Verificar a necessidade de replanejamento da data final das
              turmas;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Analisar a necessidade de melhor distribuição atividades
              propostas, para facilitar a execução da tarefa no prazo proposto.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Summarize />
                <Typography>
                  <strong>Análise que disparou a recomendação:</strong>
                </Typography>
                <Link onClick={filterBatch} sx={{ cursor: "pointer" }}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {moment(recommendation?.date)
                        .add(3, "hours")
                        .format("DD/MM/YYYY")}
                    </Typography>
                    <OpenInNew />
                  </Stack>
                  <Typography></Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={recommendation.list?.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "course",
              headerName: "Curso",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.course?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.course?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Curso</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "class",
              headerName: "Turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.class?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.class?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "conclusion",
              headerName: "Progresso da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    `${(params.row?.conclusion * 100).toFixed(
                      2
                    )}% de conclusão` || " - "
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${(params.row?.conclusion * 100).toFixed(
                      2
                    )}% de conclusão` || " - "}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Progresso da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "startDate",
              headerName: "Data de início da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    (params.row?.startDate &&
                      moment(params.row?.startDate).format("DD/MM/YYYY")) ||
                    ""
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.startDate &&
                      moment(params.row?.startDate).format("DD/MM/YYYY")) ||
                      ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Data de início da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "endDate",
              headerName: "Data de término da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    (params.row?.endDate &&
                      moment(params.row?.endDate).format("DD/MM/YYYY")) ||
                    ""
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.endDate &&
                      moment(params.row?.endDate).format("DD/MM/YYYY")) ||
                      ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Data de término da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "procrastination",
              headerName: "Índice de procrastinação da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={`${(params.row?.procrastination * 100).toFixed(2)}%`}
                >
                  <Typography>{`${(params.row?.procrastination * 100).toFixed(
                    2
                  )}%`}</Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Índice de procrastinação da turma</strong>
                  </Typography>
                  <Tooltip
                    title={
                      "Alunos que realizam as atividades obrigatórias no limite dos prazos."
                    }
                  >
                    <InfoOutlined />
                  </Tooltip>
                </Stack>
              ),
            },
            {
              field: "averageGrade",
              headerName: "Média da turma",
              flex: 1,
              minWidth: 100,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.averageGrade || ""}`}>
                  <Typography>{`${params.row?.averageGrade}`}</Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média da turma</strong>
                  </Typography>
                  {/* <Tooltip
                    title={
                      "Tempo médio comparativo das turmas anteriores do curso."
                    }
                  >
                    <InfoOutlined />
                  </Tooltip> */}
                </Stack>
              ),
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5]}
        />
      </Grid>
    </>
  );
};

export default ClassProcrastination;
