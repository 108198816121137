import React, { useState, memo, useEffect } from "react";

import {
  DateRangePicker as DefaultDateRangePicker,
  Range,
  RangeKeyDict,
  StaticRange,
  createStaticRanges,
} from "react-date-range";
import locale from "date-fns/locale/pt-BR";
import {
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  format,
  isWithinInterval,
} from "date-fns";
import "./style.sass";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Button,
  ButtonBase,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, RestartAlt } from "@mui/icons-material";
import { Creators as BatchActions } from "../../store/ducks/batch";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  onChange: (range: Range) => void;
};

const DateRangePicker = (props: Props) => {
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [state, setState] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
    key: "selection",
  });

  const batchs: [{ _id: string; startAt: string; endAt: string }] = useSelector(
    (state: any) => state.batch.list
  );

  useEffect(() => {
    dispatch(BatchActions.getBatchs());
  }, [dispatch]);

  useEffect(() => {
    const range: Range = {
      startDate: state.startDate ? startOfDay(state.startDate) : undefined,
      endDate: state.endDate ? endOfDay(state.endDate) : undefined,
      key: "selection",
    };
    props.onChange(range);
  }, [state]);

  const handleDateCLick = (startAt: string) => {
    if (state.startDate) {
      if (state.endDate) {
        setState({
          startDate: new Date(startAt),
          endDate: undefined,
          key: "selection",
        });
      } else {
        if (new Date(startAt) > state.startDate) {
          setState({
            startDate: state.startDate,
            endDate: new Date(startAt),
            key: "selection",
          });
        } else {
          setState({
            startDate: new Date(startAt),
            endDate: state.startDate,
            key: "selection",
          });
        }
      }
    } else {
      setState({
        startDate: new Date(startAt),
        endDate: undefined,
        key: "selection",
      });
    }
  };

  return (
    <Grid container spacing={1} className="date-range-picker">
      <Grid item xs={8}>
        <Paper sx={{ height: "100%" }}>
          <Stack
            spacing={0}
            direction={"row"}
            justifyContent={"flex-end"}
            sx={{ p: 2 }}
          >
            <Typography
              variant={"h5"}
              sx={{ fontWeight: 600, alignSelf: "center" }}
            >
              {selectedYear}
            </Typography>
            <IconButton onClick={() => setSelectedYear(selectedYear - 1)} size="large">
              <ArrowBackIos />
            </IconButton>
            <IconButton onClick={() => setSelectedYear(selectedYear + 1)} size="large">
              <ArrowForwardIos />
            </IconButton>
          </Stack>
          <Grid container paddingX={2} className={"calendar-dates"}>
            {state &&
              batchs
                ?.filter((item) => {
                  return new Date(item.startAt).getFullYear() === selectedYear;
                })
                .sort((a, b) => {
                  return (
                    new Date(a.startAt).getTime() -
                    new Date(b.startAt).getTime()
                  );
                })
                .map((item) => (
                  <Grid item xs={3}>
                    <ButtonBase
                      onClick={() => handleDateCLick(item.startAt)}
                      className={`${
                        (isWithinInterval(new Date(item.startAt), {
                          start: state.startDate || new Date("1999-01-01"),
                          end: state.endDate || new Date("2999-01-01"),
                        }) &&
                          "selected") ||
                        ""
                      } ${
                        (isWithinInterval(new Date(item.startAt), {
                          start: state.startDate
                            ? startOfDay(state.startDate)
                            : new Date(),
                          end: state.startDate
                            ? endOfDay(state.startDate)
                            : new Date(),
                        }) &&
                          "first-selected") ||
                        ""
                      } ${
                        (isWithinInterval(new Date(item.startAt), {
                          start: state.endDate
                            ? startOfDay(state.endDate)
                            : new Date(),
                          end: state.endDate
                            ? endOfDay(state.endDate)
                            : new Date(),
                        }) &&
                          "last-selected") ||
                        ""
                      }`}
                    >
                      <Typography textAlign={"center"}>
                        {format(new Date(item.startAt), "dd/MM")}
                      </Typography>
                    </ButtonBase>
                  </Grid>
                ))}
          </Grid>
          {/* <Grid item xs={12} spacing={2}>
              <Stack spacing={2} justifyContent={"flex-end"} direction={"row"}>
                <Button>Cancelar</Button>
                <Button variant="contained" sx={{ borderRadius: "32px" }}>
                  Aplicar
                </Button>
              </Stack>
            </Grid> */}
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Typography
            variant={"h5"}
            sx={{ fontWeight: 600, alignSelf: "center" }}
          >
            Períodos
          </Typography>
          {staticRanges.map((item) => (
            <Stack direction={"row"} spacing={0} alignItems={"center"}>
              <ButtonBase
                onClick={() =>
                  !item.isSelected(state)
                    ? setState(item.range())
                    : setState({ startDate: undefined, endDate: undefined })
                }
              >
                <Checkbox checked={item.isSelected(state)} />
                <Typography textAlign={"center"}>{item.label}</Typography>
              </ButtonBase>
            </Stack>
          ))}
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ color: "#3B8FE4" }}
          >
            <ButtonBase
              onClick={() =>
                setState({ startDate: undefined, endDate: undefined })
              }
            >
              <RestartAlt sx={{ mr: 1 }} />
              <Typography textAlign={"center"} color={"inherit"}>
                Limpar filtro de data
              </Typography>
            </ButtonBase>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const staticRanges: StaticRange[] = createStaticRanges([
  // {
  //   label: "Limpar Filtro",
  //   range: (): Range => ({ startDate: undefined, endDate: undefined }),
  //   isSelected: (range: Range) => !range.startDate && !range.endDate,
  // },
  // {
  //   label: "Hoje",
  //   range: () => ({
  //     startDate: defineds.startOfToday,
  //     endDate: defineds.endOfToday,
  //   }),
  //   isSelected: (range: Range) =>
  //     range.startDate?.getTime() === defineds.startOfToday.getTime() &&
  //     range.endDate?.getTime() === defineds.endOfToday.getTime(),
  // },
  // {
  //   label: "Essa Semana",
  //   range: () => ({
  //     startDate: defineds.startOfWeek,
  //     endDate: defineds.endOfWeek,
  //   }),
  //   isSelected: (range: Range) =>
  //     range.startDate?.getTime() === defineds.startOfWeek.getTime() &&
  //     range.endDate?.getTime() === defineds.endOfWeek.getTime(),
  // },
  {
    label: "Esse Mês",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
    isSelected: (range: Range) =>
      range.startDate?.getTime() === defineds.startOfMonth.getTime() &&
      range.endDate?.getTime() === defineds.endOfMonth.getTime(),
  },
  {
    label: "Mês Passado",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
    isSelected: (range: Range) =>
      range.startDate?.getTime() === defineds.startOfLastMonth.getTime() &&
      range.endDate?.getTime() === defineds.endOfLastMonth.getTime(),
  },
  {
    label: "Ano Passado",
    range: () => ({
      startDate: defineds.startOfPastYear,
      endDate: defineds.endOfPastYear,
    }),
    isSelected: (range: Range) =>
      range.startDate?.getTime() === defineds.startOfPastYear.getTime() &&
      range.endDate?.getTime() === defineds.endOfPastYear.getTime(),
  },
  // {
  //   label: "Últimos 12 meses",
  //   range: () => ({
  //     startDate: defineds.startOfLast12Months,
  //     endDate: defineds.endOfToday,
  //   }),
  //   isSelected: (range: Range) =>
  //     range.startDate?.getTime() === defineds.startOfLast12Months.getTime() &&
  //     range.endDate?.getTime() === defineds.endOfToday.getTime(),
  // },
  // {
  //   label: "Este ano",
  //   range: () => ({
  //     startDate: defineds.startOfYear,
  //     endDate: defineds.endOfToday,
  //   }),
  //   isSelected: (range: Range) =>
  //     range.startDate?.getTime() === defineds.startOfYear.getTime() &&
  //     range.endDate?.getTime() === defineds.endOfToday.getTime(),
  // },
]);

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLast12Months: startOfDay(addMonths(new Date(), -12)),
  startOfPastYear: startOfYear(addMonths(new Date(), -12)),
  endOfPastYear: endOfYear(addMonths(new Date(), -12)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
};

export default memo(DateRangePicker);
