import {
  FiberManualRecord,
  OpenInNew,
  Person,
  RadioButtonChecked,
  Recommend,
  SpeakerNotes,
  Summarize,
  Timeline,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { Recommendation } from "./types";

type Props = {
  filterBatch: () => void;
  recommendation: Recommendation;
};

const TutorAccessTimeClassVSHistorical = ({
  filterBatch,
  recommendation,
}: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            {recommendation.list && recommendation.list.length > 1 ? (
              <Typography>
                Verificamos que os tutores a seguir estão dedicando pelo menos
                10% a menos de carga horária de acesso na plataforma se
                comparados com os demais tutores do curso, podendo impactar no
                desempenho da turma.
              </Typography>
            ) : (
              <Typography>
                Verificamos que o tutor a seguir está dedicando pelo menos 10% a
                menos de carga horária de acesso na plataforma se comparados com
                os demais tutores do curso, podendo impactar no desempenho da
                turma.
              </Typography>
            )}
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <SpeakerNotes />
            <Typography>
              Sugerimos incentivar a participação do tutor na turma através das
              seguintes ações:
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Interagir com mais frequência nos ambientes de conversação (fóruns
              e tira-dúvidas);
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>Avaliar e dar feedback em até 24 horas;</Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>Enviar mensagens de motivação aos alunos.</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Summarize />
                <Typography>
                  <strong>Análise que disparou a recomendação:</strong>
                </Typography>
                <Link onClick={filterBatch} sx={{ cursor: "pointer" }}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {moment(recommendation?.date)
                        .add(3, "hours")
                        .format("DD/MM/YYYY")}
                    </Typography>
                    <OpenInNew />
                  </Stack>
                  <Typography></Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={recommendation.list?.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "course",
              headerName: "Curso",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.course?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.course?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Curso</strong>
                </Typography>
              ),
            },
            {
              field: "class",
              headerName: "Turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.class?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.class?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Turma</strong>
                </Typography>
              ),
            },
            {
              field: "conclusion",
              headerName: "Progresso da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    `${(params.row?.conclusion * 100).toFixed(
                      2
                    )}% de conclusão` || " - "
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${(params.row?.conclusion * 100).toFixed(
                      2
                    )}% de conclusão` || " - "}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Progresso da turma</strong>
                </Typography>
              ),
            },
            {
              field: "startDate",
              headerName: "Data de início da turma",
              flex: 1.5,
              minWidth: 200,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={`${
                    params.row?.startDate
                      ? moment(params.row?.startDate).format("DD/MM/YYYY")
                      : ""
                  }`}
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.startDate &&
                      moment(params.row?.startDate).format("DD/MM/YYYY")) ||
                      ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Data de início da turma</strong>
                </Typography>
              ),
            },
            {
              field: "endDate",
              headerName: "Data de término da turma",
              flex: 1.5,
              minWidth: 200,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    (params.row?.endDate &&
                      moment(params.row?.endDate).format("DD/MM/YYYY")) ||
                    ""
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.endDate &&
                      moment(params.row?.endDate).format("DD/MM/YYYY")) ||
                      ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Data de término da turma</strong>
                </Typography>
              ),
            },
            {
              field: "tutor",
              headerName: "Tutor",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.tutor?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.tutor?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Tutor</strong>
                </Typography>
              ),
            },
            {
              field: "tutorMeanAccessTime",
              headerName: "Média de acesso do tutor na turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.tutorMeanAccessTime || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.tutorMeanAccessTime || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Média de acesso do tutor na turma</strong>
                </Typography>
              ),
            },
            {
              field: "otherTutorsMeanAccessTime",
              headerName: "Média de acesso dos demais tutores",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={`${params.row?.otherTutorsMeanAccessTime || ""}`}
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.otherTutorsMeanAccessTime || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Média de acesso dos demais tutores</strong>
                </Typography>
              ),
            },
            {
              field: "accessTimePercentualDiff",
              headerName: "≠ Média de Acesso",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={`${params.row?.accessTimePercentualDiff || ""}`}
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.accessTimePercentualDiff || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>≠ Média de Acesso</strong>
                </Typography>
              ),
            },
            {
              field: "averageAccessTime",
              headerName: "Assiduidade da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.averageAccessTime || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.averageAccessTime || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Assiduidade da turma</strong>
                </Typography>
              ),
            },
            {
              field: "classAverageGrade",
              headerName: "Média da turma",
              flex: 1,
              minWidth: 100,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.classAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.classAverageGrade}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Média da turma</strong>
                </Typography>
              ),
            },
            {
              field: "IAEvasion",
              headerName: "Potêncial de evasão",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.IAEvasion || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.IAEvasion || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Typography variant={"body1"}>
                  <strong>Potêncial de evasão</strong>
                </Typography>
              ),
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5]}
        />
      </Grid>
    </>
  );
};

export default TutorAccessTimeClassVSHistorical;
