import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Loading from "../../components/Loading";

import { Creators as UserActions } from "../../store/ducks/user";

import "./style.sass";

const SendAccessDialog = (props) => {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.auth.authenticatedUser);

  const showUsersNoAccessUsersLoading = useSelector(
    (state) => state.user.getUsersNoAccessLoading
  );
  const sendAccessLoading = useSelector(
    (state) => state.user.sendAccessLoading
  );
  const usersNoAccessList = useSelector(
    (state) => state.user.usersNoAccessList
  );

  const onClose = () => {
    props.onClose();
    // setFormData(formDataInitial)
  };

  const selectAll = () => {
    let inputs = document.querySelectorAll(".user-checkbox input");
    inputs.forEach((input) => {
      if (!input.checked) input.click();
    });
  };

  const unselectAll = () => {
    let inputs = document.querySelectorAll(".user-checkbox input");
    inputs.forEach((input) => {
      if (input.checked) input.click();
    });
  };

  const send = () => {
    let inputs = document.querySelectorAll(".user-checkbox input");
    let userIds = [];
    inputs.forEach((input) => {
      if (input.checked) userIds.push(input.value);
    });
    dispatch(UserActions.sendAccess({ userIds }));
  };

  return (
    <Dialog className="send-access-dialog" onClose={onClose} open={true}>
      <DialogTitle>
        Enviar Acesso
        <IconButton
          className="title-close-button"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="content">
        <div>
          <Button size="small" onClick={selectAll}>
            Marcar todos
          </Button>
          <Button size="small" onClick={unselectAll}>
            Desmarcar todos
          </Button>
        </div>

        {(showUsersNoAccessUsersLoading || sendAccessLoading) && <Loading />}
        {/* {successMsg && (<Alert severity="success">{successMsg}</Alert>)}
          {errorMsg && (<Alert severity="error">{errorMsg}</Alert>)} */}

        {usersNoAccessList &&
          usersNoAccessList.map((user) => (
            <FormControlLabel
              key={user._id}
              className="user-checkbox"
              control={<Checkbox color="primary" value={user._id} />}
              label={`${user.name} (${
                authenticated?.["cognito:groups"]?.includes("manager")
                  ? "Consultor"
                  : "Tutor"
              })`}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button autoFocus onClick={send}>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SendAccessDialog.defaultProps = {
  // data: {},
  onClose: () => {},
};

export default SendAccessDialog;
