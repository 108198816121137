import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Paper } from "@mui/material";
import IndicatorIcon from "@mui/icons-material/AssignmentInd";
import Loading from "../../components/Loading";
import IndicationBlock from "../../components/IndicationBlock";

import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as IndicationActions } from "../../store/ducks/indicators";

import "./style.sass";

const IndicatorsBlock = (props) => {
  const dispatch = useDispatch();

  const [selectedIndicator, setSelectedIndicator] = useState(-1);

  const indicators = useSelector((state) => state.indicators.list);
  const selectedBatch = useSelector((state) => state.app.selectedBatch);
  const selectedTutors = useSelector((state) => state.app.selectedTutors);
  const selectedCourses = useSelector((state) => state.app.selectedCourses);
  const selectedClasses = useSelector((state) => state.app.selectedClasses);
  const selectedStudents = useSelector((state) => state.app.selectedStudents);
  const selectedEnrollments = useSelector(
    (state) => state.app.selectedEnrollments
  );
  const finished = useSelector((state) => state.app.finished);
  const filters = useSelector((state) => state.app.customFilters);

  const onChangeStudents = (newValue) => {
    dispatch(AppActions.setSelectedEnrollments(newValue));
  };

  const getIndicatorsLoading = useSelector(
    (state) => state.indicators.getIndicatorsLoading
  );

  useEffect(() => {
    if (selectedBatch) {
      dispatch(
        IndicationActions.getIndicators({
          courseIds: selectedCourses.map((courses) => courses.courseId),
          classIds: selectedClasses.map(
            (selectedClass) => selectedClass.classId
          ),
          tutorIds: selectedTutors.map((tutors) => tutors.tutorId),
          studentIds: selectedStudents.map((student) => student.studentId),

          enrollmentsIds: selectedEnrollments,
          batchId: selectedBatch,
          finished,
          filters,
        })
      );
    }
  }, [
    dispatch,
    selectedCourses,
    selectedTutors,
    selectedClasses,
    selectedStudents,
    selectedEnrollments,
    selectedBatch,
    finished,
    filters,
  ]);

  return (
    <Paper className="block indicators-block">
      {getIndicatorsLoading && <Loading />}
      <Typography variant="h6" className="title">
        <IndicatorIcon />
        Indicadores
      </Typography>
      {indicators &&
        indicators.map((indicator, key) => (
          <IndicationBlock
            key={key}
            {...indicator}
            studentsClickAction={() => onChangeStudents(indicator.students)}
            open={selectedIndicator === key}
            onClick={() =>
              selectedIndicator !== key
                ? setSelectedIndicator(key)
                : setSelectedIndicator(-1)
            }
          />
        ))}
    </Paper>
  );
};

export default IndicatorsBlock;
