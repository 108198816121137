import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase, Typography, Paper } from "@mui/material";
import AnalysisIcon from "@mui/icons-material/AssessmentOutlined";
import FilterIcon from "@mui/icons-material/TuneOutlined";

import Loading from "../../components/Loading";

import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as BatchActions } from "../../store/ducks/batch";
import EmptyImg from "./imgs/empty.png";

import "./style.sass";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const EvasionChartBlock = (props) => {
  const dispatch = useDispatch();

  const [analisys, setAnalisys] = useState([]);
  const [evasorData, setEvasorData] = useState({});

  const selectedBatch = useSelector((state) => state.app.selectedBatch);
  const selectedTutors = useSelector((state) => state.app.selectedTutors);
  const selectedCourses = useSelector((state) => state.app.selectedCourses);
  const selectedClasses = useSelector((state) => state.app.selectedClasses);
  const selectedStudents = useSelector((state) => state.app.selectedStudents);
  const selectedEnrollments = useSelector(
    (state) => state.app.selectedEnrollments
  );
  const filters = useSelector((state) => state.app.customFilters);
  const finished = useSelector((state) => state.app.finished);

  const getBatchInfoLoading = useSelector(
    (state) => state.batch.getBatchInfoLoading
  );
  const batchInfo = useSelector((state) => state.batch.batchInfo);

  // useEffect(() => {
  //   if(batchs.length)
  //     dispatch(AppActions.setSelectedBatch(batchs[0]._id))
  // }, [batchs, dispatch])

  useEffect(() => {
    if (selectedBatch !== null)
      dispatch(
        BatchActions.getBatchInfo({
          courseIds: selectedCourses.map((courses) => courses.courseId),
          classIds: selectedClasses.map(
            (selectedClass) => selectedClass.classId
          ),
          tutorIds: selectedTutors.map((tutors) => tutors.tutorId),
          studentIds: selectedStudents.map((student) => student.studentId),

          enrollmentsIds: selectedEnrollments,
          batchId: selectedBatch,
          finished,
          filters,
        })
      );
  }, [
    selectedBatch,
    selectedTutors,
    selectedCourses,
    selectedClasses,
    selectedStudents,
    selectedEnrollments,
    filters,
    dispatch,
    finished,
  ]);

  useEffect(() => {
    if (batchInfo.analisys) {
      setAnalisys(batchInfo.analisys || []);
      setEvasorData(
        batchInfo.evarsorConcludents.find((item) => item.title === "Evasores")
      );
    }
  }, [batchInfo]);

  const setSelectStudent = (studentIds) => {
    dispatch(AppActions.setSelectedEnrollments(studentIds));
  };

  return (
    <div className="evasion-chart">
      {getBatchInfoLoading && <Loading />}
      <div className="title-header">
        <AnalysisIcon className="icon" />
        <Typography variant="h6">Análise comparativa</Typography>
        <Tooltip
          title={
            "Todas as informações da análise (grupos de risco, indicadores e métricas) são referentes ao número de matrículas."
          }
        >
          <InfoOutlined />
        </Tooltip>
      </div>
      {!batchInfo.studentCount && (
        <div className="empty-info">
          {batchInfo.studentCount === 0 && (
            <div className="msg">
              <FilterIcon className="filter-icon" />
              <Typography variant="h6">
                Não existem dados para essa consulta
              </Typography>
            </div>
          )}
          <img src={EmptyImg} alt="Empty Chart" />
        </div>
      )}
      {batchInfo.studentCount > 0 && (
        <div className="analysis-chart">
          <div className="analysis">
            <Typography variant="subtitle2">Total de matriculas:</Typography>
            <Typography variant="h4">{batchInfo.studentCount}</Typography>
            <ul>
              <li>
                <Typography variant="subtitle2">
                  Em relação à análise anterior:
                </Typography>
              </li>
              {analisys.map((analisysItem, key) => (
                <li key={key}>
                  <ButtonBase
                    onClick={() => {
                      setSelectStudent(analisysItem.studentIds);
                    }}
                    className="analysis-item-button"
                  >
                    <div
                      className="color-bar"
                      style={{ backgroundColor: analisysItem.color }}
                    ></div>
                    <div className="classifications">
                      <Typography variant="subtitle2" className="title">
                        {`${analisysItem.title} ( ${
                          analisysItem.qty
                        } / ${analisysItem.percent.toFixed(2)}% )`}
                      </Typography>
                      <Typography variant="subtitle2" className="value">
                        {analisysItem.lastDiff === 0 && `Permaneceu igual`}
                        {analisysItem.lastDiff > 0 &&
                          `Aumentou ${analisysItem.lastDiff.toFixed(2)}%`}
                        {analisysItem.lastDiff < 0 &&
                          `Diminuiu ${(analisysItem.lastDiff * -1).toFixed(
                            2
                          )}%`}
                      </Typography>
                    </div>
                    <Typography variant="caption" className="previous">
                      {`Valor anterior ( ${
                        analisysItem.previousQty
                      } / ${analisysItem.previousPercent.toFixed(2)}% )`}
                    </Typography>
                  </ButtonBase>
                </li>
              ))}
            </ul>
          </div>
          <div className="chart">
            <div className="bars">
              {analisys.map((analisysItem, key) => (
                <ButtonBase
                  key={key}
                  className="bar"
                  onClick={() => {
                    setSelectStudent(analisysItem.studentIds);
                  }}
                  style={{
                    backgroundColor: analisysItem.color,
                    height:
                      (analisysItem.qty /
                        (analisys.reduce((acc, curr) => acc + curr.qty, 0) ||
                          1)) *
                        100 +
                      "%",
                  }}
                ></ButtonBase>
              ))}
            </div>
            <div className="titles">
              {analisys?.map((analisysItem, key) => (
                <div key={key} className="title">
                  {analisysItem.title}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Paper elevation={2} className="numbers-wrapper">
        <ul className="numbers">
          {analisys.map((analisysItem, key) => (
            <li key={key}>
              <ButtonBase
                onClick={() => {
                  setSelectStudent(analisysItem.studentIds);
                }}
                className="numbers-item-button"
              >
                <div
                  className="color-bar"
                  style={{ backgroundColor: analisysItem.color }}
                ></div>
                <Typography variant="subtitle2" className="title">
                  {analisysItem.title}
                </Typography>
                <Typography variant="h6" className="value">
                  {analisysItem.qty}
                  {/* <Tooltip
                    title={analisysItem.description}
                    classes={{ tooltip: classes.tooltipWidth }}
                    placement="right"
                    arrow
                  >
                    <InfoIcon color="primary" className="info-icon" />
                  </Tooltip> */}
                </Typography>
              </ButtonBase>
            </li>
          ))}
        </ul>
        {evasorData && (
          <ButtonBase
            onClick={() => {
              setSelectStudent(evasorData.studentIds);
            }}
            id="evasor-data-button"
          >
            <div className="extra-numbers">
              <Typography variant="subtitle2" className="title">
                {evasorData.title}
              </Typography>
              <Typography variant="h6" className="value">
                {evasorData.qty}
                {/* <Tooltip
                title={evasorData.description || ""}
                classes={{ tooltip: classes.tooltipWidth }}
                placement="right"
                arrow
              >
                <InfoIcon color="primary" className="info-icon" />
              </Tooltip> */}
              </Typography>
            </div>
          </ButtonBase>
        )}
      </Paper>
    </div>
  );
};

EvasionChartBlock.defaultProps = {
  hideCalendar: false,
};

// EvasionChartBlock.prototype = {
//   hideCalendar: PropTypes.bool
// }

export default EvasionChartBlock;
