import {
  OpenInNew,
  Person,
  RadioButtonChecked,
  Recommend,
  SpeakerNotes,
  Summarize,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { Recommendation } from "./types";

type Props = {
  filterBatch: () => void;
  recommendation: Recommendation;
};

const OALeastGraded = ({ filterBatch, recommendation }: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>
              {`Há aulas de cursos vigentes em que as notas dos alunos é menor que 7. `}
              .
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <SpeakerNotes />
            <Typography>
              Sugerimos que analise o conteúdo didático da aula referida, bem
              como sua metodologia, para solucionar a queda das notas dos
              alunos, para evitar a desmotivação e evasão.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Summarize />
                <Typography>
                  <strong>Análise que disparou a recomendação:</strong>
                </Typography>
                <Link onClick={filterBatch} sx={{ cursor: "pointer" }}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {moment(recommendation?.date)
                        .add(3, "hours")
                        .format("DD/MM/YYYY")}
                    </Typography>
                    <OpenInNew />
                  </Stack>
                  <Typography></Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={recommendation.list?.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "course",
              headerName: "Curso",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.course?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.course?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Curso</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "class",
              headerName: "Turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.class?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.class?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "conclusion",
              headerName: "Progresso da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    `${(params.row?.conclusion * 100).toFixed(
                      2
                    )}% de conclusão` || " - "
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${(params.row?.conclusion * 100).toFixed(
                      2
                    )}% de conclusão` || " - "}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Progresso da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "startDate",
              headerName: "Data de início da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    (params.row?.startDate &&
                      moment(params.row?.startDate).format("DD/MM/YYYY")) ||
                    ""
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.startDate &&
                      moment(params.row?.startDate).format("DD/MM/YYYY")) ||
                      ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Data de início da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "endDate",
              headerName: "Data de término da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    (params.row?.endDate &&
                      moment(params.row?.endDate).format("DD/MM/YYYY")) ||
                    ""
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.endDate &&
                      moment(params.row?.endDate).format("DD/MM/YYYY")) ||
                      ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Data de término da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "tutor",
              headerName: "Tutor",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.tutor?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.tutor?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Tutor</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "oaName",
              headerName: "Atividade avaliativa",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.oaName || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.oaName || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Atividade avaliativa</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "oaAverageGrade",
              headerName: "Média de notas no OA",
              flex: 1,
              minWidth: 100,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={`${params.row?.oaAverageGrade.toFixed(2) || ""}`}
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.oaAverageGrade.toFixed(2)}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média de notas no OA</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "historicalAverageGrade",
              headerName: "Média histórica de notas ao OA",
              flex: 1,
              minWidth: 100,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.historicalAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.historicalAverageGrade}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média histórica de notas ao OA</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "classAverageGrade",
              headerName: "Média de nota da turma",
              flex: 1,
              minWidth: 100,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.classAverageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.classAverageGrade}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média de nota da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5]}
        />
      </Grid>
    </>
  );
};

export default OALeastGraded;
