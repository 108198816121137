import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getClients: ["payload"],
  getClientsSuccess: ["payload"],
  getClientsFailure: ["payload"],
});

const INITIAL_STATE = {
  clientsList: [],
  getClientsLoading: false,
};

const getClients = (state = INITIAL_STATE, action) => ({
  ...state,
  getClientsLoading: true,
});
const getClientsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getClientsLoading: false,
  clientsList: action.payload,
});
const getClientsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getAlertActionsLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_CLIENTS]: getClients,
  [Types.GET_CLIENTS_SUCCESS]: getClientsSuccess,
  [Types.GET_CLIENTS_FAILURE]: getClientsFailure,
});
