import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/class";

function* getClasses(action) {
  try {
    const response = yield call(Api.getClasses, action.payload);
    yield put({ type: Types.GET_CLASSES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_CLASSES_FAILURE, errorMessage: error.message });
  }
}

function* getClassesEvasion(action) {
  try {
    const response = yield call(Api.getClassesEvasion, action.payload);
    yield put({ type: Types.GET_CLASSES_EVASION_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_CLASSES_EVASION_FAILURE,
      payload: error.message,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_CLASSES, getClasses),
    takeLatest(Types.GET_CLASSES_EVASION, getClassesEvasion),
  ]);
}
