import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getCourses: ["payload"],
  getCoursesSuccess: ["payload"],
  getCoursesFailure: ["payload"],

  getCoursesEvasion: ["payload"],
  getCoursesEvasionSuccess: ["payload"],
  getCoursesEvasionFailure: ["payload"],
});

const INITIAL_STATE = {
  getCoursesEvasionLoading: false,
  getCoursesEvasionErrorMsg: null,
  evasionPage: 0,
  evasionLimit: 0,
  evasionTotal: 0,
  evasionList: [],

  getCoursesLoading: false,
  getAllCoursesErrorMsg: null,
  list: [],
};

const getCourses = (state = INITIAL_STATE, action) => ({
  ...state,
  getCoursesLoading: true,
});
const getCoursesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getCoursesLoading: false,
  getCoursesErrorMsg: INITIAL_STATE.getCoursesErrorMsg,
  list: action.payload,
});
const getCoursesFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getCoursesLoading: false,
  getCoursesErrorMsg: action.payload,
  list: INITIAL_STATE.list,
});

const getCoursesEvasion = (state = INITIAL_STATE, action) => ({
  ...state,
  getCoursesEvasionLoading: true,
});
const getCoursesEvasionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getCoursesEvasionLoading: false,
  getCoursesEvasionErrorMsg: INITIAL_STATE.getCoursesEvasionErrorMsg,
  evasionPage: action.payload.page,
  evasionLimit: action.payload.limit,
  evasionTotal: action.payload.total,
  evasionList: action.payload.list,
});
const getCoursesEvasionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getCoursesEvasionLoading: false,
  getCoursesEvasionErrorMsg: action.payload,
  evasionPage: INITIAL_STATE.page,
  evasionLimit: INITIAL_STATE.limit,
  evasionTotal: INITIAL_STATE.total,
  evasionList: INITIAL_STATE.list,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_COURSES]: getCourses,
  [Types.GET_COURSES_SUCCESS]: getCoursesSuccess,
  [Types.GET_COURSES_FAILURE]: getCoursesFailure,

  [Types.GET_COURSES_EVASION]: getCoursesEvasion,
  [Types.GET_COURSES_EVASION_SUCCESS]: getCoursesEvasionSuccess,
  [Types.GET_COURSES_EVASION_FAILURE]: getCoursesEvasionFailure,
});
