import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Avatar,
  Badge,
  // Grid,
  ButtonBase,
  // Button,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import DataIcon from "@mui/icons-material/InsertChart";
import UsersIcon from "@mui/icons-material/People";
import AlertsIcon from "@mui/icons-material/Warning";
import ReportIcon from "@mui/icons-material/Dvr";

import { ReactComponent as InsightIcon } from "./img/insights.svg";
import Logo from "./img/logo.png";
// import { Creators as ActionsAuth } from '../../store/ducks/auth'
import "./style.sass";
import { IntegrationInstructions, SpeakerNotes } from "@mui/icons-material";
import { signOut } from "aws-amplify/auth";

const Header = () => {
  // const dispatch = useDispatch()
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
  const [notificationsMenuAnchorEl, setNotificationsMenuAnchorEl] =
    React.useState(null);

  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  const openUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const openNotificationsMenu = (event) => {
    setNotificationsMenuAnchorEl(event.currentTarget);
  };

  const closeNotificationsMenu = () => {
    setNotificationsMenuAnchorEl(null);
  };

  return (
    <header id="header" className="header">
      <div className="header-content">
        <div className="logo-wrapper">
          <img src={Logo} alt="logo assis" className="logo" />
        </div>
        <div className="menu-wrapper">
          <ul>
            <li>
              <Link to="/data" className="button">
                <DataIcon className="icon" />
                <Typography variant="caption" className="text">
                  Dados
                </Typography>
              </Link>
            </li>
            {authenticatedUser?.["cognito:groups"]?.includes("manager") && (
              <li>
                <Link to="/users" className="button">
                  <UsersIcon className="icon" />
                  <Typography variant="caption" className="text">
                    Gestão
                  </Typography>
                </Link>
              </li>
            )}
            <li>
              <Link to="/recommendations" className="button">
                <SpeakerNotes className="icon" />
                <Typography variant="caption" className="text">
                  Recomendações
                </Typography>
              </Link>
            </li>
            {authenticatedUser?.["cognito:groups"]?.includes("consultant") && (
              <li>
                <Link to="/reports" className="button">
                  <ReportIcon className="icon" />
                  <Typography variant="caption" className="text">
                    Relatório
                  </Typography>
                </Link>
              </li>
            )}
            {authenticatedUser?.["cognito:groups"]?.includes("consultant") && (
              <li>
                <Link to="/consultant" className="button">
                  <ReportIcon className="icon" />
                  <Typography variant="caption" className="text">
                    Relatório Consultor
                  </Typography>
                </Link>
                {/* <ButtonBase>
                  </ButtonBase> */}
              </li>
            )}
            <li>
              <Link to="/alerts" className="button">
                <AlertsIcon className="icon" />
                <Typography variant="caption" className="text">
                  Alertas
                </Typography>
              </Link>
            </li>
            {authenticatedUser?.["cognito:groups"]?.includes("manager") && (
              <li>
                <Link to="/tool" className="button">
                  <IntegrationInstructions className="icon" />
                  <Typography variant="caption" className="text">
                    Integração
                  </Typography>
                </Link>
              </li>
            )}
            {/* <li>
              <ButtonBase className="button" onClick={openNotificationsMenu}>
                <Badge color="error" variant="dot" invisible={false}>
                  <NotificationsIcon className="icon" />
                </Badge>
              </ButtonBase>

              <NotificationsMenu
                anchorEl={notificationsMenuAnchorEl}
                onClose={closeNotificationsMenu}
              />
            </li> */}

            <li>
              <Tooltip
                title={`${authenticatedUser.given_name} ${authenticatedUser.family_name}`}
                placement="bottom"
              >
                <ButtonBase className="button" onClick={openUserMenu}>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={authenticatedUser.phone_number ? true : true}
                  >
                    <Avatar src={authenticatedUser.picture} />
                  </Badge>
                </ButtonBase>
              </Tooltip>
              <Menu
                anchorEl={userMenuAnchorEl}
                // keepMounted
                className="user-menu"
                open={Boolean(userMenuAnchorEl)}
                onClose={closeUserMenu}
              >
                <MenuItem onClick={() => (window.location.href = "/profile")}>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={authenticatedUser.phone_number ? true : true}
                  >
                    Perfil
                  </Badge>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "/batchlog";
                  }}
                >
                  Log de Remessas
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    window.localStorage.removeItem("token");
                    window.location.href = "/";
                    await signOut();
                  }}
                >
                  Sair
                </MenuItem>
              </Menu>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};
export default Header;
