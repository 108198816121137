import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

import Loading from "../../components/Loading";
import Utils from "../../Utils";

import { Creators as UserActions } from "../../store/ducks/user";

import "./style.sass";

const UserFormDialog = (props) => {
  const dispatch = useDispatch();

  const showLoading = useSelector((state) => state.user.newUserLoading);
  const errorMsg = useSelector((state) => state.user.newUserErrorMsg);
  const openNewUserForm = useSelector((state) => state.user.openNewUserForm);
  const authenticated = useSelector((state) => state.auth.authenticatedUser);

  const formDataInitial = {
    name: "",
    email: "",
    whatsapp: "",
    enabled: true,
  };

  const [formData, setFormData] = useState(formDataInitial);

  const inputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "whatsapp") value = Utils.phoneMask(value);
    if (name === "enabled") value = event.target.checked;

    setFormData({ ...formData, [name]: value });
  };

  const submitAction = (event) => {
    event.preventDefault();
    let result = Utils.validateForm(event.target);
    if (result !== true) {
      setFormData({});
      setFormData({ ...formData, ...result });
    } else {
      const dataToSend = {
        name: formData.name,
        email: formData.email,
        whatsapp: Utils.digitMask(formData.whatsapp),
        enabled: formData.enabled,
        company: authenticated["custom:company"],
      };
      dispatch(UserActions.newUser(dataToSend));
      setFormData(formDataInitial);
    }
  };

  const onClose = () => {
    props.onClose();
    setFormData(formDataInitial);
    dispatch(UserActions.clearMsgs());
    dispatch(UserActions.closeNewUserForm());
  };

  return (
    <Dialog
      className="user-form-dialog"
      onClose={onClose}
      open={openNewUserForm}
    >
      <DialogTitle id="customized-dialog-title">
        Cadastro de gestor
        <IconButton
          className="title-close-button"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {showLoading && <Loading />}
        {/* {successMsg && (<Alert severity="success">{successMsg}</Alert>)} */}
        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <form
          id="user-form"
          noValidate
          autoComplete="off"
          onSubmit={submitAction}
        >
          <TextField
            name="name"
            label="Nome"
            required
            margin="normal"
            size="small"
            fullWidth
            onChange={inputChange}
            value={formData.name}
            error={formData.nameHasError}
            helperText={formData.nameErrorText}
          />
          <TextField
            name="email"
            label="Email"
            required
            margin="normal"
            size="small"
            fullWidth
            type="email"
            onChange={inputChange}
            value={formData.email}
            error={formData.emailHasError}
            helperText={formData.emailErrorText}
          />
          <Alert severity="info">
            Uma senha será gerada e enviada para o email informado. É importante
            que após o primeiro acesso o usuário altere sua senha.
          </Alert>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="whatsapp"
                label="Whatsapp"
                margin="normal"
                size="small"
                fullWidth
                type="text"
                onChange={inputChange}
                value={formData.whatsapp}
                error={formData.whatsappHasError}
                helperText={formData.whatsappErrorText}
                inputProps={{
                  minLength: 15,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    color="primary"
                    checked={formData.enabled}
                    onChange={inputChange}
                    name="enabled"
                  />
                }
                label="Permitir acesso"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button form="user-form" onClick={onClose}>
          Cancelar
        </Button>
        <Button form="user-form" type="submit" autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserFormDialog.defaultProps = {
  // data: {},
  onClose: () => {},
};

export default UserFormDialog;
