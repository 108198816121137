import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getTutors: ["payload"],
  getTutorsSuccess: ["payload"],
  getTutorsFailure: ["payload"],

  getTutorsEvasion: ["payload"],
  getTutorsEvasionSuccess: ["payload"],
  getTutorsEvasionFailure: ["payload"],
});

const INITIAL_STATE = {
  getTutorsEvasionLoading: false,
  getTutorsEvasionErrorMsg: null,
  evasionPage: 0,
  evasionLimit: 0,
  evasionTotal: 0,
  evasionList: [],

  getTutorsLoading: false,
  getTutorsErrorMsg: null,
  list: [],
};

const getTutors = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorsLoading: true,
});
const getTutorsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorsLoading: false,
  getTutorsErrorMsg: INITIAL_STATE.getTutorsErrorMsg,
  list: action.payload,
});
const getTutorsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorsLoading: false,
  getTutorsErrorMsg: action.payload,
  list: INITIAL_STATE.list,
});

const getTutorsEvasion = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorsEvasionLoading: true,
});
const getTutorsEvasionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorsEvasionLoading: false,
  getTutorsEvasionErrorMsg: INITIAL_STATE.getTutorsEvasionErrorMsg,
  evasionPage: action.payload.page,
  evasionLimit: action.payload.limit,
  evasionTotal: action.payload.total,
  evasionList: action.payload.list,
});
const getTutorsEvasionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorsEvasionLoading: false,
  getTutorsEvasionErrorMsg: action.payload,
  evasionPage: INITIAL_STATE.page,
  evasionLimit: INITIAL_STATE.limit,
  evasionTotal: INITIAL_STATE.total,
  evasionList: INITIAL_STATE.list,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_TUTORS_EVASION]: getTutorsEvasion,
  [Types.GET_TUTORS_EVASION_SUCCESS]: getTutorsEvasionSuccess,
  [Types.GET_TUTORS_EVASION_FAILURE]: getTutorsEvasionFailure,

  [Types.GET_TUTORS]: getTutors,
  [Types.GET_TUTORS_SUCCESS]: getTutorsSuccess,
  [Types.GET_TUTORS_FAILURE]: getTutorsFailure,
});
