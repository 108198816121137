import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getFeedbackTipOrQuestion: ["payload"],
  getFeedbackTipOrQuestionSuccess: ["payload"],
  getFeedbackTipOrQuestionFailure: ["payload"],
  confirmFeedbackTipDelivered: ["payload"],
  confirmFeedbackTipDeliveredSuccess: ["payload"],
  confirmFeedbackTipDeliveredFailure: ["payload"],
  saveFeedbackAnswer: ["payload"],
  saveFeedbackAnswerSuccess: ["payload"],
  saveFeedbackAnswerFailure: ["payload"],
});

const INITIAL_STATE = {
  getTipOrQuestionLoading: false,
  tipOrQuestion: null,
  // getQuestionLoading: false,
  // question: null,
  saveFeedbackAnswerLoading: false,
  saveFeedbackAnswerSuccessMsg: null,
  saveFeedbackAnswerErrorMsg: null,
  confirmFeedbackTipDeliveredLoading: false,
};

const getFeedbackTipOrQuestion = (state = INITIAL_STATE, action) => ({
  ...state,
  getTipOrQuestionLoading: true,
});

const getFeedbackTipOrQuestionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getTipOrQuestionLoading: false,
  tipOrQuestion: action.payload,
});

const getFeedbackTipOrQuestionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getTipOrQuestionLoading: false,
  tipOrQuestion: INITIAL_STATE.tip,
});

const saveFeedbackAnswer = (state = INITIAL_STATE, action) => ({
  ...state,
  saveFeedbackAnswerLoading: true,
  saveFeedbackAnswerSuccessMsg: INITIAL_STATE.saveFeedbackAnswerSuccessMsg,
  saveFeedbackAnswerErrorMsg: INITIAL_STATE.saveFeedbackAnswerErrorMsg,
});

const saveFeedbackAnswerSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  saveFeedbackAnswerLoading: false,
  saveFeedbackAnswerSuccessMsg: action.payload,
  tipOrQuestion: INITIAL_STATE.question,
});

const saveFeedbackAnswerFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  saveFeedbackAnswerLoading: false,
  saveFeedbackAnswerErrorMsg: action.payload,
});

const confirmFeedbackTipDelivered = (state = INITIAL_STATE, action) => ({
  ...state,
  confirmFeedbackTipDeliveredLoading: true,
});

const confirmFeedbackTipDeliveredSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  confirmFeedbackTipDeliveredLoading: false,
  tipOrQuestion: INITIAL_STATE.tipOrQuestion,
});

const confirmFeedbackTipDeliveredFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  confirmFeedbackTipDeliveredLoading: false,
  tipOrQuestion: INITIAL_STATE.tipOrQuestion,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_FEEDBACK_TIP_OR_QUESTION]: getFeedbackTipOrQuestion,
  [Types.GET_FEEDBACK_TIP_OR_QUESTION_SUCCESS]: getFeedbackTipOrQuestionSuccess,
  [Types.GET_FEEDBACK_TIP_OR_QUESTION_FAILURE]: getFeedbackTipOrQuestionFailure,
  [Types.SAVE_FEEDBACK_ANSWER]: saveFeedbackAnswer,
  [Types.SAVE_FEEDBACK_ANSWER_SUCCESS]: saveFeedbackAnswerSuccess,
  [Types.SAVE_FEEDBACK_ANSWER_FAILURE]: saveFeedbackAnswerFailure,
  [Types.CONFIRM_FEEDBACK_TIP_DELIVERED]: confirmFeedbackTipDelivered,
  [Types.CONFIRM_FEEDBACK_TIP_DELIVERED_SUCCESS]:
    confirmFeedbackTipDeliveredSuccess,
  [Types.CONFIRM_FEEDBACK_TIP_DELIVERED_FAILURE]:
    confirmFeedbackTipDeliveredFailure,
});
