import React from "react";
import { useDispatch } from "react-redux";

import { ButtonBase, Grid, Typography, Paper } from "@mui/material";

import { Creators as AppActions } from "../../store/ducks/app";

import "./style.sass";

const MetricBlock = (props) => {
  const dispatch = useDispatch();

  const setSelectStudent = (students) => {
    dispatch(AppActions.setSelectedEnrollments(students));
  };

  return (
    <Grid component={Paper} container className={"metric-block block"}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className="title">
          {props.icon}
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" className="subtitle">
          {props.subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {props.classifications.map((classification, key) => (
          <ButtonBase
            key={key}
            onClick={() => {
              setSelectStudent(classification.students);
            }}
            className="classification-button"
          >
            <div
              className="color-bar"
              style={{ backgroundColor: classification.color }}
            ></div>
            <Typography variant="subtitle2" className="title">
              {classification.title}
            </Typography>
            <Typography variant="caption">
              Total de {classification.total} (
              {classification.percent.toFixed(2)}%) | Média de{" "}
              {classification.avg}
            </Typography>
          </ButtonBase>
        ))}
      </Grid>
    </Grid>
  );
};

MetricBlock.defaultProps = {
  className: "",
  title: "",
  subtitle: "",
  classifications: [],
};

export default MetricBlock;
