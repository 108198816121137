import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getStudents: ["payload"],
  getStudentsSuccess: ["payload"],
  getStudentsFailure: ["payload"],
  // getStudentsEvasion: ['payload'],
  // getStudentsEvasionSuccess: ['payload'],
  // getStudentsEvasionFailure: ['payload'],
});

const INITIAL_STATE = {
  getStudentsLoading: false,
  page: 0,
  limit: 0,
  list: [],
};

const getStudents = (state = INITIAL_STATE, action) => ({
  ...state,
  getStudentsLoading: true,
});
const getStudentsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getStudentsLoading: false,
  page: action.payload.page,
  limit: action.payload.limit,
  list: action.payload.list,
  total: action.payload.total,
});
const getStudentsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getStudentsLoading: false,
  page: INITIAL_STATE.page,
  limit: INITIAL_STATE.limit,
  list: INITIAL_STATE.list,
  total: INITIAL_STATE.total,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_STUDENTS]: getStudents,
  [Types.GET_STUDENTS_SUCCESS]: getStudentsSuccess,
  [Types.GET_STUDENTS_FAILURE]: getStudentsFailure,
});
