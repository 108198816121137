/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Chip,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/Tune";
import TutorFilterPopup from "../../containers/TutorFilterPopup";
import CourseFilterPopup from "../../containers/CourseFilterPopup";
import ClassFilterPopup from "../../containers/ClassFilterPopup";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import BatchList from "../BatchList";
import { Creators as AppActions } from "../../store/ducks/app";
import Utils from "../../Utils";

import "./style.sass";
import { Paper, Stack, Tooltip } from "@mui/material";
import {
  Event,
  InfoOutlined,
  ManageSearch,
  Troubleshoot,
} from "@mui/icons-material";

const FiltersBlock = (props) => {
  const dispatch = useDispatch();

  const finished = useSelector((state) => state.app.finished);
  const selectedStudents = useSelector((state) => state.app.selectedStudents);
  const selectedEnrollments = useSelector(
    (state) => state.app.selectedEnrollments
  );

  const panelSummaryContent = useMemo(() => (
    <Grid container>
      <Grid item xs={8} className="title">
        <FilterIcon className="icon" />
        <Typography variant="h6">Filtros</Typography>
      </Grid>
    </Grid>
  ));

  const setStatus = (event, value) => {
    dispatch(AppActions.changeFinished(value));
  };

  const panelDetailsContent = (
    <Stack spacing={1}>
      <Paper elevation={1}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} p={1}>
              <Event />
              <Typography>Data</Typography>
              <Tooltip
                title={
                  "Selecione a data da análise. Cada item dessa lista representa uma análise realizada pelo Assis nos dados da data informada."
                }
              >
                <InfoOutlined />
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <BatchList />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={1}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} p={1}>
              <Troubleshoot />
              <Typography>Status</Typography>
              <Tooltip
                title={
                  "Selecione a situação dos dados que deseja ver, escolha entre “Em andamento” e “Finalizado”"
                }
              >
                <InfoOutlined />
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <div className="status-wrapper">
              <ToggleButtonGroup
                value={finished}
                exclusive
                onChange={setStatus}
                size="small"
                className="status"
              >
                <ToggleButton value={false} className="button">
                  Em andamento
                </ToggleButton>
                <ToggleButton value={true} className="button">
                  Finalizado
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={1}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} p={1}>
              <ManageSearch />
              <Typography>Análise personalizada</Typography>
              <Tooltip
                title={
                  "Selecione, Tutor(es), Cursos(s), Turmas(s) ou Alunos(s) e obtenha uma análise personalizada cruzando essas informações."
                }
              >
                <InfoOutlined />
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Grid container className="filters-wrapper" spacing={1}>
              <Grid item xs={12} className="filters-item">
                <Typography variant="button" className="filter-label">
                  Tutores
                </Typography>
                <TutorFilterPopup buttonSize="small" />
              </Grid>
              {/* <Grid item xs={6} className="filters-item"></Grid> */}

              <Grid item xs={12} className="filters-item">
                <Typography variant="button" className="filter-label">
                  Cursos
                </Typography>
                <CourseFilterPopup buttonSize="small" />
              </Grid>
              {/* <Grid item xs={6} className="filters-item"></Grid> */}

              <Grid item xs={12} className="filters-item">
                <Typography variant="button" className="filter-label">
                  Turmas
                </Typography>
                <ClassFilterPopup buttonSize="small" />
              </Grid>
              {/* <Grid item xs={6} className="filters-item"></Grid> */}

              <Grid item xs={12} className="filters-item">
                <Typography variant="button" className="filter-label">
                  Matriculas
                </Typography>
                <div>
                  {selectedEnrollments.length ? (
                    <Chip
                      className="chip"
                      label={`${selectedEnrollments.length > 1 ? `${selectedEnrollments.length} Selecionada(s)` : "1 matricula selecionada"}`}
                      size="small"
                      color="primary"
                      width="100"
                      onDelete={() =>
                        dispatch(AppActions.setSelectedEnrollments([]))
                      }
                    />
                  ) : (
                    <Chip
                      className="chip"
                      label="Nenhuma matricula selecionada"
                      size="small"
                      variant="outlined"
                      color="primary"
                    />

                  )}
                </div>
              </Grid>

              <Grid item xs={12} className="filters-item">
                <Typography variant="button" className="filter-label">
                  Alunos
                </Typography>
                <div>
                  {selectedStudents.length === 0 && (
                    <Chip
                      className="chip"
                      label="Nenhum aluno selecionado"
                      size="small"
                      variant="outlined"
                      color="primary"
                    />
                  )}
                  {selectedStudents.length === 1 && (
                    <Chip
                      className="chip"
                      label={
                        selectedStudents[0].name
                          ? Utils.capitalize(selectedStudents[0].name)
                          : "1 aluno selecionado"
                      }
                      size="small"
                      variant="outlined"
                      color="primary"
                      onDelete={() =>
                        dispatch(AppActions.setSelectedStudents([]))
                      }
                    />
                  )}
                  {selectedStudents.length > 1 && (
                    <Chip
                      className="chip"
                      label={`${selectedStudents.length} Selecionado(s)`}
                      size="small"
                      variant="outlined"
                      color="primary"
                      width="100"
                      onDelete={() =>
                        dispatch(AppActions.setSelectedStudents([]))
                      }
                    />
                  )}
                </div>
              </Grid>

              {/* <Grid item xs={6} className="filters-item"></Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );

  return (
    <Accordion className="filters-block" expanded={true}>
      <AccordionSummary>{panelSummaryContent}</AccordionSummary>
      <AccordionDetails>{panelDetailsContent}</AccordionDetails>
    </Accordion>
  );
};

FiltersBlock.defaultProps = {};

export default FiltersBlock;
