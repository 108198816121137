import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getBatchs: ["payload"],
  getBatchsSuccess: ["payload"],
  getBatchsFailure: ["payload"],
  getBatchInfo: ["payload"],
  getBatchInfoSuccess: ["payload"],
  getBatchInfoFailure: ["payload"],
});

const INITIAL_STATE = {
  getBatchsLoading: false,
  list: [],
  getBatchInfoLoading: false,
  batchInfo: false,
};

const getBatchs = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchsLoading: true,
});

const getBatchsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchsLoading: false,
  list: action.payload,
});

const getBatchsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchsLoading: false,
  list: INITIAL_STATE.list,
});

const getBatchInfo = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchInfoLoading: true,
});

const getBatchInfoSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchInfoLoading: false,
  batchInfo: action.payload,
});

const getBatchInfoFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatchInfoLoading: false,
  batchInfo: INITIAL_STATE.batchInfo,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_BATCHS]: getBatchs,
  [Types.GET_BATCHS_SUCCESS]: getBatchsSuccess,
  [Types.GET_BATCHS_FAILURE]: getBatchsFailure,
  [Types.GET_BATCH_INFO]: getBatchInfo,
  [Types.GET_BATCH_INFO_SUCCESS]: getBatchInfoSuccess,
  [Types.GET_BATCH_INFO_FAILURE]: getBatchInfoFailure,
});
