import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getSubjects: ["payload"],
  getSubjectsSuccess: ["payload"],
  getSubjectsFailure: ["payload"],

  getSubjectsEvasion: ["payload"],
  getSubjectsEvasionSuccess: ["payload"],
  getSubjectsEvasionFailure: ["payload"],
});

const INITIAL_STATE = {
  getSubjectsEvasionLoading: false,
  getSubjectsEvasionErrorMsg: null,
  evasionPage: 0,
  evasionLimit: 0,
  evasionTotal: 0,
  evasionList: [],

  getSubjectsLoading: false,
  getSubjectsErrorMsg: null,
  list: [],
};

const getSubjects = (state = INITIAL_STATE, action) => ({
  ...state,
  getSubjectsLoading: true,
});
const getSubjectsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getSubjectsLoading: false,
  getSubjectsErrorMsg: INITIAL_STATE.getSubjectsErrorMsg,
  list: action.payload,
});
const getSubjectsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getSubjectsLoading: false,
  getSubjectsErrorMsg: action.payload,
  list: INITIAL_STATE.list,
});

const getSubjectsEvasion = (state = INITIAL_STATE, action) => ({
  ...state,
  getSubjectsEvasionLoading: true,
});
const getSubjectsEvasionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getSubjectsEvasionLoading: false,
  getSubjectsEvasionErrorMsg: INITIAL_STATE.getSubjectsEvasionErrorMsg,
  evasionPage: action.payload.page,
  evasionLimit: action.payload.limit,
  evasionTotal: action.payload.total,
  evasionList: action.payload.list,
});
const getSubjectsEvasionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getSubjectsEvasionLoading: false,
  getSubjectsEvasionErrorMsg: action.payload,
  evasionPage: INITIAL_STATE.page,
  evasionLimit: INITIAL_STATE.limit,
  evasionTotal: INITIAL_STATE.total,
  evasionList: INITIAL_STATE.list,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUBJECTS_EVASION]: getSubjectsEvasion,
  [Types.GET_SUBJECTS_EVASION_SUCCESS]: getSubjectsEvasionSuccess,
  [Types.GET_SUBJECTS_EVASION_FAILURE]: getSubjectsEvasionFailure,

  [Types.GET_SUBJECTS]: getSubjects,
  [Types.GET_SUBJECTS_SUCCESS]: getSubjectsSuccess,
  [Types.GET_SUBJECTS_FAILURE]: getSubjectsFailure,
});
