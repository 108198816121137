/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../store/Api";

import {
  Button,
  FormControl,
  Grid,
  OutlinedInput,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import UserIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import EvasionBar from "../../components/EvasionBar";
import FilterButton from "../../components/FilterButton";
import Loading from "../../components/Loading";
import Pagination from "@mui/material/Pagination";

import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as StudentActions } from "../../store/ducks/student";

import "./style.sass";

const StudentsBlock = (props) => {
  const dispatch = useDispatch();

  const searchRef = useRef(null);

  const [search, setSearch] = useState(null);
  const [localPage, setLocalPage] = useState(1);
  const [order, setOrder] = useState({ field: "evasion", direction: "desc" });

  // const [finished, setFinished] = useState(false)
  const finished = useSelector((state) => state.app.finished);
  const filters = useSelector((state) => state.app.customFilters);
  const selectedBatch = useSelector((state) => state.app.selectedBatch);
  const selectedCourses = useSelector((state) => state.app.selectedCourses);
  const selectedClasses = useSelector((state) => state.app.selectedClasses);
  const selectedStudents = useSelector((state) => state.app.selectedStudents);
  const selectedEnrollments = useSelector(
    (state) => state.app.selectedEnrollments
  );
  const selectedTutors = useSelector((state) => state.app.selectedTutors);
  const classification = useSelector((state) => state.app.classification);

  const getStudentsLoading = useSelector(
    (state) => state.student.getStudentsLoading
  );
  const students = useSelector((state) => state.student.list);
  const page = useSelector((state) => state.student.page);
  const total = useSelector((state) => state.student.total);
  const limit = useSelector((state) => state.student.limit);

  useEffect(() => {
    if (selectedBatch) {
      dispatch(
        StudentActions.getStudents({
          page: localPage,
          limit: 10,
          finished,
          batchId: selectedBatch,
          tutorIds: selectedTutors.map(
            (selectedTutor) => selectedTutor.tutorId
          ),
          courseIds: selectedCourses.map(
            (selectedCourse) => selectedCourse.courseId
          ),
          classIds: selectedClasses.map(
            (selectedClass) => selectedClass.classId
          ),
          studentIds: selectedStudents.map(
            (selectedStudent) => selectedStudent.studentId
          ),
          enrollmentsIds: selectedEnrollments,
          orderField: order.field,
          orderDirection: order.direction,
          search,
          filters,
        })
      );
    }
  }, [
    // dispatch,
    search,
    localPage,
    finished,
    selectedBatch,
    selectedTutors,
    selectedCourses,
    selectedClasses,
    selectedStudents,
    selectedEnrollments,
    // startDate,
    // endDate,
    order,
    filters,
  ]);

  const onSearch = (event) => {
    setLocalPage(1);
    setSearch(searchRef.current.value);
  };
  const onSelectStudent = (student = null) => {
    setLocalPage(1);
    dispatch(AppActions.setSelectedStudents([student]));
  };

  const onFilter = async (filter) => {
    // Se todos os filtros aqui formor selecionar os alunos e colocar no selected students, então remover esse if e fazer como padrão esse comportamento
    if (Object.keys(filter).some((f) => f === "disability")) {
      const students = await Api.getDisability({
        ...filters,
        ...filter,
        batchId: selectedBatch,
        // startDate,
        // endDate,
      });
      dispatch(AppActions.setSelectedStudents(students));
    }
    // dispatch(AppActions.setCustomFilters({ ...filters, ...filter }))
  };

  const panelSummaryContent = useMemo(
    () => (
      <Grid container>
        <Grid item xs className="title">
          <UserIcon className="icon" />
          <Typography variant="h6">Alunos</Typography>
        </Grid>
        <Grid item xs={4} className="total">
          {getStudentsLoading && <Loading small />}
          <Typography variant="button">Total: {total}</Typography>
        </Grid>
      </Grid>
    ),
    [getStudentsLoading]
  );

  const panelDetailsContent = useMemo(
    () => (
      <React.Fragment>
        {getStudentsLoading && <Loading />}
        <Grid container>
          <Grid item xs={12} className="search-filter-wrapper">
            <form
              className="search-form"
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                onSearch();
              }}
            >
              <FormControl fullWidth size="small" className="search">
                <OutlinedInput
                  placeholder="Buscar alunos"
                  variant="outlined"
                  inputRef={searchRef}
                />
              </FormControl>
              <Button variant="outlined" type="submit">
                <SearchIcon />
              </Button>
            </form>
            <FilterButton
              onChangeOrder={(order) => setOrder(order)}
              onFilter={onFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="scroll">
              {students && students.length === 0 && (
                <div>Nenhum aluno encontrado</div>
              )}
              {students &&
                students.map((student) => (
                  <EvasionBar
                    key={student._id}
                    data={student}
                    type="student"
                    classification={classification}
                    seeMoreAction={() => onSelectStudent(student)}
                  />
                ))}
            </div>
            <div className="pagination-wrapper">
              <Pagination
                color={"primary"}
                size="small"
                count={Math.ceil(total / limit)}
                siblingCount={3}
                showFirstButton={true}
                showLastButton={true}
                page={page}
                onChange={(event, page) => setLocalPage(page)}
              />
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    ),
    [students]
  );

  return (
    <Accordion
      className="students-block"
      defaultExpanded={props.defaultExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {panelSummaryContent}
      </AccordionSummary>
      <AccordionDetails className="students-block-content">
        {getStudentsLoading && <Loading small />}
        {panelDetailsContent}
      </AccordionDetails>
    </Accordion>
  );
};

StudentsBlock.defaultProps = {};

export default StudentsBlock;
