import {
  OpenInNew,
  SpeakerNotes,
  Summarize,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { Recommendation } from "./types";
import { useDispatch } from "react-redux";
import { Creators as AppActions } from "../../store/ducks/app";

type Props = {
  filterBatch: () => void;
  recommendation: Recommendation;
};

const ClassAbandon = ({ filterBatch, recommendation }: Props) => {
  const dispatch = useDispatch();
  const filterStudents = (row: Record<string, any>) => {
    dispatch(AppActions.setSelectedStudents(row.students));
    dispatch(AppActions.setSelectedClasses([row.class]));
    dispatch(AppActions.setSelectedCourses([row.course]));
    dispatch(AppActions.setSelectedTutors([row.tutor]));
    dispatch(AppActions.setBackRecommendation(true));
    filterBatch();
  };
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>
              {`Há alunos que nunca acessaram o curso e que precisam ser contatados fora do AVA.`}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <SpeakerNotes />
            <Typography>
              Existe um risco de abandono dos alunos em determinados cursos
              vigentes listados abaixo. Sugerimos que acione os alunos por
              e-mail, ou outro meio de comunicação para motivá-los a voltar ao
              curso.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Summarize />
                <Typography>
                  <strong>Análise que disparou a recomendação:</strong>
                </Typography>
                <Link onClick={filterBatch} sx={{ cursor: "pointer" }}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {moment(recommendation?.date)
                        .add(3, "hours")
                        .format("DD/MM/YYYY")}
                    </Typography>
                    <OpenInNew />
                  </Stack>
                  <Typography></Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={recommendation.list?.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "course",
              headerName: "Curso",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.course?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.course?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Curso</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "class",
              headerName: "Turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.class?.name || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.class?.name || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "conclusion",
              headerName: "Progresso da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    `${(params.row?.conclusion * 100).toFixed(
                      2
                    )}% de conclusão` || " - "
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${(params.row?.conclusion * 100).toFixed(
                      2
                    )}% de conclusão` || " - "}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Progresso da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "startDate",
              headerName: "Data de início da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    (params.row?.startDate &&
                      moment(params.row?.startDate).format("DD/MM/YYYY")) ||
                    ""
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.startDate &&
                      moment(params.row?.startDate).format("DD/MM/YYYY")) ||
                      ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Data de início da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "endDate",
              headerName: "Data de término da turma",
              flex: 1.5,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip
                  title={
                    (params.row?.endDate &&
                      moment(params.row?.endDate).format("DD/MM/YYYY")) ||
                    ""
                  }
                >
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {(params.row?.endDate &&
                      moment(params.row?.endDate).format("DD/MM/YYYY")) ||
                      ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Data de término da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "abandon",
              headerName: "Porcentagem de alunos que nunca acessaram",
              flex: 1,
              minWidth: 100,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${(params.row?.abandon * 100).toFixed(2)}%`}>
                  <Link
                    onClick={() => filterStudents(params.row)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      <Typography>{`${(params.row?.abandon * 100).toFixed(
                        2
                      )}%`}</Typography>
                      <OpenInNew />
                    </Stack>
                  </Link>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Porcentagem de alunos que nunca acessaram</strong>
                  </Typography>
                </Stack>
              ),
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5]}
        />
      </Grid>
    </>
  );
};

export default ClassAbandon;
