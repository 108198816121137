import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Paper, Grid, Typography, Fab } from "@mui/material";

import MetricBlock from "../../components/MetricBlock";
import Loading from "../../components/Loading";

import TutorsBlock from "../../containers/TutorsBlock";
import CoursesBlock from "../../containers/CoursesBlock";
import ClassesBlock from "../../containers/ClassesBlock";
import StudentsBlock from "../../containers/StudentsBlock";
import SubjectsBlock from "../../containers/SubjectsBlock";
import EvasionChartBlock from "../../containers/EvasionChartBlock";
import FiltersBlock from "../../containers/FiltersBlock";

import MetricsIcon from "@mui/icons-material/Timeline";

import OnlineTimeIcon from "@mui/icons-material/Schedule";
import PersistenceIcon from "@mui/icons-material/TransferWithinAStation";
import ContentIcon from "@mui/icons-material/AssignmentTurnedIn";
import PrecedenceIcon from "@mui/icons-material/Timer";
// import ChatIcon from '@mui/icons-material/Chat'
import ChatIcon from "../../components/ChatbotIcon";

import { Creators as MetricsActions } from "../../store/ducks/metrics";
import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as ChatActions } from "../../store/ducks/chat";
import { Creators as FeedbackActions } from "../../store/ducks/feedback";
import Chat from "../../containers/Chat";

import "./style.sass";
import IndicatorsBlock from "../../containers/IndicatorsBlock";
import { Chip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const Data = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const metrics = useSelector((state) => state.metrics.list);
  const finished = useSelector((state) => state.app.finished);
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  // const [selectedIndicator, setSelectedIndicator] = useState(-1);

  const selectedBatch = useSelector((state) => state.app.selectedBatch);
  const selectedTutors = useSelector((state) => state.app.selectedTutors);
  const selectedCourses = useSelector((state) => state.app.selectedCourses);
  const selectedClasses = useSelector((state) => state.app.selectedClasses);
  const selectedStudents = useSelector((state) => state.app.selectedStudents);
  const selectedEnrollments = useSelector(
    (state) => state.app.selectedEnrollments
  );
  const filters = useSelector((state) => state.app.customFilters);
  const openChat = useSelector((state) => state.chat.openChat);
  const backRecommendation = useSelector(
    (state) => state.app.backRecommendation
  );
  const getMetricsLoading = useSelector(
    (state) => state.metrics.getMetricsLoading
  );

  useEffect(() => {
    if (selectedBatch) {
      dispatch(
        MetricsActions.getMetrics({
          courseIds: selectedCourses.map((courses) => courses.courseId),
          classIds: selectedClasses.map(
            (selectedClass) => selectedClass.classId
          ),
          tutorIds: selectedTutors.map((tutors) => tutors.tutorId),
          studentIds: selectedStudents.map((student) => student.studentId),
          enrollmentsIds: selectedEnrollments,
          batchId: selectedBatch,
          finished,
          // startDate,
          // endDate,
          filters,
        })
      );
    }
  }, [
    dispatch,
    selectedCourses,
    selectedTutors,
    selectedClasses,
    selectedStudents,
    selectedEnrollments,
    selectedBatch,
    finished,
    filters,
  ]);

  useEffect(() => {
    if (authenticatedUser["custom:feedbackFeature"])
      dispatch(FeedbackActions.getFeedbackTipOrQuestion());
  }, [authenticatedUser, dispatch]);

  const onChangeStudents = (newValue) => {
    dispatch(AppActions.setSelectedStudents(newValue));
  };

  const setShow = (value) => {
    dispatch(ChatActions.setOpenChat(value));
  };

  const handleBackRecommendation = () => {
    dispatch(AppActions.setBackRecommendation(false));
    history.goBack();
  };

  return (
    <div className="data">
      <Chat
        show={openChat}
        setShow={setShow}
        anchorX={document.getElementById("fab-chat")?.offsetLeft}
        anchorY={document.getElementById("fab-chat")?.offsetTop}
      />
      <div>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FiltersBlock />
            <TutorsBlock />
            <CoursesBlock />
            <ClassesBlock />
            {/* {keycloak.hasRealmRole("subjectModule") && (
              <SubjectsBlock />
              // <Typography variant="h6">bloco de disciplinas aqui</Typography>
            )} */}
            <StudentsBlock defaultExpanded={backRecommendation} />
          </Grid>
          <Grid item xs={7}>
            <EvasionChartBlock />
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <IndicatorsBlock />
              </Grid>
              <Grid item xs={9}>
                <Paper className="block metrics">
                  {getMetricsLoading && <Loading />}
                  <Typography variant="h6" className="title">
                    <MetricsIcon />
                    Métricas (Média geral)
                  </Typography>

                  <Grid container spacing={1}>
                    <Grid item className="metric-block-wrapper" xs={6}>
                      <MetricBlock
                        icon={<OnlineTimeIcon />}
                        {...metrics[0]}
                        studentsClickAction={onChangeStudents}
                      />
                    </Grid>
                    <Grid item className="metric-block-wrapper" xs={6}>
                      <MetricBlock
                        icon={<PersistenceIcon />}
                        {...metrics[1]}
                        studentsClickAction={onChangeStudents}
                      />
                    </Grid>
                    <Grid item className="metric-block-wrapper" xs={6}>
                      <MetricBlock
                        icon={<ContentIcon />}
                        {...metrics[3]}
                        studentsClickAction={onChangeStudents}
                      />
                    </Grid>
                    <Grid item className="metric-block-wrapper" xs={6}>
                      <MetricBlock
                        icon={<PrecedenceIcon />}
                        {...metrics[2]}
                        studentsClickAction={onChangeStudents}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Fab
        id="fab-chat"
        className="fab-chat"
        onClick={() => setShow(!openChat)}
      >
        <ChatIcon />
      </Fab>
      {backRecommendation && (
        <Chip
          id="back-recommendation"
          label="Voltar para recomendação"
          icon={<ArrowBack />}
          color="primary"
          onClick={handleBackRecommendation}
          sx={{ p: 1 }}
        />
      )}
      {/* {feedbackQuestion && (<FeedbackPopup/>)} */}
    </div>
  );
};
export default Data;
