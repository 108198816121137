import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import tutor from "./tutor";
import course from "./course";
import clss from "./class";
import subject from "./subject";
import student from "./student";
import user from "./user";
import indicators from "./indicators";
import metrics from "./metrics";
// import batchs from './period'
import notification from "./notification";
import alert from "./alert";
import chat from "./chat";
import batchlog from "./batchlog";
import batch from "./batch";
import feedback from "./feedback";
import admin from "./admin";
import recommendation from "./recommendation";

export default combineReducers({
  app,
  auth,
  tutor,
  course,
  class: clss,
  subject,
  student,
  user,
  indicators,
  metrics,
  // batchs,
  notification,
  alert,
  chat,
  batchlog,
  batch,
  feedback,
  admin,
  recommendation,
});
