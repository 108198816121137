import React from "react";

import {
  Grid,
  Button,
  Typography,
  LinearProgress,
  // Popover,
  Paper,
} from "@mui/material";
// import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import SeeMoreIcon from "@mui/icons-material/KeyboardArrowDown";
// import StudentsIcon from '@mui/icons-material/People'
import AttemptsIcon from "@mui/icons-material/TransferWithinAStation";
import OnlineTimeIcon from "@mui/icons-material/Schedule";
import GradeIcon from "@mui/icons-material/AssignmentTurnedIn";
import PrecedenceIcon from "@mui/icons-material/Timer";

import "./style.sass";

const IndicationBlock = (props) => {
  return (
    <Paper
      className={"block indication-block" + (props.open ? " open-info" : "")}
      onClick={props.onClick}
    >
      <Grid
        className="indication-block-wrapper"
        container
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          {props.name && (
            <Typography variant="subtitle2">{props.name}</Typography>
          )}
        </Grid>
        {props.hidePercent === false && (
          <Grid item>
            <Typography variant="h6" className="percent">
              {props.percent.toFixed(2)}%
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <LinearProgress
            className="bar"
            variant="determinate"
            value={props.percent}
          />
        </Grid>
        <Grid item>
          <SeeMoreIcon className="see-more-icon" />
        </Grid>
      </Grid>
      <Paper className="hover-info">
        {/* <Typography 
          variant="subtitle2" 
          align="center"
          gutterBottom>
            {props.nStudents} Alunos
          </Typography> */}
        <div className="student-button-wrapper">
          <Button
            size="small"
            onClick={() => {
              props.studentsClickAction();
            }}
          >
            {props.nStudents} Matriculas
          </Button>
        </div>
        <Typography variant="caption" gutterBottom>
          {props.description}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Métricas
        </Typography>
        <div className="icon-text">
          <OnlineTimeIcon className="icon" />
          <Typography variant="caption">{props.access}</Typography>
        </div>
        <div className="icon-text">
          <AttemptsIcon className="icon" />
          <Typography variant="caption">{props.attempts}</Typography>
        </div>
        <div className="icon-text">
          <GradeIcon className="icon" />
          <Typography variant="caption">{props.grade}</Typography>
        </div>
        <div className="icon-text">
          <PrecedenceIcon className="icon" />
          <Typography variant="caption">{props.precedency}</Typography>
        </div>
      </Paper>
    </Paper>
  );
};

IndicationBlock.defaultProps = {
  className: "",
  hidePercent: false,
  name: null,
  percent: 0,
  studentsClickAction: () => {},
};

export default IndicationBlock;
