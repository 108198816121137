import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getManagerUsers: ["payload"],
  getManagerUsersSuccess: ["payload"],
  getManagerUsersFailure: ["payload"],

  getTutorUsers: ["payload"],
  getTutorUsersSuccess: ["payload"],
  getTutorUsersFailure: ["payload"],

  getUsersNoAccess: ["payload"],
  getUsersNoAccessSuccess: ["payload"],
  getUsersNoAccessFailure: ["payload"],

  changeUserRoleToManager: ["payload"],
  changeUserRoleToManagerSuccess: ["payload"],
  changeUserRoleToManagerFailure: ["payload"],

  changeUserRoleToTutor: ["payload"],
  changeUserRoleToTutorSuccess: ["payload"],
  changeUserRoleToTutorFailure: ["payload"],

  enableUser: ["payload"],
  enableUserSuccess: ["payload"],
  enableUserFailure: ["payload"],

  disableUser: ["payload"],
  disableUserSuccess: ["payload"],
  disableUserFailure: ["payload"],

  deleteUser: ["payload"],
  deleteUserSuccess: ["payload"],
  deleteUserFailure: ["payload"],

  openNewUserForm: ["payload"],
  closeNewUserForm: ["payload"],
  newUser: ["payload"],
  newUserSuccess: ["payload"],
  newUserFailure: ["payload"],

  sendAccess: ["payload"],
  sendAccessSuccess: ["payload"],
  sendAccessFailure: ["payload"],

  saveMyData: ["payload"],
  saveMyDataSuccess: ["payload"],
  saveMyDataFailure: ["payload"],

  changePassword: ["payload"],
  changePasswordSuccess: ["payload"],
  changePasswordFailure: ["payload"],

  changeProfilePicture: ["payload"],
  changeProfilePictureSuccess: ["payload"],
  changeProfilePictureFailure: ["payload"],

  clearMsgs: ["payload"],
});

const INITIAL_STATE = {
  search: null,

  getManagerUsersLoading: false,
  managerPage: 0,
  managerLimit: 0,
  managerTotal: 0,
  managerFilter: "all",
  managerList: [],
  getManagerUsersErrorMsg: null,

  getTutorUsersLoading: false,
  tutorPage: 0,
  tutorLimit: 0,
  tutorTotal: 0,
  tutorFilter: "all",
  tutorList: [],
  getTutorUsersErrorMsg: null,

  getUsersNoAccessLoading: false,
  usersNoAccessPage: 0,
  usersNoAccessLimit: 0,
  usersNoAccessTotal: 0,
  usersNoAccessList: [],
  getUsersNoAccessErrorMsg: null,

  changeUserRoleToManagerErrorMsg: null,
  changeUserRoleToTutorErrorMsg: null,
  enableUserErrorMsg: null,
  disableUserErrorMsg: null,
  deleteUserErrorMsg: null,

  openNewUserForm: false,
  newUserLoading: false,
  newUserSuccessMsg: null,
  newUserErrorMsg: null,

  sendAccessLoading: false,
  sendAccessSuccessMsg: null,
  sendAccessErrorMsg: null,

  saveMyDataLoading: false,
  saveMyDataSuccessMsg: null,
  saveMyDataErrorMsg: null,

  changePasswordLoading: false,
  changePasswordSuccessMsg: null,
  changePasswordErrorMsg: null,

  changeProfilePictureLoading: false,
  changeProfilePictureSuccessMsg: null,
  changeProfilePictureErrorMsg: null,
};

const getManagerUsers = (state = INITIAL_STATE, action) => ({
  ...state,
  getManagerUsersLoading: true,
});
const getManagerUsersSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getManagerUsersLoading: false,
  getManagerUsersErrorMsg: INITIAL_STATE.getManagerUsersErrorMsg,
  search: action.payload.search,
  managerPage: action.payload.page,
  managerLimit: action.payload.limit,
  managerTotal: action.payload.total,
  managerFilter: action.payload.filter,
  managerList: action.payload.list,
});
const getManagerUsersFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getManagerUsersLoading: INITIAL_STATE.getManagerUsersLoading,
  getManagerUsersErrorMsg: action.payload,
  managerPage: INITIAL_STATE.managerPage,
  managerLimit: INITIAL_STATE.managerLimit,
  managerTotal: INITIAL_STATE.managerTotal,
  managerFilter: INITIAL_STATE.managerFilter,
  managerList: INITIAL_STATE.managerList,
});

const getTutorUsers = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorUsersLoading: true,
});
const getTutorUsersSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorUsersLoading: false,
  getTutorUsersErrorMsg: INITIAL_STATE.getTutorUsersErrorMsg,
  tutorPage: action.payload.page,
  tutorLimit: action.payload.limit,
  tutorTotal: action.payload.total,
  tutorFilter: action.payload.filter,
  tutorList: action.payload.list,
});
const getTutorUsersFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getTutorUsersLoading: INITIAL_STATE.getTutorUsersLoading,
  getTutorUsersErrorMsg: action.payload,
  tutorPage: INITIAL_STATE.tutorPage,
  tutorLimit: INITIAL_STATE.tutorLimit,
  tutorTotal: INITIAL_STATE.tutorTotal,
  tutorFilter: INITIAL_STATE.tutorFilter,
  tutorList: INITIAL_STATE.tutorList,
});

const getUsersNoAccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getUsersNoAccessLoading: true,
});
const getUsersNoAccessSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getUsersNoAccessLoading: false,
  getUsersNoAccessErrorMsg: INITIAL_STATE.getUsersNoAccessErrorMsg,

  usersNoAccessPage: action.payload.page,
  usersNoAccessLimit: action.payload.limit,
  usersNoAccessTotal: action.payload.total,
  usersNoAccessList: action.payload.list,
});
const getUsersNoAccessFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getUsersNoAccessLoading: INITIAL_STATE.getUsersNoAccessLoading,
  getUsersNoAccessErrorMsg: action.payload,

  usersNoAccessPage: INITIAL_STATE.usersNoAccessPage,
  usersNoAccessLimit: INITIAL_STATE.usersNoAccessLimit,
  usersNoAccessTotal: INITIAL_STATE.usersNoAccessTotal,
  usersNoAccessList: INITIAL_STATE.usersNoAccessList,
});

const changeUserRoleToManager = (state = INITIAL_STATE, action) => ({
  ...state,
  changeUserRoleToManagerLoading: true,
});
const changeUserRoleToManagerSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  changeUserRoleToManagerErrorMsg:
    INITIAL_STATE.changeUserRoleToManagerErrorMsg,
});
const changeUserRoleToManagerFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  changeUserRoleToManagerErrorMsg: action.payload,
});

const changeUserRoleToTutor = (state = INITIAL_STATE, action) => ({
  ...state,
  changeUserRoleToTutorLoading: true,
});
const changeUserRoleToTutorSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  changeUserRoleToTutorErrorMsg: INITIAL_STATE.changeUserRoleToTutorErrorMsg,
});
const changeUserRoleToTutorFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  changeUserRoleToTutorErrorMsg: action.payload,
});

const enableUser = (state = INITIAL_STATE, action) => ({
  ...state,
  enableUserLoading: true,
});
const enableUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  enableUserErrorMsg: INITIAL_STATE.enableUserErrorMsg,
});
const enableUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  enableUserErrorMsg: action.payload,
});

const disableUser = (state = INITIAL_STATE, action) => ({
  ...state,
  disableUserLoading: true,
});
const disableUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  disableUserErrorMsg: INITIAL_STATE.disableUserErrorMsg,
});
const disableUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  disableUserErrorMsg: action.payload,
});

const deleteUser = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteUserLoading: true,
});
const deleteUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteUserErrorMsg: INITIAL_STATE.deleteUserErrorMsg,
});
const deleteUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteUserErrorMsg: action.payload,
});

const openNewUserForm = (state = INITIAL_STATE, action) => ({
  ...state,
  openNewUserForm: true,
});
const closeNewUserForm = (state = INITIAL_STATE, action) => ({
  ...state,
  openNewUserForm: false,
});
const newUser = (state = INITIAL_STATE, action) => ({
  ...state,
  newUserLoading: true,
  newUserSuccessMsg: INITIAL_STATE.newUserSuccessMsg,
  newUserErrorMsg: INITIAL_STATE.newUserErrorMsg,
});
const newUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  openNewUserForm: false,
  newUserLoading: false,
  newUserSuccessMsg: INITIAL_STATE.newUserSuccessMsg,
  newUserErrorMsg: INITIAL_STATE.newUserErrorMsg,
});
const newUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  newUserLoading: false,
  newUserSuccessMsg: INITIAL_STATE.newUserSuccessMsg,
  newUserErrorMsg: action.payload,
});

const sendAccess = (state = INITIAL_STATE, action) => ({
  ...state,
  sendAccessLoading: true,
  sendAccessSuccessMsg: INITIAL_STATE.sendAccessSuccessMsg,
  sendAccessErrorMsg: INITIAL_STATE.sendAccessErrorMsg,
});
const sendAccessSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  sendAccessLoading: false,
  sendAccessSuccessMsg: action.payload,
  sendAccessErrorMsg: INITIAL_STATE.sendAccessErrorMsg,
});
const sendAccessFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  sendAccessLoading: false,
  sendAccessSuccessMsg: INITIAL_STATE.sendAccessSuccessMsg,
  sendAccessErrorMsg: action.payload,
});

const saveMyData = (state = INITIAL_STATE, action) => ({
  ...state,
  saveMyDataLoading: true,
  saveMyDataSuccessMsg: INITIAL_STATE.saveMyDataSuccessMsg,
  saveMyDataErrorMsg: INITIAL_STATE.saveMyDataErrorMsg,
});
const saveMyDataSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  saveMyDataLoading: false,
  saveMyDataSuccessMsg: action.payload,
  saveMyDataErrorMsg: INITIAL_STATE.saveMyDataErrorMsg,
});
const saveMyDataFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  saveMyDataLoading: false,
  saveMyDataSuccessMsg: INITIAL_STATE.saveMyDataSuccessMsg,
  saveMyDataErrorMsg: action.payload,
});

const changePassword = (state = INITIAL_STATE, action) => ({
  ...state,
  changePasswordLoading: true,
  changePasswordSuccessMsg: INITIAL_STATE.changePasswordSuccessMsg,
  changePasswordErrorMsg: INITIAL_STATE.changePasswordErrorMsg,
});
const changePasswordSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  changePasswordLoading: false,
  changePasswordSuccessMsg: action.payload,
  changePasswordErrorMsg: INITIAL_STATE.changePasswordErrorMsg,
});
const changePasswordFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  changePasswordLoading: false,
  changePasswordSuccessMsg: INITIAL_STATE.changePasswordSuccessMsg,
  changePasswordErrorMsg: action.payload,
});

const changeProfilePicture = (state = INITIAL_STATE, action) => ({
  ...state,
  changeProfilePictureLoading: true,
  changeProfilePictureSuccessMsg: INITIAL_STATE.changeProfilePictureSuccessMsg,
  changeProfilePictureErrorMsg: INITIAL_STATE.changeProfilePictureErrorMsg,
});
const changeProfilePictureSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  changeProfilePictureLoading: false,
  changeProfilePictureSuccessMsg: action.payload,
  changeProfilePictureErrorMsg: INITIAL_STATE.changeProfilePictureErrorMsg,
});
const changeProfilePictureFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  changeProfilePictureLoading: false,
  changeProfilePictureSuccessMsg: INITIAL_STATE.changeProfilePictureSuccessMsg,
  changeProfilePictureErrorMsg: action.payload,
});

const clearMsgs = (state = INITIAL_STATE, action) => ({
  ...state,
  changeProfilePictureSuccessMsg: INITIAL_STATE.changeProfilePictureSuccessMsg,
  getManagerUsersErrorMsg: INITIAL_STATE.getManagerUsersErrorMsg,
  getTutorUsersErrorMsg: INITIAL_STATE.getTutorUsersErrorMsg,
  getUsersNoAccessErrorMsg: INITIAL_STATE.getUsersNoAccessErrorMsg,
  changeUserRoleToManagerErrorMsg:
    INITIAL_STATE.changeUserRoleToManagerErrorMsg,
  changeUserRoleToTutorErrorMsg: INITIAL_STATE.changeUserRoleToTutorErrorMsg,
  enableUserErrorMsg: INITIAL_STATE.enableUserErrorMsg,
  disableUserErrorMsg: INITIAL_STATE.disableUserErrorMsg,
  deleteUserErrorMsg: INITIAL_STATE.deleteUserErrorMsg,
  newUserLoading: INITIAL_STATE.newUserLoading,
  newUserSuccessMsg: INITIAL_STATE.newUserSuccessMsg,
  newUserErrorMsg: INITIAL_STATE.newUserErrorMsg,
  sendAccessSuccessMsg: INITIAL_STATE.sendAccessSuccessMsg,
  sendAccessErrorMsg: INITIAL_STATE.sendAccessErrorMsg,
  saveMyDataSuccessMsg: INITIAL_STATE.saveMyDataSuccessMsg,
  saveMyDataErrorMsg: INITIAL_STATE.saveMyDataErrorMsg,
  changePasswordSuccessMsg: INITIAL_STATE.changePasswordSuccessMsg,
  changePasswordErrorMsg: INITIAL_STATE.changePasswordErrorMsg,
  changeProfilePictureErrorMsg: INITIAL_STATE.changeProfilePictureErrorMsg,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_MANAGER_USERS]: getManagerUsers,
  [Types.GET_MANAGER_USERS_SUCCESS]: getManagerUsersSuccess,
  [Types.GET_MANAGER_USERS_FAILURE]: getManagerUsersFailure,

  [Types.GET_TUTOR_USERS]: getTutorUsers,
  [Types.GET_TUTOR_USERS_SUCCESS]: getTutorUsersSuccess,
  [Types.GET_TUTOR_USERS_FAILURE]: getTutorUsersFailure,

  [Types.GET_USERS_NO_ACCESS]: getUsersNoAccess,
  [Types.GET_USERS_NO_ACCESS_SUCCESS]: getUsersNoAccessSuccess,
  [Types.GET_USERS_NO_ACCESS_FAILURE]: getUsersNoAccessFailure,

  [Types.ENABLE_USER]: enableUser,
  [Types.ENABLE_USER_SUCCESS]: enableUserSuccess,
  [Types.ENABLE_USER_FAILURE]: enableUserFailure,

  [Types.DISABLE_USER]: disableUser,
  [Types.DISABLE_USER_SUCCESS]: disableUserSuccess,
  [Types.DISABLE_USER_FAILURE]: disableUserFailure,

  [Types.CHANGE_USER_ROLE_TO_MANAGER]: changeUserRoleToManager,
  [Types.CHANGE_USER_ROLE_TO_MANAGER_SUCCESS]: changeUserRoleToManagerSuccess,
  [Types.CHANGE_USER_ROLE_TO_MANAGER_FAILURE]: changeUserRoleToManagerFailure,

  [Types.CHANGE_USER_ROLE_TO_TUTOR]: changeUserRoleToTutor,
  [Types.CHANGE_USER_ROLE_TO_TUTOR_SUCCESS]: changeUserRoleToTutorSuccess,
  [Types.CHANGE_USER_ROLE_TO_TUTOR_FAILURE]: changeUserRoleToTutorFailure,

  [Types.DELETE_USER]: deleteUser,
  [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Types.DELETE_USER_FAILURE]: deleteUserFailure,

  [Types.OPEN_NEW_USER_FORM]: openNewUserForm,
  [Types.CLOSE_NEW_USER_FORM]: closeNewUserForm,
  [Types.NEW_USER]: newUser,
  [Types.NEW_USER_SUCCESS]: newUserSuccess,
  [Types.NEW_USER_FAILURE]: newUserFailure,

  [Types.SEND_ACCESS]: sendAccess,
  [Types.SEND_ACCESS_SUCCESS]: sendAccessSuccess,
  [Types.SEND_ACCESS_FAILURE]: sendAccessFailure,

  [Types.SAVE_MY_DATA]: saveMyData,
  [Types.SAVE_MY_DATA_SUCCESS]: saveMyDataSuccess,
  [Types.SAVE_MY_DATA_FAILURE]: saveMyDataFailure,

  [Types.CHANGE_PASSWORD]: changePassword,
  [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,

  [Types.CHANGE_PROFILE_PICTURE]: changeProfilePicture,
  [Types.CHANGE_PROFILE_PICTURE_SUCCESS]: changeProfilePictureSuccess,
  [Types.CHANGE_PROFILE_PICTURE_FAILURE]: changeProfilePictureFailure,

  [Types.CLEAR_MSGS]: clearMsgs,
});
