import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import TutorIcon from "@mui/icons-material/School";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FilterButton from "../../components/FilterButton";
import Loading from "../../components/Loading";
import Slider from "../../components/Slider";
import TutorCard from "../../components/TutorCard";

import TutorFilterPopup from "../../containers/TutorFilterPopup";

import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as TutorActions } from "../../store/ducks/tutor";

import "./style.sass";

const TutorsBlock = (props) => {
  const dispatch = useDispatch();

  const [order, setOrder] = useState({ field: "evasion", direction: "desc" });

  const finished = useSelector((state) => state.app.finished);
  const filters = useSelector((state) => state.app.customFilters);
  const selectedBatch = useSelector((state) => state.app.selectedBatch);
  const selectedCourses = useSelector((state) => state.app.selectedCourses);
  const selectedClasses = useSelector((state) => state.app.selectedClasses);
  const selectedStudents = useSelector((state) => state.app.selectedStudents);
  const selectedEnrollments = useSelector(
    (state) => state.app.selectedEnrollments
  );
  const selectedTutors = useSelector((state) => state.app.selectedTutors);

  const loading = useSelector((state) => state.tutor.getTutorsEvasionLoading);
  const tutors = useSelector((state) => state.tutor.evasionList);
  const total = useSelector((state) => state.tutor.evasionTotal);

  useEffect(() => {
    if (selectedBatch) {
      dispatch(
        TutorActions.getTutorsEvasion({
          limit: 1000,
          finished,
          batchId: selectedBatch,
          tutorIds: selectedTutors.map(
            (selectedTutor) => selectedTutor.tutorId
          ),
          courseIds: selectedCourses.map(
            (selectedCourse) => selectedCourse.courseId
          ),
          classIds: selectedClasses.map(
            (selectedClass) => selectedClass.classId
          ),
          studentIds: selectedStudents.map(
            (selectedStudent) => selectedStudent.studentId
          ),
          enrollmentsIds: selectedEnrollments,
          orderField: order.field,
          orderDirection: order.direction,
          filters,
        })
      );
    }
  }, [
    dispatch,
    finished,
    selectedBatch,
    selectedTutors,
    selectedCourses,
    selectedClasses,
    selectedStudents,
    selectedEnrollments,
    // startDate,
    // endDate,
    order,
    filters,
  ]);

  const panelSummaryContent = useMemo(
    () => (
      <Grid container>
        <Grid item xs className="title">
          <TutorIcon className="icon" />
          <Typography variant="h6">Tutores</Typography>
        </Grid>
        <Grid item xs={4} className="total">
          {loading && <Loading small />}
          <Typography variant="button">Total: {total}</Typography>
        </Grid>
      </Grid>
    ),
    [loading, total]
  );

  const content = useMemo(() => {
    const onSelectTutors = (tutors) => {
      dispatch(AppActions.setSelectedTutors(tutors));
    };

    return (
      <Grid container>
        <Grid item xs={12} className="filters">
          <TutorFilterPopup />
          <FilterButton onChangeOrder={(order) => setOrder(order)} />
        </Grid>
        <Grid item xs={12}>
          {tutors.length === 0 && !loading && (
            <div className="empty">Nenhum tutor encontrado</div>
          )}
          {tutors.length > 0 && (
            <Slider>
              {tutors &&
                tutors.map((tutor) => (
                  <div key={tutor._id}>
                    <TutorCard
                      data={tutor}
                      seeMoreAction={() => onSelectTutors([tutor])}
                      // classification={classification}
                    />
                  </div>
                ))}
            </Slider>
          )}
        </Grid>
      </Grid>
    );
  }, [dispatch, tutors, loading]);

  return (
    <Accordion className="tutors-block">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {panelSummaryContent}
      </AccordionSummary>
      <AccordionDetails>
        {loading && <Loading />}
        {content}
      </AccordionDetails>
    </Accordion>
  );
};

TutorsBlock.defaultProps = {};

export default TutorsBlock;
