import { fork, all } from "redux-saga/effects";

import auth from "./auth";
import tutor from "./tutor";
import course from "./course";
import clss from "./class";
import subject from "./subject";
import student from "./student";
import user from "./user";
import indicators from "./indicators";
import metrics from "./metrics";
// import batchs from './period'
import notification from "./notification";
import alert from "./alert";
import chat from "./chat";
import batchlog from "./batchlog";
import batch from "./batch";
import classification from "./classification";
import feedback from "./feedback";
import admin from "./admin";
import recommendation from "./recommendation";

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(tutor),
    fork(course),
    fork(clss),
    fork(subject),
    fork(student),
    fork(user),
    fork(indicators),
    fork(metrics),
    // fork(batchs),
    fork(notification),
    fork(alert),
    fork(chat),
    fork(batchlog),
    fork(batch),
    fork(classification),
    fork(feedback),
    fork(admin),
    fork(recommendation),
  ]);
}
