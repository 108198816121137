import React from "react";
import { useSelector, useDispatch } from "react-redux";

import FilterPopup from "../../components/FilterPopup";

import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as CourseActions } from "../../store/ducks/course";

import "./style.sass";

const CourseFilterPopup = (props) => {
  const dispatch = useDispatch();

  const finished = useSelector((state) => state.app.finished);
  const startDate = useSelector((state) => state.app.startDate);
  const endDate = useSelector((state) => state.app.endDate);

  const loading = useSelector((state) => state.course.getCoursesLoading);
  const courses = useSelector((state) => state.course.list);
  const selectedCourses = useSelector((state) => state.app.selectedCourses);

  const onSelectCourses = (courses) => {
    dispatch(AppActions.setSelectedCourses(courses));
  };

  const onOpen = () => {
    if (!courses.length) {
      dispatch(
        CourseActions.getCourses({
          limit: 1000,
          finished,
          startDate,
          endDate,
        })
      );
    }
  };

  return (
    <div className="course-filter-popup">
      <FilterPopup
        label="Cursos"
        {...props}
        options={courses}
        selectedOptions={selectedCourses}
        loading={loading}
        onOpen={onOpen}
        onConfirm={onSelectCourses}
      />
    </div>
  );
};

CourseFilterPopup.defaultProps = {
  buttonSize: "medium",
  colorSize: "medium",
};

export default CourseFilterPopup;
