import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Avatar,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";

import UserIcon from "@mui/icons-material/Person";
import PasswordIcon from "@mui/icons-material/VpnKey";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Loading from "../../components/Loading";
import Utils from "../../Utils";

import { Creators as UserActions } from "../../store/ducks/user";
import { Creators as AuthActions } from "../../store/ducks/auth";

import "./style.sass";
import { enqueueSnackbar } from "notistack";
import { Stack } from "@mui/material";
import { fetchAuthSession, updatePassword } from "aws-amplify/auth";

const Profile = (props) => {
  const dispatch = useDispatch();
  const [demoRef, setDemoRef] = useState("");
  const [flow, setFlow] = useState("");

  const formDataInitial = {
    given_name: "",
    family_name: "",
    email: "",
    phone_number: "",
    previousPassword: "",
    password: "",
    optin: false,
  };

  const [formData, setFormData] = useState(formDataInitial);
  const [showpreviousPassword, setShowpreviousPassword] = useState(false);
  const [showpassword, setShowpassword] = useState(false);
  const [newPicture, setNewPicture] = useState(null);

  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  const showChangeProfilePictureLoading = useSelector(
    (state) => state.user.changeProfilePictureLoading
  );

  const showSaveMyDataLoading = useSelector(
    (state) => state.user.saveMyDataLoading
  );
  const saveMyDataSuccessMsg = useSelector(
    (state) => state.user.saveMyDataSuccessMsg
  );
  const saveMyDataErrorMsg = useSelector(
    (state) => state.user.saveMyDataErrorMsg
  );

  const showChangePasswordLoading = useSelector(
    (state) => state.user.changePasswordLoading
  );
  const changePasswordSuccessMsg = useSelector(
    (state) => state.user.changePasswordSuccessMsg
  );
  const changePasswordErrorMsg = useSelector(
    (state) => state.user.changePasswordErrorMsg
  );

  const updateUserAuthenticated = async () => {
    const tokens = (await fetchAuthSession({ forceRefresh: true })).tokens;
    console.log("tokens", tokens);
    dispatch(
      AuthActions.getAuthenticatedUserSuccess({
        ...tokens.idToken.payload,
        username: tokens.accessToken.payload.username,
      })
    );
  };

  useEffect(() => {
    if (saveMyDataSuccessMsg && !showSaveMyDataLoading) {
      updateUserAuthenticated();
    }
  }, [showSaveMyDataLoading, saveMyDataSuccessMsg]);

  const inputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "phone_number") value = Utils.phoneMask(value);

    if (name === "optin") value = !formData.optin;

    setFormData({ ...formData, [name]: value });
  };

  const myDataSubmitAction = (event) => {
    event.preventDefault();
    let result = Utils.validateForm(event.target);
    if (result !== true) {
      setFormData({});
      setFormData({ ...formData, ...result });
    } else {
      const dataToSend = {
        username: authenticatedUser.username,
        given_name: formData.given_name,
        family_name: formData.family_name,
        name: formData.given_name + " " + formData.family_name,
        email: formData.email,
        phone_number: formData.phone_number
          ? Utils.digitMask(formData.phone_number)
          : null,
        picture: newPicture,
        // optin: formData.optin
      };
      dispatch(UserActions.saveMyData(dataToSend));
    }
  };

  const passwordSubmitAction = async (event) => {
    event.preventDefault();
    let result = Utils.validateForm(event.target);
    if (result !== true) {
      setFormData({});
      setFormData({ ...formData, ...result });
    } else {
      try {
        dispatch(UserActions.changePassword());
        await updatePassword({
          oldPassword: formData.previousPassword,
          newPassword: formData.proposedPassword,
        });
        setFormData({
          ...formData,
          previousPasswordHasError: false,
          previousPasswordErrorText: null,
          proposedPasswordHasError: false,
          proposedPasswordErrorText: null,
        });
        dispatch(
          UserActions.changePasswordSuccess("Senha alterada com sucesso.")
        );
      } catch (error) {
        dispatch(UserActions.changePasswordFailure("Erro ao alterar a senha."));
        if (error?.__type === "NotAuthorizedException") {
          setFormData({
            ...formData,
            previousPasswordHasError: true,
            previousPasswordErrorText: "Senha atual incorreta.",
            proposedPasswordHasError: false,
            proposedPasswordErrorText: null,
          });
        } else if (error?.__type === "InvalidPasswordException") {
          setFormData({
            ...formData,
            previousPasswordHasError: false,
            previousPasswordErrorText: null,
            proposedPasswordHasError: true,
            proposedPasswordErrorText:
              "Senha deve ter pelo menos 8 caracteres (letras ou números).",
          });
        }
      }
    }
  };

  const changePicture = async (event) => {
    let file = event.target.files[0];

    if (file.size > 300000) {
      enqueueSnackbar("Tamanho da imagem excedeu o limite, 300kb.", {
        title: "Erro ao alterar imagem",
        variant: "error",
      });
    } else {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => setNewPicture(reader.result);
      } else {
        setNewPicture(null);
      }
    }
  };

  const saveNewPicture = async (event) => {
    dispatch(
      UserActions.saveMyData({
        picture: newPicture,
        username: authenticatedUser.username,
      })
    );
    setNewPicture(null);
  };

  useEffect(() => {
    setFormData({
      given_name: authenticatedUser.given_name,
      family_name: authenticatedUser.family_name,
      email: authenticatedUser.email,
      phone_number: Utils.phoneMask(authenticatedUser.phone_number?.slice(3)),
      optin: authenticatedUser.optin,
    });
  }, [authenticatedUser]);

  return (
    <div className="profile">
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          {
            <Paper className="block">
              <div className="title">
                <UserIcon className="icon" />
                <Typography variant="h6">Imagem do perfil</Typography>
              </div>
              <div className="picture-wrapper">
                {showChangeProfilePictureLoading && <Loading />}
                <Avatar
                  src={newPicture || authenticatedUser.picture}
                  className="picture"
                  alt=""
                />
                <input
                  type="file"
                  id="fileSelector"
                  className="file-selector"
                  onChange={changePicture}
                  accept="image/*"
                />
                {!newPicture && (
                  <Stack alignItems={"center"}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() =>
                        document.getElementById("fileSelector").click()
                      }
                    >
                      Selecionar nova imagem
                    </Button>
                    <Typography variant="caption">Limite de 300kb</Typography>
                  </Stack>
                )}
                {newPicture && (
                  <React.Fragment>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        let inputFile = document.getElementById("fileSelector");
                        inputFile.value = "";
                        var event = new Event("change");
                        inputFile.dispatchEvent(event);
                        setNewPicture(null);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={saveNewPicture}
                    >
                      Salvar
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </Paper>
          }

          <Paper className="block">
            <div className="title">
              <PasswordIcon className="icon" />
              <Typography variant="h6">Senha</Typography>
            </div>
            {showChangePasswordLoading && <Loading />}
            {changePasswordSuccessMsg && (
              <Alert severity="success">{changePasswordSuccessMsg}</Alert>
            )}
            {changePasswordErrorMsg && (
              <Alert severity="error">{changePasswordErrorMsg}</Alert>
            )}
            <form
              id="password-form"
              noValidate
              autoComplete="off"
              onSubmit={passwordSubmitAction}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} className="password-wrapper">
                  <TextField
                    name="previousPassword"
                    label="Senha atual"
                    required
                    margin="normal"
                    size="small"
                    fullWidth
                    type={showpreviousPassword ? "text" : "password"}
                    onChange={inputChange}
                    value={formData.previousPassword}
                    error={formData.previousPasswordHasError}
                    helperText={formData.previousPasswordErrorText}
                    inputProps={{
                      minLength: 4,
                      maxLength: 15,
                    }}
                  />
                  <IconButton
                    className="visibilityButton"
                    onClick={() =>
                      setShowpreviousPassword(!showpreviousPassword)
                    }
                    size="large"
                  >
                    {showpreviousPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </Grid>
                <Grid item xs={6} className="password-wrapper">
                  <TextField
                    name="proposedPassword"
                    label="Nova senha"
                    required
                    margin="normal"
                    size="small"
                    fullWidth
                    type={showpassword ? "text" : "password"}
                    onChange={inputChange}
                    value={formData.proposedPassword}
                    error={formData.proposedPasswordHasError}
                    helperText={formData.proposedPasswordErrorText}
                    inputProps={{
                      minLength: 8,
                      maxLength: 15,
                    }}
                  />
                  <IconButton
                    className="visibilityButton"
                    onClick={() => setShowpassword(!showpassword)}
                    size="large"
                  >
                    {showpassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Alterar senha
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="block">
            <div className="title">
              <UserIcon className="icon" />
              <Typography variant="h6">Meus Dados</Typography>
            </div>
            {showSaveMyDataLoading && <Loading />}
            {saveMyDataSuccessMsg && (
              <Alert severity="success">{saveMyDataSuccessMsg}</Alert>
            )}
            {saveMyDataErrorMsg && (
              <Alert severity="error">{saveMyDataErrorMsg}</Alert>
            )}
            <form
              id="mydata-form"
              noValidate
              autoComplete="off"
              onSubmit={myDataSubmitAction}
            >
              <TextField
                name="given_name"
                label="Nome"
                required
                margin="normal"
                size="small"
                fullWidth
                // disabled={!keycloak.hasResourceRole("consultant")}
                onChange={inputChange}
                value={formData.given_name}
                error={formData.nameHasError}
                helperText={formData.nameErrorText}
              />
              <TextField
                name="family_name"
                label="Sobrenome"
                required
                margin="normal"
                size="small"
                fullWidth
                // disabled={!keycloak.hasResourceRole("consultant")}
                onChange={inputChange}
                value={formData.family_name}
                error={formData.nameHasError}
                helperText={formData.nameErrorText}
              />
              <TextField
                name="email"
                label="Email"
                required
                margin="normal"
                size="small"
                fullWidth
                type="email"
                // disabled={!keycloak.hasResourceRole("consultant")}
                onChange={inputChange}
                value={formData.email}
                error={formData.emailHasError}
                helperText={formData.emailErrorText}
              />
              <TextField
                name="phone_number"
                label="Celular"
                // required
                margin="normal"
                size="small"
                fullWidth
                type="text"
                onChange={inputChange}
                value={formData.phone_number}
                error={formData.phone_numberHasError}
                helperText={formData.phone_numberErrorText}
                inputProps={{
                  minLength: 15,
                }}
              />
              {/* <FormControlLabel
                control={<Switch color="primary" checked={formData.optin} onChange={inputChange}/>}
                label={"Aceito receber notificações via phone_number do Assis"}
                name={"optin"}
              /> */}
              <br />
              <br />
              <br />
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2} direction={"row"}>
            <TextField
              id="demophone_number"
              name="demophone_number"
              label="Celular"
              // required
              margin="normal"
              size="small"
              fullWidth
              type="text"
              onChange={(event) => { setDemoRef(event.target.value) }}
              value={Utils.phoneMask(demoRef)}
              inputProps={{
                minLength: 15,
              }}
            />
            <FormControl fullWidth size="small" margin="normal" id="demo-select">
              <InputLabel id="flow-select-label">Fluxo</InputLabel>
              <Select
                labelId="flow-select-label"
                id="flow-select"
                value={flow}
                label="Fluxo"
                onChange={(event) => setFlow(event.target.value)}
              >
                <MenuItem value={1}>Nota baixou</MenuItem>
                <MenuItem value={2}>Nota subiu</MenuItem>
                <MenuItem value={3}>Feedback</MenuItem>
                <MenuItem value={4}>Métrica abandono</MenuItem>
                <MenuItem value={5}>Piora no Grupo de risco</MenuItem>
                <MenuItem value={6}>Curso finalizando</MenuItem>
                <MenuItem value={7}>Muitas tentativas, mas nota baixa</MenuItem>
                <MenuItem value={8}>Métrica baixo rendimento</MenuItem>
                <MenuItem value={9}>Métrica procrastinação</MenuItem>
                <MenuItem value={10}>Métrica inatividade</MenuItem>
                <MenuItem value={11}>Inadimplência</MenuItem>
                <MenuItem value={12}>Faltou a aula</MenuItem>
                <MenuItem value={13}>Máximo de falta</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Button variant="contained" color="warning" onClick={() => {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            let raw;
            let requestOptions;
            switch (flow) {
              case 1:
                raw = JSON.stringify([
                  {
                    "nome": "Neilson Araujo Bispo",
                    "numero": `${Utils.digitMask(demoRef)}`,
                    "identificadorUnico": "ALN20240001",
                    "cursos": [
                      // Nota Baixa
                      {
                        "descricao": "Gestão de Negócios",
                        "notasAvaliacoes": [6, 6, 6],
                        "mediaHistorica": 8.0,
                        "tutor": {
                          "identificadorUnico": "TUT20240002",
                          "nome": "Evandro Melo Santana",
                          "email": "evandro_melo@instituicaoparceira.com"
                        }
                      }
                    ]
                  }
                ]);

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  mode: "no-cors",
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://q4tpyiwuja.execute-api.us-east-2.amazonaws.com/dev/dados", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));
                break;
              case 2:
                raw = JSON.stringify([
                  {
                    "nome": "Isa Marta Santos da Conceicao",
                    "numero": `${Utils.digitMask(demoRef)}`,
                    "identificadorUnico": "ALN20240001",
                    "cursos": [
                      {
                        "descricao": "Relações Internacionais",
                        "notasAvaliacoes": [
                          7.05,
                          7.05,
                          7.05
                        ],
                        "mediaHistorica": 0,
                        "tutor": {
                          "identificadorUnico": "TUT20240002",
                          "nome": "Janaina Mirelly da Silva Xavier",
                          "email": "janaina_xavier@instituicaoparceira.com"
                        }
                      },
                    ]
                  }
                ]);

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  mode: "no-cors",
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://q4tpyiwuja.execute-api.us-east-2.amazonaws.com/dev/dados", requestOptions)

                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;
              case 3:
                raw = JSON.stringify({
                  "clientName": "Cicero Djalma Goncalves Cavalcante",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-feedback", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;
              case 4:
                raw = JSON.stringify({
                  "clientName": "William Ferreira Araujo",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-abandon", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;
              case 5:
                raw = JSON.stringify({
                  "clientName": "Wagner Costa Ferreira",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-bad-risk-group", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;
              case 6:
                raw = JSON.stringify({
                  "clientName": "Silvia Nepomuceno Freire",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-finishing-course", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;

              case 7:
                raw = JSON.stringify({
                  "clientName": "Diassis Costa Da Silva",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-many-attempts-low-grade", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;

              case 8:
                raw = JSON.stringify({
                  "clientName": "Higor Rodrigues Da Fonseca",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-low-performance", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;

              case 9:
                raw = JSON.stringify({
                  "clientName": "Emilio Daniel Carvalho Chaves",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-procrastination", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;

              case 10:
                raw = JSON.stringify({
                  "clientName": "Larissa De Santana Oliveira",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-inactivity", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;

              case 11:
                raw = JSON.stringify({
                  "clientName": "Felipe Vidal",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-non-payment", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;

              case 12:
                raw = JSON.stringify({
                  "clientName": "Felipe Vidal",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-missed-class", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;
              case 13:
                raw = JSON.stringify({
                  "clientName": "Felipe Vidal",
                  "clientPhone": `${Utils.digitMask(demoRef).slice(1)}`,
                  "clientId": "6271eecbaf57f4e3db52929a",
                  "instanceName": "teste"
                });

                requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };

                fetch("https://consultores.assis.ai:4001/notify-max-percent-fault", requestOptions)
                  .then((result) => console.log(result))
                  .catch((error) => console.error(error));

                break;
              default:
                break;
            }
          }}> Enviar Demonstração </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default Profile;
