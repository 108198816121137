import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/subject";

function* getSubjects(action) {
  try {
    const response = yield call(Api.getSubjects, action.payload);
    yield put({ type: Types.GET_TUTORS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: Types.GET_TUTORS_FAILURE, errorMessage: error.message });
  }
}

function* getSubjectsEvasion(action) {
  try {
    const response = yield call(Api.getSubjectsEvasion, action.payload);
    yield put({ type: Types.GET_TUTORS_EVASION_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_TUTORS_EVASION_FAILURE,
      payload: error.message,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(Types.GET_SUBJECTS, getSubjects),
    takeLatest(Types.GET_SUBJECTS_EVASION, getSubjectsEvasion),
  ]);
}
