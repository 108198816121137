import { put, call, takeLatest, all } from "redux-saga/effects";
import Api from "../Api";
import { Types } from "../ducks/app";

function* getClassification(action) {
  try {
    const response = yield call(Api.getClassification);
    yield put({ type: Types.GET_CLASSIFICATION_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: Types.GET_CLASSIFICATION_FAILURE,
      payload: error.message,
    });
  }
}

export default function* () {
  yield all([takeLatest(Types.GET_CLASSIFICATION, getClassification)]);
}
