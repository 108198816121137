import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getRecommendations: ["payload"],
  getRecommendationsSuccess: ["payload"],
  getRecommendationsFailure: ["payload"],
  setFilters: ["payload"],
  selectRecommendation: ["payload"],
});

const INITIAL_STATE = {
  getRecommendationLoading: false,
  getRecommendationErrorMsg: null,
  list: [],
  history: [],
  selectedRecommendation: null,
  filters: null,
};

const getRecommendations = (state = INITIAL_STATE, action) => ({
  ...state,
  getRecommendationLoading: true,
});

const getRecommendationsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getRecommendationLoading: false,
  getRecommendationErrorMsg: INITIAL_STATE.getRecommendationErrorMsg,
  list: action.payload.list,
  history: action.payload.history,
});
const getRecommendationsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getRecommendationLoading: false,
  getRecommendationErrorMsg: action.payload,
  list: INITIAL_STATE.list,
});
const setFilters = (state = INITIAL_STATE, action) => ({
  ...state,
  filters: action.payload,
});
const selectRecommendation = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedRecommendation: action.payload,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_RECOMMENDATIONS]: getRecommendations,
  [Types.GET_RECOMMENDATIONS_SUCCESS]: getRecommendationsSuccess,
  [Types.GET_RECOMMENDATIONS_FAILURE]: getRecommendationsFailure,
  [Types.SET_FILTERS]: setFilters,
  [Types.SELECT_RECOMMENDATION]: selectRecommendation,
});
