import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getClasses: ["payload"],
  getClassesSuccess: ["payload"],
  getClassesFailure: ["payload"],

  getClassesEvasion: ["payload"],
  getClassesEvasionSuccess: ["payload"],
  getClassesEvasionFailure: ["payload"],
});

const INITIAL_STATE = {
  getClassesEvasionLoading: false,
  getClassesEvasionErrorMsg: null,
  evasionPage: 0,
  evasionLimit: 0,
  evasionTotal: 0,
  evasionList: [],

  getClassesLoading: false,
  getClassesErrorMsg: null,
  list: [],
};

const getClasses = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassesLoading: true,
});
const getClassesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassesLoading: false,
  getClassesErrorMsg: INITIAL_STATE.getClassesErrorMsg,
  list: action.payload,
});
const getClassesFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassesLoading: false,
  getClassesErrorMsg: action.payload,
  list: INITIAL_STATE.list,
});

const getClassesEvasion = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassesEvasionLoading: true,
});
const getClassesEvasionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassesEvasionLoading: false,
  getClassesEvasionErrorMsg: INITIAL_STATE.getClassesEvasionErrorMsg,
  evasionPage: action.payload.page,
  evasionLimit: action.payload.limit,
  evasionTotal: action.payload.total,
  evasionList: action.payload.list,
});
const getClassesEvasionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  getClassesEvasionLoading: false,
  getClassesEvasionErrorMsg: action.payload,
  evasionPage: INITIAL_STATE.page,
  evasionLimit: INITIAL_STATE.limit,
  evasionTotal: INITIAL_STATE.total,
  evasionList: INITIAL_STATE.list,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_CLASSES_EVASION]: getClassesEvasion,
  [Types.GET_CLASSES_EVASION_SUCCESS]: getClassesEvasionSuccess,
  [Types.GET_CLASSES_EVASION_FAILURE]: getClassesEvasionFailure,

  [Types.GET_CLASSES]: getClasses,
  [Types.GET_CLASSES_SUCCESS]: getClassesSuccess,
  [Types.GET_CLASSES_FAILURE]: getClassesFailure,
});
