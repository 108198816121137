import {
  Event,
  FiberManualRecord,
  Groups,
  InfoOutlined,
  MenuBook,
  OpenInNew,
  Percent,
  Person,
  RadioButtonChecked,
  Recommend,
  School,
  SpeakerNotes,
  Summarize,
  Timeline,
  Today,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { Recommendation } from "./types";

type Props = {
  filterBatch: () => void;
  recommendation: Recommendation;
};

const WorkloadCourse = ({ filterBatch, recommendation }: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#FFDBDB",
            borderRadius: "12px 12px 0px 0px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Warning sx={{ color: "#FF3636" }} />
            <Typography>
              {`Notamos que o tempo médio para concluir o curso foi de ${
                recommendation.list?.[0].averageAccessTime
              }, ${
                recommendation.percent > 100
                  ? `${
                      recommendation.percent - 100
                    }% superior à carga horária definida na ementa do curso que é de ${
                      recommendation.list?.[0].workload
                    } horas.`
                  : `${
                      100 - recommendation.percent
                    }% inferior à carga horária definida na ementa do curso que é de ${
                      recommendation.list?.[0].workload
                    } horas.`
              }`}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#D8EBFF",
            borderRadius: "0px 0px 12px 12px",
            p: 2,
          }}
        >
          <Stack direction={"row"} spacing={2} sx={{ color: "#3B8FE4" }} pb={2}>
            <SpeakerNotes />
            <Typography>Sugerimos as seguintes ações:</Typography>
          </Stack>

          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Recolher feedbacks dos alunos sobre o curso, em relação ao seu
              conteúdo e ao AVA;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Revisar o planejamento do curso quanto a carga horária total, se
              está super ou subdimensionado;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Revisar o tempo de duração do curso com relação ao tempo de
              resolução dos OAs;
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} ml={3} mb={1}>
            <FiberManualRecord color={"primary"} />
            <Typography>
              Verificar com a equipe pedagógica e/ou conteudistas sobre a
              qualidade de material atual e possíveis atualizações necessárias
              frente à cursos disponíveis em outras plataformas.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <MenuBook />
                <Typography>
                  <strong>Curso:</strong>
                </Typography>
                <Typography>{recommendation?.course?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Groups />
                <Typography>
                  <strong>Turma:</strong>
                </Typography>
                <Typography>{recommendation?.class?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Percent />
                <Typography>
                  <strong>Progresso da turma:</strong>
                </Typography>
                <Typography>
                  {(recommendation?.conclusion * 100).toFixed(2)}% de conclusão
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Today />
                <Typography>
                  <strong>Data de início da turma:</strong>
                </Typography>
                <Typography>
                  {recommendation?.startDate &&
                    moment(recommendation?.startDate).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Event />
                <Typography>
                  <strong>Data de término da turma:</strong>
                </Typography>
                <Typography>
                  {recommendation?.endDate &&
                    moment(recommendation?.endDate).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <School />
                <Typography>
                  <strong>Tutor:</strong>
                </Typography>
                <Typography>{recommendation?.tutor?.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"row"} sx={{ mb: 2 }}>
                <Summarize />
                <Typography>
                  <strong>Análise que disparou a recomendação:</strong>
                </Typography>
                <Link onClick={filterBatch} sx={{ cursor: "pointer" }}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>
                      {moment(recommendation?.date)
                        .add(3, "hours")
                        .format("DD/MM/YYYY")}
                    </Typography>
                    <OpenInNew />
                  </Stack>
                  <Typography></Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: "61vh" }}
          rows={recommendation.list?.map((el, i) => ({ id: i, ...el })) || []}
          columns={[
            {
              field: "averageGrade",
              headerName: "Média de nota da turma",
              flex: 1,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.averageGrade || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {`${params.row?.averageGrade}` || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Média de nota da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "averageAccessTime",
              headerName: "Tempo médio da turma",
              flex: 1,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.averageAccessTime || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.averageAccessTime || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Tempo médio da turma</strong>
                  </Typography>
                </Stack>
              ),
            },
            {
              field: "historicalAccessTime", // @TODO Classificar no backend o AVG IAEvasion para um Grupo
              headerName: "Tempo médio geral",
              flex: 1,
              minWidth: 180,
              renderCell: (params: GridCellParams) => (
                <Tooltip title={`${params.row?.historicalAccessTime || ""}`}>
                  <Typography textOverflow={"ellipsis"} overflow={"hidden"}>
                    {params.row?.historicalAccessTime || ""}
                  </Typography>
                </Tooltip>
              ),
              renderHeader: () => (
                <Stack spacing={1} direction={"row"}>
                  <Typography variant={"body1"}>
                    <strong>Tempo médio geral</strong>
                  </Typography>
                  <Tooltip
                    title={
                      "Tempo médio comparativo das turmas anteriores do curso."
                    }
                  >
                    <InfoOutlined />
                  </Tooltip>
                </Stack>
              ),
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5]}
        />
      </Grid>
    </>
  );
};

export default WorkloadCourse;
