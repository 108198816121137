/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Badge,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  OutlinedInput,
  Typography,
  Paper,
  Popover,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Alert from "@mui/material/Alert";

import ManagerIcon from "@mui/icons-material/Grade";
import TutorIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import AddManagerIcon from "@mui/icons-material/PersonAdd";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Close";

import UserFormDialog from "../../containers/UserFormDialog";
import SendAccessDialog from "../../containers/SendAccessDialog";
import UserCard from "../../components/UserCard";
import Loading from "../../components/Loading";

import { Creators as UserActions } from "../../store/ducks/user";

import "./style.sass";

const Users = (props) => {
  const dispatch = useDispatch();

  const searchRef = useRef(null);
  // const filterhRef = useRef(null)

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [localManagerPage, setLocalManagerPage] = useState(1);
  const [localTutorPage, setLocalTutorPage] = useState(1);
  // const [order, setOrder] = useState({by: 'evasion', order: 'desc'})
  const [infoEl, setInfoEl] = useState(null);

  const [showSendAccessDialog, setShowSendAccessDialog] = useState(false);

  const managerUsers = useSelector((state) => state.user.managerList);
  const showManagerUsersLoading = useSelector(
    (state) => state.user.getManagerUsersLoading
  );

  const tutorUsers = useSelector((state) => state.user.tutorList);
  const showTutorUsersLoading = useSelector(
    (state) => state.user.getTutorUsersLoading
  );

  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  const [formData, setFormData] = useState({
    search: "",
  });

  const inputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSearch = (event) => {
    setSearch(formData.search);
  };

  const onChangeFilter = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    dispatch(UserActions.getManagerUsers());
    dispatch(UserActions.getTutorUsers());
  }, []);

  // useEffect(() => {
  //   let params = {
  //     search,
  //     filter,
  //     page: 1,
  //     limit: 100,
  //   }
  //   dispatch(UserActions.getManagerUsers(params))
  //   dispatch(UserActions.getTutorUsers(params))
  // }, [search, filter])

  useEffect(() => {}, []);

  // useEffect(() => {
  //   let params = {
  //     search,
  //     filter,
  //     page: localTutorPage,
  //     limit: tutorLimit,
  //     company: authenticatedUser.company,
  //   };
  //   dispatch(UserActions.getTutorUsers(params));
  // }, [localTutorPage, search, filter]);

  const clearSearch = () => {
    setFormData({ ...formData, search: "" });
    if (search) setSearch("");
  };

  return (
    <div className="users">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form
            className="search-form"
            noValidate
            onSubmit={(event) => {
              event.preventDefault();
              onSearch();
            }}
          >
            <FormControl variant="standard" size="small" className="search">
              <OutlinedInput
                placeholder="Buscar usuários"
                variant="outlined"
                name="search"
                value={formData.search}
                onChange={inputChange}
                inputRef={searchRef}
              />
            </FormControl>
            {formData.search && (
              <IconButton
                className="clear-button"
                size="small"
                onClick={clearSearch}
              >
                <ClearIcon />
              </IconButton>
            )}
            <Button type="submit" color="primary" variant="outlined">
              <SearchIcon />
            </Button>
          </form>
          {/* <RadioGroup row value={filter} onChange={onChangeFilter}>
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label="Todos"
            />
            <FormControlLabel
              value="no-access"
              control={<Radio color="primary" />}
              label="Nunca acessaram"
            />
          </RadioGroup> */}
          {/* <Badge
            className="new-users-button"
            color="error"
            badgeContent={usersNoAccessTotal || 10}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowSendAccessDialog(true)}
            >
              Acesso para novos
            </Button>
          </Badge> */}
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={6}>
          <Paper className="block">
            {showManagerUsersLoading && <Loading />}
            <div className="title">
              <ManagerIcon className="icon" />
              <Typography variant="h6">Gestores</Typography>
            </div>
            <div className="list">
              {!showManagerUsersLoading && !managerUsers.length && (
                <Alert severity="warning">
                  Nenhum usuário do tipo gestor foi encontrado
                </Alert>
              )}
              <Grid container spacing={2}>
                {managerUsers &&
                  managerUsers
                    .filter((el) => {
                      const name = el.name
                        ? el.name
                        : `${el.firstName} ${el.lastName}`;
                      return (
                        name.toLowerCase()?.includes(search?.toLowerCase()) ||
                        el.email?.toLowerCase().includes(search?.toLowerCase())
                      );
                    })
                    .slice((localManagerPage - 1) * 10, localManagerPage * 10)
                    .map((user) => (
                      <Grid item xs={6} key={user.sub}>
                        <UserCard
                          data={{ ...user, type: "manager" }}
                          itsMe={authenticatedUser.sub === user.sub}
                          changeToManagerAction={() =>
                            dispatch(
                              UserActions.changeUserRoleToManager(user.sub)
                            )
                          }
                          changeToTutorAction={() =>
                            dispatch(
                              UserActions.changeUserRoleToTutor(user.sub)
                            )
                          }
                          enableAction={() =>
                            dispatch(UserActions.enableUser(user))
                          }
                          disableAction={() =>
                            dispatch(UserActions.disableUser(user))
                          }
                          deleteAction={() =>
                            dispatch(UserActions.deleteUser(user.sub))
                          }
                          sendAccessAction={() =>
                            dispatch(
                              UserActions.sendAccess({ userIds: [user.sub] })
                            )
                          }
                          // tutorModule={keycloak.hasRealmRole("tutorModule")}
                        />
                      </Grid>
                    ))}
              </Grid>
            </div>
            <div className="pagination-wrapper">
              <Pagination
                color={"primary"}
                size="small"
                count={Math.ceil(managerUsers.length / 10)}
                siblingCount={3}
                showFirstButton={true}
                showLastButton={true}
                // page={managerPage}
                onChange={(event, page) => {
                  console.log(event, page);
                  setLocalManagerPage(page);
                }}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className="block">
            {showTutorUsersLoading && <Loading />}
            <div className="title">
              <TutorIcon className="icon" />
              <Typography variant="h6">Tutores</Typography>
              <IconButton
                className="info-tutor-button"
                size="small"
                color="primary"
                onClick={(event) => setInfoEl(event.currentTarget)}
              >
                <InfoIcon className="icon" />
              </IconButton>
            </div>
            <Popover
              open={Boolean(infoEl)}
              anchorEl={infoEl}
              onClose={() => setInfoEl(null)}
              anchorOrigin={{
                horizontal: "left",
                vertical: "center",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "center",
              }}
              disableRestoreFocus
            >
              <Typography className="info-tutor-popover-text" variant="body2">
                Todos os tutores enviados são cadastrados como usuários do tipo
                tutor
              </Typography>
            </Popover>
            <div className="list">
              {!showTutorUsersLoading && !tutorUsers.length && (
                <Alert severity="warning">
                  Nenhum usuário do tipo tutor foi encontrado
                </Alert>
              )}
              <Grid container spacing={2}>
                {tutorUsers &&
                  tutorUsers
                    .filter((el) => {
                      const name = el.name
                        ? el.name
                        : `${el.firstName} ${el.lastName}`;
                      return (
                        name.toLowerCase()?.includes(search?.toLowerCase()) ||
                        el.email?.toLowerCase().includes(search?.toLowerCase())
                      );
                    })
                    .slice((localTutorPage - 1) * 10, localTutorPage * 10)
                    .map((user) => (
                      <Grid item xs={6} key={user.id}>
                        <UserCard
                          data={{ ...user, type: "tutor" }}
                          changeToManagerAction={() =>
                            dispatch(
                              UserActions.changeUserRoleToManager(user.id)
                            )
                          }
                          changeToTutorAction={() =>
                            dispatch(UserActions.changeUserRoleToTutor(user.id))
                          }
                          enableAction={() =>
                            dispatch(UserActions.enableUser(user))
                          }
                          disableAction={() =>
                            dispatch(UserActions.disableUser(user))
                          }
                          deleteAction={() =>
                            dispatch(UserActions.deleteUser(user.id))
                          }
                          sendAccessAction={() =>
                            dispatch(
                              UserActions.sendAccess({ userIds: [user.id] })
                            )
                          }
                          // tutorModule={keycloak.hasRealmRole("tutorModule")}
                        />
                      </Grid>
                    ))}
              </Grid>
            </div>
            <div className="pagination-wrapper">
              <Pagination
                color={"primary"}
                size="small"
                count={Math.ceil(tutorUsers.length / 10)}
                siblingCount={3}
                showFirstButton={true}
                showLastButton={true}
                // page={tutorPage}
                onChange={(event, page) => setLocalTutorPage(page)}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>

      <UserFormDialog />
      {showSendAccessDialog && (
        <SendAccessDialog onClose={() => setShowSendAccessDialog(false)} />
      )}
    </div>
  );
};
export default Users;
