import React from "react";
import { useSelector, useDispatch } from "react-redux";

import FilterPopup from "../../components/FilterPopup";

import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as TutorActions } from "../../store/ducks/tutor";

import "./style.sass";

const TutorFilterPopup = (props) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.tutor.getTutorsLoading);
  const tutors = useSelector((state) => state.tutor.list);
  const selectedTutors = useSelector((state) => state.app.selectedTutors);
  const authenticated = useSelector((state) => state.auth.authenticatedUser);

  const onSelectTutors = (tutors) => {
    dispatch(AppActions.setSelectedTutors(tutors));
  };

  const onOpen = () => {
    if (!tutors.length) {
      dispatch(TutorActions.getTutors());
    }
  };

  return (
    <div className="tutor-filter-popup">
      <FilterPopup
        label="Tutores"
        {...props}
        options={tutors}
        selectedOptions={selectedTutors}
        loading={loading}
        onOpen={onOpen}
        onConfirm={onSelectTutors}
        disabled={authenticated?.["cognito:groups"]?.includes("tutor")}
      />
    </div>
  );
};

TutorFilterPopup.defaultProps = {
  buttonSize: "medium",
  colorSize: "medium",
};

export default TutorFilterPopup;
