import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { ButtonBase } from "@mui/material";

import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as BatchActions } from "../../store/ducks/batch";

import "./style.sass";

const BatchList = (props) => {
  const dispatch = useDispatch();

  const selectedBatch = useSelector((state) => state.app.selectedBatch);

  const batchs = useSelector((state) => state.batch.list);

  const setSelectBatch = (batch) => {
    dispatch(AppActions.setSelectedBatch(batch));
  };

  useEffect(() => {
    dispatch(BatchActions.getBatchs());
  }, [dispatch]);

  useEffect(() => {
    if (batchs.length) dispatch(AppActions.setSelectedBatch(batchs[0]._id));
  }, [batchs, dispatch]);

  return (
    <div className="batch-list">
      <ul style={{ width: batchs.length * 30 }}>
        {batchs.map((batch) => (
          <li key={batch._id}>
            <ButtonBase
              className={`batch-button ${
                batch._id === selectedBatch ? "active" : ""
              }`}
              onClick={() => setSelectBatch(batch._id)}
            >
              <div className="dot"></div>
              <div className="description">
                {moment(batch.endAt).format("DD/MM/YYYY")}
              </div>
            </ButtonBase>
          </li>
        ))}
      </ul>
    </div>
  );
};

BatchList.defaultProps = {};

export default BatchList;
